import React, { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowLeft } from 'react-icons/fa';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
//import { FaPencilAlt } from 'react-icons/fa'; // Utilise FontAwesome pour l'icône de crayon
import moment from "moment-timezone";
import axios from "axios";

import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, CardBody, CardTitle, CardHeader, Form, FormGroup, Input, Label, Container, Row, Col, UncontrolledAlert,
  Nav, NavItem, NavLink, TabContent, TabPane, ListGroup,
  ListGroupItem,
  Badge
} from "reactstrap";
import classnames from 'classnames';
import EarRight from '../../assets/img/theme/gauche.jpg'; // Remplacez 'path/to' par le chemin approprié
import EarLeft from '../../assets/img/theme/droite.jpg';  // Remplacez 'path/to' par le chemin approprié
import LinkIcon from '../../assets/img/theme/link.jpg';
import { useParams } from "react-router-dom";


const formatDate = (dateString) => {
  const utcDate = moment.utc(dateString);
  return utcDate.format("YYYY-MM-DD"); // Format propre en UTC
};


const NouveauClient = () => {
  
const { clientId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const initialActiveTab = location.state && location.state.activeTab ? location.state.activeTab : '1';
  const [client, setClient] = useState({
    nom: "",
    prenom: "",
    dateCreation: new Date().toISOString().split("T")[0],
    centerId: localStorage.getItem("currentCenter") || "", 
    dateNaissance: "",
    mutuelle: "",
    numeroSecu: "",
    caisse: "",
    email: "",
    telephoneFixe: "",
    telephonePortable: "",
    adresse: "",
    codePostal: "",
    ville: "",
    note: "",
    audiogramme: "",
    statut: "",
    origine: "",
    dateAppareillage: "", // New field for appareillage date
    dateFacturation: "",
    codeProduit: "",
    quantity: "",
    RbstSS: "",
    RbstCompl: "",
    Remise: "",
    dateDevis: "",
    numeroDevis: "",
    dureeValiditeDevis: "",
    marqueAppareilGDevis: "",
    modeleAppareilGDevis: "",
    numeroSerieGDevis: "",
    prixHTGDevis: "",
    prixTTCGDevis: "",
    marqueAppareilDDevis: "",
    modeleAppareilDDevis: "",
    marqueAppareilG: "",
    modelAppareilG: "",
    codeProduitG: "",
  lppG: "",
  quantityG: "",
  montantAppareilG: "",
  
  marqueAppareilD: "",
  modelAppareilD: "",
  codeProduitD: "",
  lppD: "",
  quantityD: "",
  montantAppareilD: "",
    numeroSerieDDevis: "",
    prixHTDDevis: "",
    prixTTCDDevis: "",
    classeAppareilDevis: "",
    rbstSSDevis: "",
    rbstComplDevis: "",
    garantieDevis: "",
    servicesInclusDevis: "",
    prixHTAccessoiresDevis: "",
    prixTTCAccessoiresDevis: "",
    acompteDevis: "",
    dateBilanAuditif: "",
  heureBilanAuditif: "",
  datePremierEssai: "",
  heurePremierEssai: "",
  dateDeuxiemeEssai: "",
  heureDeuxiemeEssai: "",
  dateRdvFacturation: "",
  heureRdvFacturation: "",
  dateRdvTeleconsultation: "",
  heureRdvTeleconsultation: "",
  dateRdvDevis: "",
  heureRdvDevis: "",
  pdfFiles: []

  });
  const [isEditable, setIsEditable] = useState(!location.state || !location.state.client);
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [audiogrammeSuccessMessage, setAudiogrammeSuccessMessage] = useState("");
  const [facture, setFacture] = useState({ articles: [] }); // Ensure articles is always an array
  const [showFactureModal, setShowFactureModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [token, setToken] = useState('');
  const [orlOptions, setOrlOptions] = useState([]);
  const [selectedOrl, setSelectedOrl] = useState(null);
  const [marques, setMarques] = useState([]);
  const [models, setModels] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [historique, setHistorique] = useState([]);
  const [filteredModelsLeft, setFilteredModelsLeft] = useState([]);
  const [filteredModelsRight, setFilteredModelsRight] = useState([]);
  const [filteredMarquesLeft, setFilteredMarquesLeft] = useState([]);
  const [filteredMarquesRight, setFilteredMarquesRight] = useState([]);
  const [stockProduits, setStockProduits] = useState([]);
 


  // useEffect(() => {
  //   // Récupérer les produits depuis l'API
  //   fetch('/api/produits')
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log("Produits récupérés:", data);
  //       // Extraire les marques uniques
  //       const uniqueMarques = [...new Set(data.map(item => item.marque))];
  //       setMarques(uniqueMarques);

  //       // Stocker tous les produits pour filtrer les modèles plus tard
  //       setModels(data);
  //     })
  //     .catch(error => console.error('Erreur lors de la récupération des produits:', error));
  // }, []);

  useEffect(() => {
    const stored = localStorage.getItem("currentCenter");
    let centerId = stored;
  
    try {
      centerId = JSON.parse(stored);
      if (typeof centerId === "string") centerId = { _id: centerId };
    } catch (e) {
      console.error("Erreur parsing centerId:", e);
    }
  
    if (centerId?._id) {
      fetch(`/api/produits?centerId=${centerId._id}`)
        .then(res => res.json())
        .then(data => {
          setStockProduits(data); // 🔧 nouveau state
        })
        .catch(err => console.error("Erreur chargement stock:", err));
    }
  }, []);

  
  const getDateFieldName = (statut) => {
    switch (statut) {
      case 'Rdv 1er essai': return 'datePremierEssai';
      case 'Rdv 2eme essai': return 'dateDeuxiemeEssai';
      case 'Rdv Bilan auditif': return 'dateBilanAuditif';
      case 'Rdv Téléconsultation': return 'dateRdvTeleconsultation';
      case 'Rdv facturation': return 'dateRdvFacturation';
      case 'Rdv devis': return 'dateRdvDevis';
      case 'Facturé': return 'dateFacturation';
      default: return '';
    }
  };
  
  const getHeureFieldName = (statut) => {
    switch (statut) {
      case 'Rdv 1er essai': return 'heurePremierEssai';
      case 'Rdv 2eme essai': return 'heureDeuxiemeEssai';
      case 'Rdv Bilan auditif': return 'heureBilanAuditif';
      case 'Rdv Téléconsultation': return 'heureRdvTeleconsultation';
      case 'Rdv facturation': return 'heureRdvFacturation';
      case 'Rdv devis': return 'heureRdvDevis';
      case 'Facturé': return 'heureFacturation';
      default: return '';
    }
  };
  useEffect(() => {
    axios.get(`/api/clients/${client._id}`)
      .then((response) => {
        setClient(prevClient => ({
          ...prevClient,
          ...response.data,
          pdfFiles: response.data.pdfFiles || [] // On s'assure que `pdfFiles` est toujours un tableau
        }));
      })
      .catch(error => {
        console.error("Erreur lors de la récupération du client :", error);
      });
  }, [clientId]);
  
  const handleMarqueChange = (event) => {
    const { name, value } = event.target;
    const isLeft = name === "marqueAppareilG";
  
    // Filtrer les modèles correspondant à la marque sélectionnée
    const updatedFilteredModels = models
      .filter(item => item.marque === value)
      .map(item => item.model);
  
    // Mettre à jour l'état
    setClient(prevClient => ({
      ...prevClient,
      [name]: value, // Met à jour la marque
      [isLeft ? "modelAppareilG" : "modelAppareilD"]: updatedFilteredModels.length > 0 ? updatedFilteredModels[0] : "",  // Réinitialise le modèle
      [isLeft ? "codeProduitG" : "codeProduitD"]: "", // Réinitialise le code produit
      [isLeft ? "lppG" : "lppD"]: "" // Réinitialise le LPP
    }));
  
    if (isLeft) {
      setFilteredModelsLeft(updatedFilteredModels);
    } else {
      setFilteredModelsRight(updatedFilteredModels);
    }
  };
  
  const handleModelChange = (event) => {
    const { name, value } = event.target;
    const isLeft = name === "modelAppareilG";

    const validModels = isLeft ? filteredModelsLeft : filteredModelsRight;
  if (!validModels.includes(value)) {
    return; // Arrêter la mise à jour si le modèle sélectionné n'existe pas
  }
  
    // Trouver l'objet produit correspondant au modèle sélectionné
    const selectedProduct = models.find(item => item.model === value);
  
    setClient(prevClient => ({
      ...prevClient,
      [name]: value, // Met à jour le modèle
      [isLeft ? "codeProduitG" : "codeProduitD"]: selectedProduct ? selectedProduct.code : "",
      [isLeft ? "lppG" : "lppD"]: selectedProduct ? selectedProduct.lpp : ""
    }));
  };
  
  
  useEffect(() => {

    //Si un client est passé dans l'état, utilisez ses valeurs pour initialiser le formulaire
      if (location.state && location.state.client && location.state.client._id) {
        console.log('Client trouvé:', location.state.client); 
        const formattedClient = {
          ...location.state.client,
          dateNaissance: formatDate(location.state.client.dateNaissance),
          dateAppareillage: formatDate(location.state.client.dateAppareillage),
          dateFacturation: formatDate(location.state.client.dateFacturation),
          prescripteurId: location.state.client.prescripteurId,
          marqueAppareilG: location.state.client.marqueAppareilG,
          marqueAppareilD: location.state.client.marqueAppareilD,
          modelAppareilG: location.state.client.modelAppareilG,
          modelAppareilD: location.state.client.modelAppareilD,
          dateDevis: location.state.client.dateDevis ? new Date(location.state.client.dateDevis).toISOString().split('T')[0] : '',  // Assurez-vous de formater la date
      numeroDevis: location.state.client.numeroDevis || '',  // Ajout du numéro du devis
      dureeValiditeDevis: location.state.client.dureeValiditeDevis || '',  // Ajout de la durée de validité du devis
      marqueAppareilGDevis: location.state.client.marqueAppareilGDevis || '',  // Appareil gauche - Type
      modeleAppareilGDevis: location.state.client.modeleAppareilGDevis || '',  // Appareil gauche - Modèle
      numeroSerieGDevis: location.state.client.numeroSerieGDevis || '',  // Appareil gauche - Numéro de série
      prixHTGDevis: location.state.client.prixHTGDevis || '',  // Appareil gauche - Prix HT
      prixTTCGDevis: location.state.client.prixTTCGDevis || '',  // Appareil gauche - Prix TTC
      marqueAppareilDDevis: location.state.client.marqueAppareilDDevis || '',  // Appareil droit - Type
      modeleAppareilDDevis: location.state.client.modeleAppareilDDevis || '',  // Appareil droit - Modèle
      numeroSerieDDevis: location.state.client.numeroSerieDDevis || '',  // Appareil droit - Numéro de série
      prixHTDDevis: location.state.client.prixHTDDevis || '',  // Appareil droit - Prix HT
      prixTTCDDevis: location.state.client.prixTTCDDevis || '',  // Appareil droit - Prix TTC
      classeAppareilDevis: location.state.client.classeAppareilDevis || '',  // Classe de l'appareil
      rbstSSDevis: location.state.client.rbstSSDevis || '',  // Remboursement Sécurité Sociale
      rbstComplDevis: location.state.client.rbstComplDevis || '',  // Remboursement Mutuelle
      garantieDevis: location.state.client.garantieDevis || '',  // Garantie
      servicesInclusDevis: location.state.client.servicesInclusDevis || '',  // Services inclus
      prixHTAccessoiresDevis: location.state.client.prixHTAccessoiresDevis || '',  // Accessoires - Prix HT
      prixTTCAccessoiresDevis: location.state.client.prixTTCAccessoiresDevis || '',  // Accessoires - Prix TTC
      acompteDevis: location.state.client.acompteDevis|| '',  // Acompte requis
      pdfFiles: location.state.client.pdfFiles || []
        };
        setClient(formattedClient);
        setPdfFiles(formattedClient.pdfFiles); 

         // Ajoutez cette ligne ici pour voir les données du client dans la console
    console.log("Données client après formatage:", formattedClient);

        if (location.state.client.prescripteurId && orlOptions.length) {
          const selected = orlOptions.find(option => option.value === location.state.client.prescripteurId);
          setSelectedOrl(selected || null);
        }
      }
    }, [location.state, orlOptions]);

  
  const toggleTab = (tabId) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
  };
  

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const isMounted = useRef(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;  // Nettoyage en démontant le composant
    };
  }, []);

  const validateForm = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!client.nom.trim()) {
      formIsValid = false;
      tempErrors["nom"] = "Le nom est requis.";
    }

    if (!client.email.trim()) {
      formIsValid = false;
      tempErrors["email"] = "L'email est requis.";
      toast.error("L'email est requis.");
    }

    // Vérification pour le champ telephonePortable
  if (!client.telephonePortable.trim()) {
    formIsValid = false;
    tempErrors["telephonePortable"] = "Le numéro de téléphone portable est requis.";
    // Ajout d'une notification toast
    toast.error("Le numéro de téléphone portable est requis.");
  }

    setErrors(tempErrors);
    return formIsValid;
  };


  const saveClient = async (clientData) => {
    const url = clientData._id ? `https://app.vtalys.fr/api/clients/${clientData._id}` : 'https://app.vtalys.fr/api/clients/add';
    const method = clientData._id ? 'PUT' : 'POST';

    console.log('Envoi des données du client:', clientData);  // Log des données envoyées
    console.log('URL:', url);  // Log de l'URL utilisée
    console.log('Méthode HTTP:', method);  // Log de la méthode utilisée
    const cleanDate = (date) => {
      if (!date || date === "Invalid date") return null;
      const parsedDate = new Date(date);
      return isNaN(parsedDate.getTime()) ? null : parsedDate.toISOString().split('T')[0];
  };

  // 🔧 Corriger le centerId au bon format
  let parsedCenterId = "";
  if (clientData.centerId) {
    if (typeof clientData.centerId === 'string') {
      try {
        const parsed = JSON.parse(clientData.centerId);
        parsedCenterId = parsed._id || parsed; // Support objet stringifié
      } catch {
        parsedCenterId = clientData.centerId; // Fallback si ID simple
      }
    } else if (typeof clientData.centerId === 'object') {
      parsedCenterId = clientData.centerId._id;
    } else {
      parsedCenterId = clientData.centerId;
    }
  }

    // Vérification du centerId et formatage des dates
    const updatedClientData = {
      ...clientData,
      _id: clientData._id ? clientData._id.trim() : undefined, // S’assurer que l’ID est correct
      centerId: parsedCenterId,
      dateFacturation: cleanDate(clientData.dateFacturation),
      dateAppareillage: cleanDate(clientData.dateAppareillage),
      dateNaissance: cleanDate(clientData.dateNaissance),
      dateDevis: cleanDate(clientData.dateDevis),
      dateBilanAuditif: cleanDate(clientData.dateFacturation),
  datePremierEssai: cleanDate(clientData.datePremierEssai),
  dateDeuxiemeEssai: cleanDate(clientData.dateDeuxiemeEssai),
  dateRdvFacturation: cleanDate(clientData.dateRdvFacturation),
  dateRdvTeleconsultation: cleanDate(clientData.dateRdvTeleconsultation),
  dateRdvDevis: cleanDate(clientData.dateRdvDevis),
    };

     // 🔴 Vérification de l'ID avant envoi
     if (clientData._id && (typeof clientData._id !== "string" || clientData._id.length !== 24)) {
      console.error("⛔ Erreur: L'ID du client n'est pas valide :", clientData._id);
      toast.error("Erreur: L'ID du client est invalide.");
      return;
  }
  console.log("📌 Données envoyées au backend :", updatedClientData);  // 🔍 Vérification
 
    try {
      const token = localStorage.getItem('token');
      console.log('Token JWT:', token);  // Log du token utilisé
  
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Ensure the token is correctly formatted
          ...(parsedCenterId && { 'x-current-center': parsedCenterId })
        },
        body: JSON.stringify(updatedClientData)
      });

      console.log('Statut de la réponse:', response.status);  // Log du statut de réponse HTTP

      if (!response.ok) {
        const data = await response.json();
        console.log('Erreur lors de l\'enregistrement:', data);  // Log de l'erreur
        toast.error(`Erreur: ${data.message}`);
        return;
      }

      const data = await response.json();
      console.log('Réponse du serveur:', data);  // Log de la réponse
      toast.success("Le client a été ajouté/modifié avec succès !");
      setIsEditable(false);

      if (clientData.statut === "Facturé" ) {
        // Génération de la facture lorsque le client est facturé
        generateInvoice(data._id || clientData._id, {
          totalGeneral: clientData.prix || 0, // Assurez-vous que ce champ est bien rempli
          articles: [{ description: 'Appareil auditif', quantite: 1, prix: clientData.prix || 1200 }] // Exemple
        });
      }

    } catch (error) {
      console.error('Erreur lors de l\'opération sur le client:', error);
      toast.error(`Erreur lors de l'opération sur le client: ${error.message}`);
    }
  };


  const saveAppareillage = () => {
    if (validateForm()) {
      if (client.modelAppareilG && client.modelAppareilG !== '') {
        console.log("Décrémentation gauche :", client.modelAppareilG);
        updateProductQuantity(client.modelAppareilG, 'gauche');
      }
      if (client.modelAppareilD && client.modelAppareilD !== '') {
        console.log("Décrémentation droite :", client.modelAppareilD);
        updateProductQuantity(client.modelAppareilD, 'droite');
      }
      
  
      saveClient(client);
    }
  };
  
  
  
  const handleSelectChange = selectedOption => {
    setSelectedOrl(selectedOption);  // Pour gérer l'état local de l'option sélectionnée
    // Mettre à jour l'état global du client pour le prescripteur
    setClient(prevState => ({
      ...prevState,
      prescripteurId: selectedOption ? selectedOption.value : ''
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // if (name === 'modelAppareilG' || name === 'modelAppareilD') {
    //   updateProductQuantity(value);
    // }
  
    const prevStatut = client.statut;
    
  if (name === 'statut' && client.statut) {
    const prevStatut = client.statut;
    const prevDateKey = getDateFieldName(prevStatut);
    const prevDate = client[prevDateKey] || 'Non renseignée';
    const prevHeureKey = getHeureFieldName(prevStatut);
    const prevHeure = client[prevHeureKey] || 'Non renseignée';

    console.log('Changement de statut détecté:', prevStatut, '->', value);
    fetch(`/api/clients/${client._id}/historique`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        nouveauStatut: value,
        date: new Date().toISOString(), // Date actuelle pour le nouveau statut
        heure: new Date().toLocaleTimeString(), // Heure actuelle pour le nouveau statut
      }),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Historique mis à jour:', data);
    })
    .catch((error) => {
      console.error('Erreur lors de la mise à jour de l\'historique:', error);
    });
  }

  
    setClient({ ...client, [name]: value });
  
    if (!!errors[name]) setErrors({ ...errors, [name]: null });
  };
  
  useEffect(() => {
    if (client && client._id) {
      fetch(`/api/clients/${client._id}/historique`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Erreur HTTP : ${response.status}`);
          }
          return response.json();  // Si la réponse est correcte, convertis en JSON
        })
        .then((data) => {
          setHistorique(data);  // Met à jour l'état avec les données récupérées
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération de l\'historique:', error);
        });
    } else {
      console.error('client._id est indéfini');
    }
  }, [client._id]);
  
  
  // Fonction pour mettre à jour la quantité du produit
const updateProductQuantity = (model, oreille) => {
  fetch(`/api/produits/update-quantity`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ model, oreille })
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Erreur lors de la mise à jour de la quantité du produit');
    }
    return response.json();
  })
  .then(data => {
    console.log('Quantité mise à jour:', data);
    // Afficher une notification toast si la quantité est à zéro
    if (data.produit.quantity === 0) {
      toast.error(`Le produit ${data.produit.model} n'est plus en stock !`);
    }
  })
  .catch(error => {
    console.error('Erreur:', error);
  });
};

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      saveClient(client);
    }
  };
  const handleSubmitAudiogramme = async (e) => {
    e.preventDefault();
    const audiogrammeData = {
      ...client,
      audiogramme: client.audiogramme
    };

    const url = client._id ? `https://app.vtalys.fr/api/clients/${client._id}` : 'https://app.vtalys.fr/api/clients/add';
    const method = client._id ? 'PUT' : 'POST';

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify(audiogrammeData)
      });

      if (!response.ok) {
        const errorText = await response.text();
        toast.error(`Erreur lors de l'enregistrement: ${errorText}`);
        throw new Error(`HTTP status ${response.status}: ${errorText}`);
        
      }

      const data = await response.json();
      console.log('Opération réussie:', data);
      toast.success("Le bilan auditif a été enregistré avec succès.");
      setAudiogrammeSuccessMessage("Le bilan auditif a été enregistré avec succès.");
      setTimeout(() => {
        setAudiogrammeSuccessMessage("");
        history.push('/admin/clients'); // Redirection après l'affichage du message de succès
      }, 3000); // Affichage du message pendant 3 secondes

    } catch (error) {
      console.error("Erreur lors de l'enregistrement du bilan auditif:", error);
      //setErrors({ form: "Erreur lors de l'opération sur le bilan auditif." });
      toast.error(`Erreur lors de l'enregistrement du bilan auditif`);
    }
  };
  const handleBack = () => {
    history.goBack();
  };

  const updateClientStatus = async (clientId, statut) => {
    try {
      const token = localStorage.getItem('token');  // Assurez-vous de récupérer le token ici
      if (!token) {
        throw new Error('Token JWT manquant.');
      }
    
      const response = await fetch(`https://app.vtalys.fr/api/clients/${client._id}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Ajouter le token dans l'en-tête Authorization
        },
        body: JSON.stringify({ statut })
      });
    
      if (!response.ok) {
        const updateErrorData = await response.json();
        console.error('Erreur lors de la mise à jour du statut:', updateErrorData);
        toast.error(`Erreur lors de la mise à jour du statut du client: ${updateErrorData.error || 'Erreur inconnue'}`);
        return;
      }
    
      // Afficher un message de succès pour la mise à jour du statut
      toast.success("Statut du client mis à jour avec succès !");
      
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut:", error);
      toast.error(`Erreur lors de la mise à jour du statut: ${error.message}`);
    }
  };
  

  const handleSubmitAppareillage = async (e) => {
    e.preventDefault();

    // Collecter les données d'appareillage
    const appareillageData = {
      ...client, // conserve les données existantes du client
      marqueAppareilG: client.marqueAppareilG,
      modelAppareilG: client.modelAppareilG,
      codeProduitG: client.codeProduitG,
      lppG: client.lppG,
      quantityG: client.quantityG,
      montantAppareilG: client.montantAppareilG,
      marqueAppareilD: client.marqueAppareilD,
      modelAppareilD: client.modelAppareilD,
      codeProduitD: client.codeProduitD,
      lppD: client.lppD,
      quantityD: client.quantityD,
      montantAppareilD: client.montantAppareilD
    };

    // URL et méthode pour l'API
    const url = client._id ? `https://app.vtalys.fr/api/clients/${client._id}` : 'https://app.vtalys.fr/api/clients/add';
    const method = client._id ? 'PUT' : 'POST';

    // Appel API pour enregistrer les données
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify(appareillageData)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP status ${response.status}: ${errorText}`);
      }

      const data = await response.json();
      console.log('Opération réussie:', data);
      setClient(data);
      setAudiogrammeSuccessMessage("L'appareillage a été enregistré avec succès.");
      setTimeout(() => {
        setAudiogrammeSuccessMessage(""); // Effacer le message après 3 secondes
        history.push('/admin/clients'); // Redirection après l'affichage du message de succès
      }, 3000);

    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'appareillage:", error);
      setAudiogrammeSuccessMessage(`Erreur lors de l'opération: ${error.message}`);
    }
  };

  

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setClient({
      ...client,
      appareillage: {
        ...client.appareillage,
        [name]: checked
      }
    });
  };

  const downloadPDF = async () => {
    try {
      const response = await fetch('https://app.vtalys.fr/api/clients/generate-pdf', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
      });
      if (response.ok) {
        // Crée une URL Blob et ouvre un nouvel onglet pour télécharger le PDF
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'devis.pdf'); // Nom du fichier à télécharger
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success("Le PDF a été téléchargé avec succès !");
      } else {
        throw new Error('Problème lors du téléchargement du PDF');
      }
    } catch (error) {
      console.error('Erreur lors du téléchargement du PDF:', error);
      toast.error("Erreur lors du téléchargement du PDF");
    }
  };

  const generateInvoice = async (clientId, factureData) => {
    

    try {
      const token = localStorage.getItem('token');  // Assurez-vous de récupérer le token ici
      if (!token) {
        throw new Error('Token JWT manquant.');
      }
  
      const response = await fetch(`https://app.vtalys.fr/api/factures/generate/${clientId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Ajouter le token dans l'en-tête Authorization
        },
        body: JSON.stringify({
          ...factureData,
          dateFacture: factureData.dateFacture || new Date().toISOString().split('T')[0]  // Ajoutez la date actuelle si nécessaire
        })
      });
      const data = await response.json();

      if (!response.ok) {
        // const data = await response.json();
        // Log pour vérifier le contenu de data
      console.log('Données de l\'erreur:', data);

      // Vérifier si l'erreur est une duplication
      if (data.error && data.error.includes('E11000 duplicate key error')) {
        // Extraire l'email du message d'erreur si possible
        const emailMatch = data.error.match(/email: \"(.+?)\"/);
        const email = emailMatch ? emailMatch[1] : 'cet email';
        toast.error(`Erreur: Une facture existe déjà pour ce client avec l'email ${email}`);
      } else {
        toast.error(`Erreur lors de la génération de la facture: ${data.error || 'Erreur inconnue'}`);
      }
      return;
    }

      toast.success("Facture générée avec succès !");
      await updateClientStatus(clientId, 'Facture éditée');


} catch (error) {
toast.error(`Erreur lors de la génération de la facture: Une facture existe déjà pour ce client`);
}
};

  const generateDevis = async (clientId, devisData) => {
    if (!clientId) {
      toast.error("Client ID manquant. Impossible de générer le devis.");
      return;
    }
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token JWT manquant.');
      }
  
      const response = await fetch(`https://app.vtalys.fr/api/clients/generate-devis/${clientId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Ajouter le token dans l'en-tête Authorization
        },
        body: JSON.stringify({
          ...devisData,
          dateDevis: devisData.dateDevis || new Date().toISOString().split('T')[0]  // Ajoutez la date actuelle si nécessaire
        })
      });
  
      if (!response.ok) {
        const data = await response.json();
        toast.error(`Erreur lors de la génération du devis: ${data.message}`);
        return;
      }
  
      toast.success("Devis généré avec succès !");
    } catch (error) {
      toast.error(`Erreur lors de la génération du devis: ${error.message}`);
    }
  };

  const generateDevisPDF = (client, user) => {
    const doc = new jsPDF();
    doc.setFont('Helvetica');
    doc.setFontSize(11);

    // Obtenir le dernier numéro de devis depuis le localStorage ou démarrer à 1
    const dernierNumeroDevis = localStorage.getItem('dernierNumeroDevis');
    const nouveauNumeroDevis = dernierNumeroDevis ? parseInt(dernierNumeroDevis, 10) + 1 : 1;

    // Stocker le nouveau numéro de devis
    localStorage.setItem('dernierNumeroDevis', nouveauNumeroDevis.toString().padStart(5, '0'));

    // Format du numéro de devis pour qu'il soit toujours sur 5 chiffres (ex : 00001, 00002, etc.)
    const numeroDevisFormate = nouveauNumeroDevis.toString().padStart(5, '0');

    // En-tête du devis avec les informations de l'audioprothésiste
    doc.text(`Nom de l'audioprothésiste: ${user.name || ''}`, 20, 10);
    doc.text(`Téléphone: ${user.telephone || ''}`, 20, 15);
    doc.text(`Email: ${user.email || ''}`, 20, 20);

    // Titre du devis centré
    doc.setFontSize(18);
    doc.text(`Devis - ${client.nom} ${client.prenom}`, 105, 30, null, null, 'center');

    // Date, numéro et durée de validité du devis alignés à droite
    doc.setFontSize(11);
    const dateDevis = client.dateDevis ? new Date(client.dateDevis).toLocaleDateString("fr-FR") : new Date().toLocaleDateString("fr-FR");
    doc.text(`Date: ${dateDevis}`, 200, 35, null, null, 'right');
    //doc.text(`Numéro de devis: ${client.numeroDevis || ''}`, 200, 40, null, null, 'right');
    doc.text(`Numéro de devis: ${numeroDevisFormate}`, 200, 40, null, null, 'right');
    doc.text(`Validité: ${client.dureeValiditeDevis || ''} jours`, 200, 45, null, null, 'right');

    // Informations du client
    doc.text(`Nom du patient: ${client.nom} ${client.prenom}`, 20, 55);
    doc.text(`Téléphone: ${client.telephonePortable || ''}`, 20, 60);
    const dateNaissanceFormatted = client.dateNaissance ? new Date(client.dateNaissance).toLocaleDateString("fr-FR") : '';
    doc.text(`Date de Naissance: ${dateNaissanceFormatted}`, 20, 65);
    doc.text(`Adresse: ${client.adresse || ''}, ${client.codePostal || ''}, ${client.ville || ''}`, 20, 70);

    // Section Appareil Gauche et Droit avec classe de l'appareil
    const totalTTCApplG = Math.round(client.prixHTGDevis * 1.055 * 100) / 100;
    const totalTTCApplD = Math.round(client.prixHTDDevis * 1.055 * 100) / 100;
    const totalTTC = (totalTTCApplG + totalTTCApplD).toFixed(2);

    // Tableau des appareils avec informations supplémentaires (classe de l'appareil, garantie, etc.)
    autoTable(doc, {
        head: [['Désignation', 'Classe', 'Marque', 'Modèle', 'N° Série', 'Qté', 'Prix HT (€)', 'TVA', 'Prix TTC (€)', 'Garantie']],
        body: [
            [
                'Appareil Gauche',
                `${client.classeAppareilDevis || ''}`,
                `${client.marqueAppareilGDevis || ''}`,
                `${client.modeleAppareilGDevis || ''}`,
                `${client.numeroSerieGDevis || ''}`,
                `${client.quantityG || 1}`,
                `${client.prixHTGDevis || 0} €`,
                '5.5%',
                `${totalTTCApplG.toFixed(2)} €`,
                `${client.garantieDevis || ''}`
            ],
            [
                'Appareil Droit',
                `${client.classeAppareilDevis || ''}`,
                `${client.marqueAppareilDDevis || ''}`,
                `${client.modeleAppareilDDevis || ''}`,
                `${client.numeroSerieDDevis || ''}`,
                `${client.quantityD || 1}`,
                `${client.prixHTDDevis || 0} €`,
                '5.5%',
                `${totalTTCApplD.toFixed(2)} €`,
                `${client.garantieDevis || ''}`
            ]
        ],
        startY: 85,
        theme: 'grid',
        styles: { fontSize: 10, font: 'Helvetica' },
        headStyles: { fillColor: [0, 51, 51] },
        columnStyles: {
          0: { cellWidth: 30 },  // Désignation
          1: { cellWidth: 10 },  // Classe (Réduite)
          2: { cellWidth: 25 },  // Marque
          3: { cellWidth: 45 },  // Modèle (Élargie)
          4: { cellWidth: 25 },  // N° Série (Élargie)
          5: { cellWidth: 10 },  // Qté (Réduite)
          6: { cellWidth: 20 },  // Prix HT (€) (Réduite)
          7: { cellWidth: 15 },  // TVA
          8: { cellWidth: 20 },  // Prix TTC (€) (Réduite)
          9: { cellWidth: 15 }   // Garantie (Réduite)
      },
        margin: { top: 10, bottom: 10 },
    });

    // Section Accessoires
    const totalAccessoiresTTC = client.prixTTCAccessoiresDevis || 0;
    autoTable(doc, {
        head: [['Désignation', 'Prix HT (€)', 'Prix TTC (€)']],
        body: [
            [
                'Accessoires',
                `${client.prixHTAccessoiresDevis || 0} €`,
                `${totalAccessoiresTTC} €`
            ]
        ],
        startY: doc.lastAutoTable.finalY + 10,
        theme: 'grid',
        styles: { fontSize: 10, font: 'Helvetica' },
        headStyles: { fillColor: [0, 51, 51] },
        margin: { top: 10, bottom: 10 },
    });

    // Résumé financier
    autoTable(doc, {
        startY: doc.lastAutoTable.finalY + 10,
        theme: 'plain',
        body: [
            [`Total TTC des Appareils : ${totalTTC} €`],
            [`Prix TTC des Accessoires : ${totalAccessoiresTTC} €`],
            [`Montant pris en charge par la Sécurité Sociale : ${client.rbstSSDevis || 0} €`],
            [`Montant pris en charge par la Mutuelle : ${client.rbstComplDevis || 0} €`],
            [`Acompte à verser : ${client.acompteDevis || 0} €`],
            [`Montant à la charge du patient : ${(totalTTC + totalAccessoiresTTC - (client.rbstSSDevis + client.rbstComplDevis)).toFixed(2)} €`]
        ],
        styles: {
            fontSize: 10,
            font: 'Helvetica',
            cellPadding: 2,
            lineColor: 200,
            lineWidth: 0.5
        },
        margin: { top: 10, right: 20, bottom: 10, left: 20 }
    });

    // Sauvegarder le PDF
    doc.save(`Devis-${client.nom}-${client.prenom}.pdf`);
};


// Récupérer les informations de l'utilisateur à partir de localStorage (ou d'une autre source)
const user = JSON.parse(localStorage.getItem('user')) || {};
  
useEffect(() => {
  if (
    client.statut === "Facturé" &&
    (!client.dateFacturation || !client.rbstSS || !client.rbstCompl)
  ) {
    setShowFactureModal(true);
  }
}, [client.statut, client.dateFacturation, client.rbstSS, client.rbstCompl]);

  

  const handleStatutChange = (event) => {
    const newStatut = event.target.value;
    setClient({ ...client, statut: newStatut });
  
    // Si le nouveau statut est "Facturé" et qu'aucune date de facturation n'existe, afficher le modal
    if (
      newStatut === "Facturé" && 
      (!client.dateFacturation || !client.rbstSS || !client.rbstCompl)
    )  {
      setShowFactureModal(true);
    } else {
      setShowFactureModal(false);
    }
  };
  

  const handleModalInputChange = (event) => {
    setClient({ ...client, [event.target.name]: event.target.value });
  };

  

  const handleSubmitFacture = async () => {
    // Effectuer les opérations nécessaires pour sauvegarder les données
    if (validateFactureData()) {
      try {
        // Sauvegarde des données de facturation
        await saveClient(client); // Assume que saveClient va mettre à jour la base de données avec les nouvelles valeurs
  
        // Vérifier si les données sont bien enregistrées
        setShowFactureModal(false); // Fermer le modal seulement après la sauvegarde réussie
        toast.success("Les informations de facturation ont été sauvegardées avec succès !");
      } catch (error) {
        console.error("Erreur lors de la sauvegarde des informations de facturation :", error);
        toast.error("Erreur lors de la sauvegarde. Veuillez réessayer.");
      }
    } else {
      toast.error("Veuillez remplir tous les champs obligatoires.");
    }
  };

  const validateFactureData = () => {
    return client.dateFacturation && client.rbstSS && client.rbstCompl;
  };
  
  
  const steps = [
    { id: '1', name: 'Informations Générales', path: '#informations-generales', icon: 'ni ni-badge' },
    { id: '2', name: 'Rdv Bilan auditif Auditif', path: '#bilan-auditif', icon: 'ni ni-headphones' },
    { id: '3', name: 'Devis', path: '#devis', icon: 'ni ni-single-copy-04' },
    { id: '4', name: 'Appareillage', path: '#appareillage', icon: 'ni ni-vector' },
    { id: '5', name: 'Historique', path: '#historique', icon: 'ni ni-folder-17' }
  ];


  const navLinkStyle = {
    padding: '8px 16px',
    borderRadius: '8px', // Border-radius toujours appliqué
    backgroundColor: '#f8f9fa',
    border: '1px solid #ddd',
    color: '#007BFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s ease',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    fontWeight: '500',
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '15px',
    outline: 'none',
  };

  // Styles pour les NavLink actifs
  const navLinkActiveStyle = {
    ...navLinkStyle, // Inherit all common styles including border-radius
    backgroundColor: '#007D70',
    color: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transform: 'scale(1.05)',
  };


  const EarCardStyle = {
    borderRadius: '12px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
    marginTop: '30px',
    marginBottom: '30px',
    padding: '20px',
    background: 'linear-gradient(87deg, #f0f8ff, #e6f7ff)',
    color: 'white',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    maxWidth: '400px', // Réduit la largeur maximale
    width: '100%',
  };

  const labelStyle = {
    color: 'black',
    fontSize: '13px',
    marginBottom: '5px',
  };

  const formGroupStyle = {
    marginBottom: '10px',
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',  // Centrer horizontalement
    alignItems: 'center',  // Centrer verticalement
    gap: '20px',  // Espacement entre les cartes
    marginBottom: '20px',
    flexWrap: 'wrap', // Permettre un bon alignement sur les petits écrans
  };
  useEffect(() => {
    console.log("Options prescripteurs:", orlOptions);
  }, [orlOptions]);

  useEffect(() => {
    console.log("Selected prescripteur:", selectedOrl);
  }, [selectedOrl]);


  const [isFlipped, setIsFlipped] = useState({
    right: false,
    left: false
  });

  const [hovered, setHovered] = useState(false); // État pour gérer le survol


  const handleEditToggle = (e) => {
    e.stopPropagation();
    setIsEditable((prev) => !prev);
  };


  const [isEditing, setIsEditing] = useState({ left: false, right: false });

  const toggleFlip = (side) => {
    setIsFlipped(prev => ({
      ...prev,
      [side]: !prev[side]
    }));
  };

  const toggleEdit = (side) => {
    setIsEditing((prev) => ({ ...prev, [side]: !prev[side] }));
  };

  // Styles
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      height: '80vh',
      paddingTop: '20px',
      gap: '80px', // Augmente l'espacement entre les cartes et l'image du lien
      position: 'relative',
    },
    card: {
      width: '300px',
      height: '500px',
      perspective: '1000px',
      border: '2px solid #007D70',
      borderRadius: '10px',
      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      position: 'relative',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#fff',
      overflow: 'hidden',
      cursor: 'pointer',
    },
    cardInner: {
      position: 'relative',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      transition: 'transform 0.6s',
      transformStyle: 'preserve-3d',
    },
    cardFace: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backfaceVisibility: 'hidden',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between', // Espace les éléments de manière uniforme
      alignItems: 'center',
      padding: '40px 20px', // Augmente le padding pour plus d'espace intérieur
      boxSizing: 'border-box',
    },
    cardBack: {
      position: 'relative', // Assurez-vous que le parent a 'position: relative'
      padding: '20px', // Ajuster le padding si nécessaire
      transform: 'rotateY(180deg)',
    },
    image: {
      width: '150px',
      height: '150px',
      marginBottom: '20px' // Espace entre l'image et le texte
    },
    button: {
      padding: '15px',
      border: 'none',
      borderRadius: '5px',
      background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
      color: 'white',
      fontSize: '16px',
      cursor: 'pointer',
      marginTop: '20px', // Espace entre le texte et le bouton
      transition: 'transform 0.3s ease',
      width: '80%',
      maxWidth: '180px',
    },
    saveButton: {
      marginTop: '10px',
      padding: '10px 20px',
      borderRadius: '8px',
      background: '#28a745',
      border: 'none',
      color: 'white',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
    },
    pencilButton: {
      position: 'absolute',
      top: '10px', // Ajustez cette valeur pour l'espacement
      right: '10px', // Positionne le bouton à droite
      width: '30px', // Limite la largeur du bouton
      height: '30px', // Limite la hauteur du bouton
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5px', // Ajustez le padding si nécessaire
      borderRadius: '50%', // Rend le bouton rond
      transition: 'all 0.3s ease', // Transition pour l'effet de survol
      background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
      border: 'none',
      color: 'white',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
      fontSize: '14px',
    },
    pencilButtonHover: {
      transform: 'scale(1.2)', // Agrandit le bouton à 120% de sa taille normale
    },

    linkContainer: {
      position: 'absolute',
      top: '50%', // Centre verticalement par rapport aux cartes
      left: '50%', // Centre horizontalement par rapport au conteneur
      transform: 'translate(-50%, -50%)', // Ajuste pour centrer parfaitement
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    linkIcon: {
      width: '50px',
      height: '50px',
    },
    pencilIcon: {
      width: '16px',
      height: '16px',
    },
    detailsTitle: {
      marginTop: '30px', // Augmentez cette valeur pour déplacer le titre plus bas
      paddingTop: '20px', // Ajoutez un padding supérieur si nécessaire
      fontSize: '18px', // Taille du texte du titre
      fontWeight: 'bold', // Rendre le titre en gras
      textAlign: 'center', // Centre le texte
      position: 'relative', // Permet un positionnement plus flexible
      top: '10px', 
    },
  };

  
  const handlePdfDrop = async (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files).filter(file => file.type === 'application/pdf');
    
    for (const file of newFiles) {
      const formData = new FormData();
      formData.append('pdfFile', file);
      formData.append('clientId', client._id);
  
      try {
        const response = await fetch(`https://app.vtalys.fr/api/upload/${client._id}`, {
          method: 'POST',
          body: formData
        });
  
        const data = await response.json();
        if (response.ok) {
          const newFileObj = { 
            fileName: data.fileName, 
            fileData: data.fileData 
          };
  
          await updateClientWithPdf(newFileObj);
          
          setPdfFiles(prevFiles => [...prevFiles, newFileObj]);
          toast.success('Fichier PDF sauvegardé avec succès!');
        } else {
          console.error('Erreur lors de l’upload:', data.message);
        }
      } catch (error) {
        console.error('Erreur réseau:', error);
      }
    }
  };
  
  
  const handlePdfUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    const formData = new FormData();
    formData.append("pdfFile", file);
    formData.append('clientId', client._id);
    try {
      const response = await fetch(`https://app.vtalys.fr/api/upload/${client._id}`, {
        method: "POST",
        body: formData
      });
  
      const data = await response.json();
      if (response.ok) {
        const newFileObj = { 
          fileName: data.fileName, 
          fileData: data.fileData 
        };
  
        await updateClientWithPdf(newFileObj);
        setPdfFiles(prevFiles => [...prevFiles, newFileObj]);
      toast.success('Fichier PDF sauvegardé avec succès!');
    } else {
      console.error('Erreur lors de l’upload:', data.message);
    }
  } catch (error) {
    console.error('Erreur réseau:', error);
  }
};
  
  
  // const downloadPdf = (file) => {
  //   if (!file || !file.fileData || !file.fileName) {
  //     console.error("❌ Fichier invalide ou données manquantes :", file);
  //     return;
  //   }
  
  //   const byteCharacters = atob(file.fileData);
  //   const byteNumbers = new Array(byteCharacters.length);
  //   for (let i = 0; i < byteCharacters.length; i++) {
  //     byteNumbers[i] = byteCharacters.charCodeAt(i);
  //   }
  //   const byteArray = new Uint8Array(byteNumbers);
  //   const blob = new Blob([byteArray], { type: 'application/pdf' });
  
  //   const fileUrl = URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.href = fileUrl;
  //   link.download = file.fileName;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  
  const updateClientWithPdf = async (fileObj) => {
    try {
      const token = localStorage.getItem('token'); // récupère le token JWT depuis localStorage ou là où il est stocké
  
      const response = await fetch(`https://app.vtalys.fr/api/clients/${client._id}`, {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // 🔑 Ajout du token ici
        },
        body: JSON.stringify({
          ...client,
          pdfFiles: [...pdfFiles, fileObj]
        })
      });
  
      const data = await response.json();
      if (response.ok) {
        console.log("✅ Mise à jour client avec PDF réussie:", data);
      } else {
        console.error("❌ Erreur lors de la mise à jour client:", data.message);
      }
    } catch (error) {
      console.error("❌ Erreur réseau lors de l'update client:", error);
    }
  };
  

  useEffect(() => {
    const fetchPdfFiles = async () => {
      if (!client?._id) {
        return;
      }
      try {
        const response = await fetch(`https://app.vtalys.fr/api/clients/${client._id}/files`);
        if (!response.ok) throw new Error(`Erreur HTTP : ${response.status}`);
  
        const data = await response.json();
        console.log("📂 Fichiers reçus du serveur :", data.files);
        if (data.files && Array.isArray(data.files)) {
          setPdfFiles(data.files);
        }
      } catch (error) {
        console.error("❌ Erreur lors du chargement des fichiers:", error);
      }
    };
  
    fetchPdfFiles();
  }, [client?._id]);
  
    
  
const downloadPdf = (file) => {
  if (!file || !file.fileData || !file.fileName) {
    console.error("❌ Fichier invalide ou données manquantes :", file);
    return;
  }

  const byteCharacters = atob(file.fileData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });

  const fileUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = file.fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const uploadPdf = async (file) => {
  const formData = new FormData();
  formData.append('pdfFile', file);

  try {
    const response = await fetch(`https://app.vtalys.fr/api/upload/${client._id}`, {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();
    if (response.ok) {
      toast.success('Fichier PDF sauvegardé avec succès!');
      setPdfFiles(prevFiles => [...prevFiles, {
        fileName: file.fileName,
        fileData: data.fileData,
      }]);
    } else {
      toast.error('Erreur lors de la sauvegarde du fichier PDF');
    }
  } catch (error) {
    console.error('Erreur lors de l\'upload du fichier:', error);
    toast.error('Erreur lors de l\'upload du fichier');
  }
};

const filteredModelsG = [...new Set(
  stockProduits
    .filter(p => p.marque === client.marqueAppareilG)
    .map(p => p.model)
)];

const filteredModelsD = [...new Set(
  stockProduits
    .filter(p => p.marque === client.marqueAppareilD)
    .map(p => p.model)
)];

  
  return (
    
    //  <div style={{ paddingTop: '50px', background: 'linear-gradient(87deg, #11cdef 0, #1171ef 100%)' }}>
    <div style={{ paddingTop: '50px', paddingBottom: '30px', background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)' }}>
      <Container className="mt-5">
        <Card style={{ paddingBottom: '20px' }}>
          <ToastContainer position="bottom-left" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          <CardHeader className="bg-white text-white">
            <Button color="link" onClick={handleBack} style={{ marginRight: '20px', color: 'black' }}>
              <FaArrowLeft />
            </Button>
            <h4 className="mb-0">{client._id ? "Modifier Client" : "Ajouter Nouveau Client"}</h4>
            addLineBreakAfter(headerTitle);
            <div style={{ marginTop: '10px' }}> {/* Controlled space for line break in React */}
              

              <Nav style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginBottom: '20px'
              }}>
                {steps.map(step => (
                  <NavItem key={step.id}>
                    <NavLink
                      className={classnames({ active: activeTab === step.id })}
                      onClick={() => toggleTab(step.id)}
                      style={activeTab === step.id ? navLinkActiveStyle : navLinkStyle}
                      onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                      onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                    >
                      <i className={`${step.icon}`} style={{ paddingRight: '8px', color: '#28a745' }} />
                      {step.name}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

            </div>
          </CardHeader>


          <CardBody>

            <Nav tabs>
            </Nav>


            <div style={{ height: '1px', backgroundColor: 'transparent', marginTop: '10px', marginBottom: '10px' }}></div>
            {/* iciii */}
            
            <div style={{ height: '1px', backgroundColor: 'transparent', marginTop: '10px', marginBottom: '10px' }}></div>
            <TabContent activeTab={activeTab} className="mt-3">
              <TabPane tabId="1">
                {successMessage && (
                  <UncontrolledAlert
                    color="success"
                    className="fixed-alert"
                    style={{ position: 'fixed', top: '0', left: '0', right: '0', zIndex: '1050' }}
                    fade={false}
                  >
                    <span className="alert-inner--icon">
                      <i className="ni ni-like-2" />
                    </span>
                    <span className="alert-inner--text">
                      <strong>Succès!</strong> {successMessage}
                    </span>
                  </UncontrolledAlert>
                )}
                {/* Bouton Modifier placé en haut */}

                <Card style={{ borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', overflow: 'hidden', margin: '20px 0' }}>
                  <CardHeader style={{ background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)', color: 'white', textAlign: 'center', padding: '15px 0', fontSize: '1.2rem', fontWeight: 'bold' }}>
                    Informations Client
                  </CardHeader>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0', marginRight: '15px' }}>
                  <Button
                          type="submit"
                          color="primary"
                          onClick={() => {
                            if (validateForm()) {
                              saveClient(client);
                            }
                          }}
                          disabled={!isEditable}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            transition: 'all 0.3s ease',
                            background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
                            border: 'none',
                            color: 'white',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            fontSize: '16px',
                          }}
                          onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                        >
                          <FontAwesomeIcon icon={faSave} style={{ marginRight: '8px' }} />
                          Enregistrer
                        </Button>
                    <Button
                      type="button"
                      color="info"
                      onClick={() => setIsEditable(true)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px 20px',
                        borderRadius: '8px',
                        transition: 'all 0.3s ease',
                        background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
                        border: 'none',
                        color: 'white',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        fontSize: '16px'
                      }}
                      onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                      onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                      disabled={isEditable}
                    >
                      {/* modifier */}
                      <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '15px' }} />

                    </Button>
                  </div>
                  <CardBody style={{ padding: '30px', background: '#f8f9fa' }}>
                    <Form onSubmit={handleSubmit}>
                    <Label for="dateCreation">
        Date de création
        <Input
                              type="date"
                              name="dateCreation"
                              id="dateCreation"
                              value={client.dateCreation || ''}
                              onChange={handleInputChange}
                              disabled={!isEditable} 
                              className="form-control-alternative"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
                              readOnly
                            />
      </Label>
                      <Row form>
                      
                        <Col md={6}>
                          <FormGroup>
                            <Label for="nom">
                              Nom <span style={{ color: 'red', position: 'relative', fontSize: '15px' }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              name="nom"
                              id="nom"
                              value={client.nom}
                              onChange={handleInputChange}
                              required
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez le nom du client"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
                            />
                            {errors.nom && <p style={{ color: 'red' }}>{errors.nom}</p>}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="prenom">Prénom</Label>
                            <Input
                              type="text"
                              name="prenom"
                              id="prenom"
                              value={client.prenom}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez le prénom du client"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="dateNaissance">Date de Naissance</Label>
                            <Input
                              type="date"
                              name="dateNaissance"
                              id="dateNaissance"
                              value={client.dateNaissance || ''}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              onFocus={(e) => e.target.showPicker()} 
                              className="form-control-alternative"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="mutuelle">Mutuelle</Label>
                            <Input
                              type="text"
                              name="mutuelle"
                              id="mutuelle"
                              value={client.mutuelle}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez la mutuelle"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="numeroSecu">Numéro de Sécurité Sociale</Label>
                            <Input
                              type="text"
                              name="numeroSecu"
                              id="numeroSecu"
                              value={client.numeroSecu}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez le numéro de sécurité sociale"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="caisse">Caisse</Label>
                            <Input
                              type="text"
                              name="caisse"
                              id="caisse"
                              value={client.caisse}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez la caisse"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="origine">Origine</Label>
                            <Input
                              type="select"
                              name="origine"
                              id="origine"
                              value={client.origine}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
                            >
                              <option value="">Sélectionner une origine</option>
                              <option value="Site">Site</option>
                              <option value="Facebook">Facebook</option>
                              <option value="Ouieqare">Ouieqare</option>
                              <option value="Audibene">Audibene</option>
                              <option value="Direct">Direct</option>
                              <option value="Google">Google</option>
                              <option value="Doctolib">Doctolib</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                        
                        <Row form>
  {/* Colonne pour le champ "Statut" */}
  <Col md={4}>
    <FormGroup>
      <Label for="statut">Statut</Label>
      <Input
        type="select"
        name="statut"
        id="statut"
        value={client.statut}
        onChange={handleStatutChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
      >
        <option value="">Sélectionner un statut</option>
        <option value="Rdv Bilan auditif">Rdv Bilan auditif</option>
        <option value="Rdv Téléconsultation">Rdv Téléconsultation</option>
        <option value="Rdv devis">Rdv Devis</option>
        <option value="Rdv Annulé">Rdv Annulé</option>
        <option value="En livraison">En livraison</option>
        <option value="Rdv livraison">Rdv livraison</option>
        <option value="Appareillé">Appareillé</option>
        <option value="Rdv 1er essai">Rdv 1er essai</option>
        <option value="Rdv 2eme essai">Rdv 2eme essai</option>
        <option value="Rdv facturation">Rdv facturation</option>
        <option value="Facturé">Facturé</option>
        {/* <option value="Facture éditée">Facturé éditée</option> */}
      </Input>
    </FormGroup>
  </Col>

  {/* Colonne conditionnelle pour la date d'Appareillage (reste toujours visible) */}
  <Col md={4} style={{ minWidth: '250px' }}>
    {(client.statut === "Appareillé" || client.statut === "Facturé") && (
      <FormGroup>
        <Label for="dateAppareillage">Date d'Appareillage</Label>
        <Input
          type="date"
          name="dateAppareillage"
          id="dateAppareillage"
          value={client.dateAppareillage || ''}
          onChange={handleInputChange}
          disabled={!isEditable}
          onFocus={(e) => e.target.showPicker()} 
          className="form-control-alternative"
          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
        />
      </FormGroup>
    )}
  </Col>

  {/* Colonne pour les autres dates qui changent en fonction du statut */}
  <Col md={12} style={{ minWidth: '250px' }}>
    {client.statut === "Facturé" ? (
      <>
      <Row>
      <Col md={6}>
      <FormGroup>
        <Label for="dateFacturation">Date de Facturation</Label>
        <Input
          type="date"
          name="dateFacturation"
          id="dateFacturation"
          value={client.dateFacturation ? formatDate(client.dateFacturation) : ''}
          onChange={handleInputChange}
          disabled={!isEditable}
          onFocus={(e) => e.target.showPicker()} 
          className="form-control-alternative"
          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
        />
      </FormGroup>
      </Col>
        <Col md={6}>
      <FormGroup>
          <Label for="heureFacturation">Heure de Facturation</Label>
          <Input
            type="time"
            name="heureFacturation"
            id="heureFacturation"
            value={client.heureFacturation || ''}
            onChange={handleInputChange}
            disabled={!isEditable}
            className="form-control-alternative"
            style={{ /* styles */ }}
          />
        </FormGroup>
        </Col>
        </Row>
        {/* Remise */}
        <Row>
        <Col md={6}>
      <FormGroup>
        <Label for="remise">Remise</Label>
        <Input
          type="number"
          name="remise"
          id="remise"
          value={client.remise || ''}
          onChange={handleInputChange}
          disabled={!isEditable}
          className="form-control-alternative"
          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
        />
      </FormGroup>
      </Col>
      <Col md={6}>
      {/* Remboursement Sécurité Sociale */}
      <FormGroup>
        <Label for="rbstSS">Remboursement Sécurité Sociale</Label>
        <Input
          type="number"
          name="rbstSS"
          id="rbstSS"
          value={client.rbstSS || ''}
          onChange={handleInputChange}
          disabled={!isEditable}
          className="form-control-alternative"
          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
        />
      </FormGroup>
      </Col>
      </Row>
      {/* Remboursement Complémentaire */}
      <Row>
      <Col md={6}>
      <FormGroup>
        <Label for="rbstCompl">Remboursement Complémentaire</Label>
        <Input
          type="number"
          name="rbstCompl"
          id="rbstCompl"
          value={client.rbstCompl || ''}
          onChange={handleInputChange}
          disabled={!isEditable}
          className="form-control-alternative"
          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
        />
      </FormGroup>
      </Col>
      </Row>
      </>
    ) : client.statut === "Rdv Bilan auditif" ? (
      <>
      <FormGroup>
        <Label for="dateBilanAuditif">Date Bilan Auditif</Label>
        <Input
          type="date"
          name="dateBilanAuditif"
          id="dateBilanAuditif"
          value={client.dateBilanAuditif ? formatDate(client.dateBilanAuditif) : ''}
          onChange={handleInputChange}
          disabled={!isEditable}
          onFocus={(e) => e.target.showPicker()} 
          className="form-control-alternative"
          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
        />
      </FormGroup>
      <FormGroup>
          <Label for="heureBilanAuditif">Heure Bilan Auditif</Label>
          <Input
            type="time"
            name="heureBilanAuditif"
            id="heureBilanAuditif"
            value={client.heureBilanAuditif || ''}
            onChange={handleInputChange}
            disabled={!isEditable}
            className="form-control-alternative"
            style={{ /* styles */ }}
          />
        </FormGroup>
      </>
    ) : client.statut === "Rdv livraison" ? (
      <>
      <FormGroup>
        <Label for="dateRdvLivraison">Date de Livraison</Label>
        <Input
          type="date"
          name="dateRdvLivraison"
          id="dateRdvLivraison"
          value={client.dateRdvLivraison ? formatDate(client.dateRdvLivraison) : ''}
          onChange={handleInputChange}
          disabled={!isEditable}
          onFocus={(e) => e.target.showPicker()} 
          className="form-control-alternative"
          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
        />
      </FormGroup>
      <FormGroup>
          <Label for="heureRdvLivraison">Heure de Livraison</Label>
          <Input
            type="time"
            name="heureRdvLivraison"
            id="heureRdvLivraison"
            value={client.heureRdvLivraison || ''}
            onChange={handleInputChange}
            disabled={!isEditable}
            className="form-control-alternative"
            style={{ /* styles */ }}
          />
        </FormGroup>
      </>
      ) : client.statut === "Rdv devis" ? (
        <>
        <FormGroup>
          <Label for="dateRdvDevis">Date de Rdv Devis</Label>
          <Input
            type="date"
            name="dateRdvDevis"
            id="dateRdvDevis"
            value={client.dateRdvDevis ? formatDate(client.dateRdvDevis) : ''}
            onChange={handleInputChange}
            disabled={!isEditable}
            onFocus={(e) => e.target.showPicker()} 
            className="form-control-alternative"
            style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="heureRdvDevis">Heure de Rdv Devis</Label>
          <Input
            type="time"
            name="heureRdvDevis"
            id="heureRdvDevis"
            value={client.heureRdvDevis || ''}
            onChange={handleInputChange}
            disabled={!isEditable}
            className="form-control-alternative"
            style={{ /* styles */ }}
          />
        </FormGroup>
      </>
        ) : client.statut === "Rdv Téléconsultation" ? (
          <>
          <FormGroup>
            <Label for="dateRdvTeleconsultation">Date de Rdv Teleconsultation</Label>
            <Input
              type="date"
              name="dateRdvTeleconsultation"
              id="dateRdvTeleconsultation"
              value={client.dateRdvTeleconsultation ? formatDate(client.dateRdvTeleconsultation) : ''}
              onChange={handleInputChange}
              disabled={!isEditable}
              onFocus={(e) => e.target.showPicker()} 
              className="form-control-alternative"
              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
            />
          </FormGroup>
          <FormGroup>
          <Label for="heureRdvTeleconsultation">Heure de Rdv Téléconsultation</Label>
          <Input
            type="time"
            name="heureRdvTeleconsultation"
            id="heureRdvTeleconsultation"
            value={client.heureRdvTeleconsultation || ''}
            onChange={handleInputChange}
            disabled={!isEditable}
            className="form-control-alternative"
            style={{ /* styles */ }}
          />
        </FormGroup>
      </>
    ) : client.statut === "Rdv 1er essai" ? (
      <>
      <FormGroup>
        <Label for="datePremierEssai">Date 1er Essai</Label>
        <Input
          type="date"
          name="datePremierEssai"
          id="datePremierEssai"
          value={client.datePremierEssai ? formatDate(client.datePremierEssai) : ''}
          onChange={handleInputChange}
          disabled={!isEditable}
          onFocus={(e) => e.target.showPicker()} 
          className="form-control-alternative"
          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
        />
      </FormGroup>
      <FormGroup>
          <Label for="heurePremierEssai">Heure 1er Essai</Label>
          <Input
            type="time"
            name="heurePremierEssai"
            id="heurePremierEssai"
            value={client.heurePremierEssai || ''}
            onChange={handleInputChange}
            disabled={!isEditable}
            className="form-control-alternative"
            style={{ /* styles */ }}
          />
        </FormGroup>
      </>
    ) : client.statut === "Rdv 2eme essai" ? (
      <>
      <FormGroup>
        <Label for="dateDeuxiemeEssai">Date 2ème Essai</Label>
        <Input
          type="date"
          name="dateDeuxiemeEssai"
          id="dateDeuxiemeEssai"
          value={client.dateDeuxiemeEssai ? formatDate(client.dateDeuxiemeEssai): ''}
          onChange={handleInputChange}
          disabled={!isEditable}
          onFocus={(e) => e.target.showPicker()} 
          className="form-control-alternative"
          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
        />
      </FormGroup>
      <FormGroup>
          <Label for="heureDeuxiemeEssai">Heure 2ème Essai</Label>
          <Input
            type="time"
            name="heureDeuxiemeEssai"
            id="heureDeuxiemeEssai"
            value={client.heureDeuxiemeEssai || ''}
            onChange={handleInputChange}
            disabled={!isEditable}
            className="form-control-alternative"
            style={{ /* styles */ }}
          />
        </FormGroup>
      </>
    ) : client.statut === "Rdv facturation" ? (
      <>
      <FormGroup>
        <Label for="dateRdvFacturation">Date Rdv Facturation</Label>
        <Input
          type="date"
          name="dateRdvFacturation"
          id="dateRdvFacturation"
          value={client.dateRdvFacturation ? formatDate(client.dateRdvFacturation) : ''}
          onChange={handleInputChange}
          disabled={!isEditable}
          onFocus={(e) => e.target.showPicker()} 
          className="form-control-alternative"
          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
        />
      </FormGroup>
      <FormGroup>
          <Label for="heureRdvFacturation">Heure Rdv Facturation</Label>
          <Input
            type="time"
            name="heureRdvFacturation"
            id="heureRdvFacturation"
            value={client.heureRdvFacturation || ''}
            onChange={handleInputChange}
            disabled={!isEditable}
            className="form-control-alternative"
            style={{ /* styles */ }}
          />
        </FormGroup>
      </>
    ) : (
      <div style={{ height: '56px' }}></div> // Espace vide si aucun statut ne correspond
    )}
  </Col>
</Row>


                       

                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="telephoneFixe">Téléphone Fixe</Label>
                            <Input
                              type="text"
                              name="telephoneFixe"
                              id="telephoneFixe"
                              value={client.telephoneFixe}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez le téléphone fixe"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="telephonePortable">Téléphone Portable</Label>
                            <Input
                              type="text"
                              name="telephonePortable"
                              id="telephonePortable"
                              value={client.telephonePortable}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez le téléphone portable"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="codePostal">Code Postal</Label>
                            <Input
                              type="text"
                              name="codePostal"
                              id="codePostal"
                              value={client.codePostal}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez le code postal"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="ville">Ville</Label>
                            <Input
                              type="text"
                              name="ville"
                              id="ville"
                              value={client.ville}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez la ville"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormGroup>
                        <Label for="adresse">Adresse</Label>
                        <Input
                          type="text"
                          name="adresse"
                          id="adresse"
                          value={client.adresse}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className="form-control-alternative"
                          placeholder="Entrez l'adresse"
                          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', minHeight: '50px', color: '#000' }}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="email">
                          Email <span style={{ color: 'red', position: 'relative', fontSize: '15px' }}>*</span>
                        </Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          value={client.email}
                          onChange={handleInputChange}
                          required
                          disabled={!isEditable}
                          className="form-control-alternative"
                          placeholder="Entrez l'email"
                          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
                        />
                        {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                      </FormGroup>

                      <FormGroup>
                        <Label for="input-prescripteur" className="form-control-label">Prescripteur</Label>
                        <Select
                          id="input-prescripteur"
                          value={selectedOrl}
                          onChange={handleSelectChange}
                          options={orlOptions}
                          classNamePrefix="select"
                          placeholder="Choisir un prescripteur"
                          isClearable={true}
                          isSearchable={true}
                          isDisabled={!isEditable}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              minHeight: 'calc(1.5em + 1.3rem + 2px)',
                              height: 'calc(2.4rem + 2px)',
                              borderRadius: '8px',
                              border: '1px solid #ddd', color: '#000'
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              height: 'calc(2.4rem + 2px)'
                            })
                          }}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="note">Note</Label>
                        <Input
                          type="textarea"
                          name="note"
                          id="note"
                          value={client.note}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className="form-control-alternative"
                          placeholder="Entrez une note"
                          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', minHeight: '100px', maxHeight: '300px' }}
                        />
                      </FormGroup>
                      <div
  onDrop={handlePdfDrop}
  onDragOver={(e) => e.preventDefault()}
  style={{
    border: '2px dashed #007D70',
    borderRadius: '10px',
    padding: '20px',
    textAlign: 'center',
    marginBottom: '20px',
    cursor: 'pointer',
    backgroundColor: '#f8f9fa'
  }}
>
  <p>Glissez et déposez les fichiers PDF ici ou</p>
  <input
    type="file"
    accept="application/pdf"
    multiple
    onChange={handlePdfUpload}
    style={{ display: 'none' }}
    id="file-upload"
  />
  <label htmlFor="file-upload" style={{ cursor: 'pointer', color: '#007D70', textDecoration: 'underline' }}>
    Cliquez pour sélectionner des fichiers PDF
  </label>
</div>
{console.log("📂 Liste des fichiers avant affichage :", pdfFiles)}

<ul>
  {pdfFiles.length > 0 ? (
    pdfFiles.map((file) => (
      <li key={file.fileId}>
        <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '8px', color: '#007D70' }} />
        {file.fileName}
        <Button color="link" onClick={() => downloadPdf(file.fileId)}>Télécharger</Button>
      </li>
    ))
  ) : (
    <p>Aucun fichier disponible.</p>
  )}
</ul>






                      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>


                        
                        {/* <div style={{ float: 'right' }}> */}
              {/* {client._id && (
                <>
                  <Button
                    color="primary"
                    onClick={() => {
                      if (validateForm()) {
                        saveClient(client);
                      }
                    }}
                    disabled={!isEditable}
                    style={{
                      marginLeft: '10px',
                      background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
                      border: 'none' // Ajouté pour enlever la bordure par défaut qui peut altérer le rendu du dégradé
                    }}
                  >
                    Enregistrer Modifications
                  </Button>

                </>
              )} */}
              {/* <Button
    color="primary"
    onClick={() => {
      if (validateForm()) {
        saveClient(client);
      }
    }}
    disabled={!isEditable}
    style={{
      marginLeft: '10px',
      background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
      border: 'none' // Ajouté pour enlever la bordure par défaut qui peut altérer le rendu du dégradé
    }}
  >
    {client._id ? "Enregistrer Modifications" : "Enregistrer Nouveau Client"}
  </Button>
            </div> */}
                        <Button
                          type="button"
                          color="secondary"
                          onClick={() => history.push('/admin/clients')}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            transition: 'all 0.3s ease',
                            background: 'linear-gradient(87deg, #6c757d 0%, #5a6268 100%)',
                            border: 'none',
                            color: 'white',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            fontSize: '16px',
                          }}
                          onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                        >
                          <FontAwesomeIcon icon={faTimes} style={{ marginRight: '8px' }} />
                          Annuler
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </TabPane>

              <TabPane tabId="2">
                {audiogrammeSuccessMessage && (
                  <UncontrolledAlert color="success" className="fixed-alert" fade={false}>
                    <span className="alert-inner--icon"><i className="ni ni-like-2" /></span>
                    <span className="alert-inner--text"><strong>Succès!</strong> {audiogrammeSuccessMessage}</span>
                  </UncontrolledAlert>
                )}
                <Form onSubmit={handleSubmitAudiogramme} style={{ paddingTop: '50px' }}>
                  <FormGroup>
                    <Label for="audiogramme">Audiogramme</Label>
                    <Input type="text" name="audiogramme" id="audiogramme" value={client.audiogramme} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  {/* Ajoutez plus de champs selon vos besoins ici */}
                  <Button type="submit" color="primary">Enregistrer Rdv Bilan auditif</Button>
                </Form>
              </TabPane>
              <TabPane tabId="3">
                {/* <Form onSubmit={handleSubmitAudiogramme} style={{ paddingTop: '50px' }}>
                  <FormGroup>
                    <Label for="typeAppareil">Type d'Appareil</Label>
                    <Input type="text" name="typeAppareil" id="typeAppareil" value={client.typeAppareil} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="modeleAppareil">Modèle de l'Appareil</Label>
                    <Input type="text" name="modeleAppareil" id="modeleAppareil" value={client.modeleAppareil} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="numeroSerie">Numéro de Série</Label>
                    <Input type="text" name="numeroSerie" id="numeroSerie" value={client.numeroSerie} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="prix">Prix</Label>
                    <Input type="number" name="prix" id="prix" value={client.prix} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="garantie">Durée de la Garantie</Label>
                    <Input type="text" name="garantie" id="garantie" value={client.garantie} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="servicesInclus">Services Inclus</Label>
                    <Input type="text" name="servicesInclus" id="servicesInclus" value={client.servicesInclus} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="acompte">Acompte Requis</Label>
                    <Input type="number" name="acompte" id="acompte" value={client.acompte} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <Button color="secondary" onClick={downloadPDF}>Générer Devis PDF</Button>
                  <Button type="submit" color="primary">Enregistrer Devis</Button>
                </Form> */}
                {console.log("Données du client dans l'onglet Devis:", client)}
                <Card style={{ borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', overflow: 'hidden', margin: '20px 0' }}>
                  <CardHeader style={{ background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)', color: 'white', textAlign: 'center', padding: '15px 0', fontSize: '1.2rem', fontWeight: 'bold' }}>
                    Devis
                  </CardHeader>
                 
                  <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0', marginRight: '15px' }}>
                  <Button
  color="secondary"
  onClick={() => generateDevisPDF(client, user)}  // Passez l'ID du client et les données du devis
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
    borderRadius: '8px',
    transition: 'all 0.3s ease',
    background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)',
    border: 'none',
    color: 'white',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    fontSize: '16px',
  }}
  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
>
  <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '8px' }} />
  Générer Devis PDF
</Button>
                    <Button
                      type="button"
                      color="info"
                      onClick={() => setIsEditable(true)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px 20px',
                        borderRadius: '8px',
                        transition: 'all 0.3s ease',
                        background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)',
                        border: 'none',
                        color: 'white',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        fontSize: '16px'
                      }}
                      onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                      onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                      disabled={isEditable}
                    >
                      {/* modifier */}
                      <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '15px' }} />

                    </Button>
                  </div>
                  <CardBody style={{ padding: '30px', background: '#f8f9fa' }}>
                    <Form onSubmit={handleSubmitAudiogramme}>
                      <Row form>
                      
</Row>

<Row form>
  <Col md={6}>
    <FormGroup>
      <Label for="dateDevis">Date du Devis</Label>
      <Input
        type="date"
        name="dateDevis"
        id="dateDevis"
        value={client.dateDevis || ''}
        onChange={handleInputChange}
        disabled={!isEditable}
        onFocus={(e) => e.target.showPicker()} 
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
  </Col>
  <Col md={6}>
    <FormGroup>
      <Label for="numeroDevis">Numéro du Devis</Label>
      <Input
        type="text"
        name="numeroDevis"
        id="numeroDevis"
        placeholder="Numéro du Devis"
        value={client.numeroDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
  </Col>
  <Col md={6}>
    <FormGroup>
      <Label for="dureeValiditeDevis">Durée de Validité du Devis (en jours)</Label>
      <Input
        type="number"
        name="dureeValiditeDevis"
        id="dureeValiditeDevis"
        placeholder="Durée de validité"
        value={client.dureeValiditeDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
  </Col>
</Row>

{/* Section Appareil Gauche et Droit */}
<Row form>
  <Col md={6}>
    <h5 className="text-center">Appareil Gauche</h5>
    <FormGroup>
      <Label for="marqueAppareilGDevis">Marque Appareil</Label>
      <Input
        type="text"
        name="marqueAppareilGDevis"
        id="marqueAppareilGDevis"
        placeholder="Ex: Appareil auditif"
        value={client.marqueAppareilGDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
    <FormGroup>
      <Label for="modeleAppareilGDevis">Modèle de l'Appareil</Label>
      <Input
        type="text"
        name="modeleAppareilGDevis"
        id="modeleAppareilGDevis"
        placeholder="Ex: Modèle XYZ"
        value={client.modeleAppareilGDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
    <FormGroup>
      <Label for="numeroSerieGDevis">Numéro de Série</Label>
      <Input
        type="text"
        name="numeroSerieGDevis"
        id="numeroSerieGDevis"
        placeholder="Ex: 123456789"
        value={client.numeroSerieGDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
    <FormGroup>
      <Label for="prixHTGDevis">Prix HT (€)</Label>
      <Input
        type="number"
        name="prixHTGDevis"
        id="prixHTGDevis"
        placeholder="Prix Hors Taxes"
        value={client.prixHTGDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
    {/* <FormGroup>
      <Label for="prixTTCGDevis">Prix TTC (€)</Label>
      <Input
        type="number"
        name="prixTTCGDevis"
        id="prixTTCG"
        placeholder="Prix Toutes Taxes Comprises"
        value={client.prixTTCGDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup> */}
  </Col>

  <Col md={6}>
    <h5 className="text-center">Appareil Droit</h5>
    <FormGroup>
      <Label for="marqueAppareilDDevis">Marque Appareil</Label>
      <Input
        type="text"
        name="marqueAppareilDDevis"
        id="marqueAppareilDDevis"
        placeholder="Ex: Appareil auditif"
        value={client.marqueAppareilDDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
    <FormGroup>
      <Label for="modeleAppareilDDevis">Modèle de l'Appareil</Label>
      <Input
        type="text"
        name="modeleAppareilDDevis"
        id="modeleAppareilDDevis"
        placeholder="Ex: Modèle XYZ"
        value={client.modeleAppareilDDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
    <FormGroup>
      <Label for="numeroSerieDDevis">Numéro de Série</Label>
      <Input
        type="text"
        name="numeroSerieDDevis"
        id="numeroSerieDDevis"
        placeholder="Ex: 123456789"
        value={client.numeroSerieDDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
    <FormGroup>
      <Label for="prixHTDDevis">Prix HT (€)</Label>
      <Input
        type="number"
        name="prixHTDDevis"
        id="prixHTDDevis"
        placeholder="Prix Hors Taxes"
        value={client.prixHTDDevis || ''}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
    {/* <FormGroup>
      <Label for="prixTTCDDevis">Prix TTC (€)</Label>
      <Input
        type="number"
        name="prixTTCDDevis"
        id="prixTTCDDevis"
        placeholder="Prix Toutes Taxes Comprises"
        value={client.prixTTCDDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup> */}
  </Col>
</Row>

<Row form>
  <Col md={6}>
    <FormGroup>
      <Label for="classeAppareilDevis">Classe de l'Appareil</Label>
      <Input
        type="text"
        name="classeAppareilDevis"
        id="classeAppareilDevis"
        placeholder="Classe 1 ou Classe 2"
        value={client.classeAppareilDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
  </Col>
  
  </Row>

<Row form>
  <Col md={6}>
    <FormGroup>
      <Label for="rbstSSDevis">Remboursement Sécurité Sociale (€)</Label>
      <Input
        type="number"
        name="rbstSSDevis"
        id="rbstSSDevis"
        placeholder="Montant remboursé"
        value={client.rbstSSDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
  </Col>
  <Col md={6}>
    <FormGroup>
      <Label for="rbstComplDevis">Remboursement Mutuelle (€)</Label>
      <Input
        type="number"
        name="rbstComplDevis"
        id="rbstComplDevis"
        placeholder="Montant remboursé par la Mutuelle"
        value={client.rbstComplDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
  </Col>
</Row>

<Row form>
  <Col md={6}>
    <FormGroup>
      <Label for="garantieDevis">Durée de la Garantie</Label>
      <Input
        type="text"
        name="garantieDevis"
        id="garantieDevis"
        placeholder="Ex: 2 ans"
        value={client.garantieDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
  </Col>
  <Col md={6}>
    <FormGroup>
      <Label for="servicesInclusDevis">Services Inclus</Label>
      <Input
        type="text"
        name="servicesInclusDevis"
        id="servicesInclusDevis"
        placeholder="Ex: Ajustements gratuits"
        value={client.servicesInclusDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
  </Col>
</Row>
{/* Prix accessoires */}
<Row form>
  <Col md={6}>
    <FormGroup>
      <Label for="prixHTAccessoiresDevis">Prix HT des Accessoires (€)</Label>
      <Input
        type="number"
        name="prixHTAccessoiresDevis"
        id="prixHTAccessoiresDevis"
        placeholder="Prix Hors Taxes des accessoires"
        value={client.prixHTAccessoiresDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
  </Col>
  {/* <Col md={6}>
    <FormGroup>
      <Label for="prixTTCAccessoiresDevis">Prix TTC des Accessoires (€)</Label>
      <Input
        type="number"
        name="prixTTCAccessoiresDevis"
        id="prixTTCAccessoiresDevis"
        placeholder="Prix Toutes Taxes Comprises des accessoires"
        value={client.prixTTCAccessoiresDevis}
        onChange={handleInputChange}
        disabled={!isEditable}
        className="form-control-alternative"
        style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
      />
    </FormGroup>
  </Col> */}
</Row>

<FormGroup>
  <Label for="acompteDevis">Acompte Requis (€)</Label>
  <Input
    type="number"
    name="acompteDevis"
    id="acompteDevis"
    placeholder="Ex: 200"
    value={client.acompteDevis}
    onChange={handleInputChange}
    disabled={!isEditable}
    className="form-control-alternative"
    style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
  />
</FormGroup>

<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                      

                        {/* <Button
                          type="submit"
                          color="primary"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            transition: 'all 0.3s ease',
                            background: 'linear-gradient(87deg, #007BFF 0%, #0056b3 100%)',
                            border: 'none',
                            color: 'white',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            fontSize: '16px',
                          }}
                          onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                        >
                          <FontAwesomeIcon icon={faSave} style={{ marginRight: '8px' }} />
                          Enregistrer Devis
                        </Button> */}
                        <Button
                        type="submit"
    color="primary"
    onClick={() => {
      if (validateForm()) {
        saveClient(client);
      }
    }}
    disabled={!isEditable}
    style={{
      marginLeft: '10px',
      background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
      border: 'none' // Ajouté pour enlever la bordure par défaut qui peut altérer le rendu du dégradé
    }}
  >
    {client._id ? "Enregistrer Modifications" : "Enregistrer Nouveau Client"}
  </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </TabPane>

              {/* <TabPane tabId="4">
  <Form onSubmit={handleSubmitAppareillage} style={{ paddingTop: '50px' }}>
    <FormGroup>
      <Label for="marqueAppareilG">Produit 1 </Label>
      <Input type="text" name="marqueAppareilG" id="marqueAppareilG" value={client.marqueAppareilG} onChange={handleInputChange} />
    </FormGroup>
    <FormGroup>
      <Label for="marqueAppareil2">Produit 2 </Label>
      <Input type="text" name="marqueAppareil" id="marqueAppareil" value={client.marqueAppareil2} onChange={handleInputChange} />
    </FormGroup>
    <FormGroup>
      <Label for="codeProduit">Code du Produit</Label>
      <Input type="text" name="codeProduit" id="codeProduit" value={client.codeProduit} onChange={handleInputChange} />
    </FormGroup>
    <FormGroup>
              <Label for="productQuantity">Quantité</Label>
              <Input
                type="number"
                id="productQuantity"
                name="quantity"
                value={client.quantity}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
    <FormGroup>
      <Label for="montantAppareil">Montant du Produit</Label>
      <Input type="text" name="montantAppareil" id="montantAppareil" value={client.montantAppareil} onChange={handleInputChange} />
    </FormGroup>
    <FormGroup check>
      <Label check>
        <Input type="checkbox" name="monoAppareil" id="monoAppareil" checked={client.monoAppareil || false} onChange={handleCheckboxChange} />
        Mono Appareil
      </Label>
    </FormGroup>
    <FormGroup>
      <Label for="observations">Observations</Label>
      <Input type="textarea" name="Appobservations" id="Appobservations" value={client.Appobservations} onChange={handleInputChange} />
    </FormGroup>
    <Button type="submit" color="primary">Enregistrer Appareillage</Button>
  </Form>
</TabPane> */}

              {/* <TabPane tabId="4">
              {audiogrammeSuccessMessage && (
                  <UncontrolledAlert color="success" className="fixed-alert" fade={false}>
                    <span className="alert-inner--icon"><i className="ni ni-like-2" /></span>
                    <span className="alert-inner--text"><strong>Succès!</strong> {audiogrammeSuccessMessage}</span>
                  </UncontrolledAlert>
                )}
                <Form onSubmit={handleSubmitAppareillage} style={{ paddingTop: '30px' }}>
                  <div style={containerStyle}>
                    <div style={EarCardStyle}>
                      <h5><strong>Appareil Gauche</strong></h5>
                      {[
                        { id: 'marqueAppareilG', label: 'Marque' },
                        { id: 'modelAppareilG', label: 'Modèle' },
                        { id: 'codeProduitG', label: 'Code' },
                        { id: 'lppG', label: 'LPP' },
                        { id: 'quantityG', label: 'Quantité', type: 'number' },
                        { id: 'montantAppareilG', label: 'Montant HT' }
                      ].map(({ id, label, type }) => (
                        <FormGroup key={id} style={formGroupStyle}>
                          <Label for={id} style={labelStyle}>{label}</Label>
                          <Input
                            type={type || 'text'}
                            name={id}
                            id={id}
                            value={client.id}
                            onChange={handleInputChange}
                            style={{ fontSize: '12px', padding: '6px' }}
                            disabled={!isEditable} // Désactive le champ si isEditable est false
                          />
                        </FormGroup>
                      ))}
                    </div>

                    <div style={EarCardStyle}>
                      <h5><strong>Appareil Droit</strong></h5>
                      {[
                        { id: 'marqueAppareilD', label: 'Marque' },
                        { id: 'modelAppareilD', label: 'Modèle' },
                        { id: 'codeProduitD', label: 'Code' },
                        { id: 'lppD', label: 'LPP' },
                        { id: 'quantiteD', label: 'Quantité', type: 'number' },
                        { id: 'montantAppareilD', label: 'Montant HT' }
                      ].map(({ id, label, type }) => (
                        <FormGroup key={id} style={formGroupStyle}>
                          <Label for={id} style={labelStyle}>{label}</Label>
                          <Input
                            type={type || 'text'}
                            name={id}
                            id={id}
                            value={client.id}
                            onChange={handleInputChange}
                            style={{ fontSize: '12px', padding: '6px' }}
                            disabled={!isEditable} // Désactive le champ si isEditable est false
                          />
                        </FormGroup>
                      ))}
                    </div>
                  </div>
                  <Button type="submit" color="primary" style={{ width: '100%', maxWidth: '300px', margin: '0 auto', display: 'block' }}>Enregistrer Appareillage</Button>
                </Form>
              </TabPane> */}
              <TabPane tabId="4">
              <Button
                          type="submit"
                          color="primary"
                          onClick={saveAppareillage}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            transition: 'all 0.3s ease',
                            background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
                            border: 'none',
                            color: 'white',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            fontSize: '16px',
                          }}
                          onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                        >
                          <FontAwesomeIcon icon={faSave} style={{ marginRight: '8px' }} />
                          Enregistrer
                        </Button>
                <div style={styles.container}>
                  {/* Carte Oreille Gauche */}
                  <div
                    style={styles.card}
                    onClick={() => toggleFlip('left')}
                    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                  >
                    <div
                      style={{
                        ...styles.cardInner,
                        transform: isFlipped.left ? 'rotateY(180deg)' : 'rotateY(0)',
                      }}
                    >
                      {/* Face avant */}
                      <div style={styles.cardFace}>
                        <h2 style={{...styles.detailsTitle, marginBottom: '40px'}}>Oreille Gauche</h2>
                        <img src={EarLeft} alt="Illustration de l'oreille gauche" style={styles.image} />
                        <p style={{ fontSize: '16px' }}>Marque : {client.marqueAppareilG}</p>
                        <p style={{ fontSize: '16px' }}>Modèle : {client.modelAppareilG}</p>
                        <button
                          style={styles.button}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFlip('left');
                          }}
                          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                        >
                          {isFlipped.left ? 'Retourner' : "Afficher plus d'infos"}
                        </button>
                      </div>
                      {/* Face arrière */}
                      <div style={{ ...styles.cardFace, ...styles.cardBack }}>
                        <h2>Oreille Gauche - Détails</h2>
                        <button
                          type="button"
                          onClick={handleEditToggle}
                          style={{
                            ...styles.pencilButton,
                            ...(hovered ? styles.pencilButtonHover : {}),
                          }}
                          onMouseEnter={() => setHovered(true)}
                          onMouseLeave={() => setHovered(false)}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                        {isEditable ? (
                          <>
                          {/* <input
                              type="text"
                              name="marqueAppareilG"
                              value={client.marqueAppareilG || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Marque"
                            />
                            <input
                              type="text"
                              name="modelAppareilG"
                              value={client.modelAppareilG || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Model"
                            /> */}
                            {/* Liste déroulante pour la marque */}
                            <Input
      type="select"
      name="marqueAppareilG"
      id="marqueAppareilG"
      value={client.marqueAppareilG || ''}
      onChange={handleMarqueChange} // Utilisez `handleMarqueChange` ici
      onClick={(e) => e.stopPropagation()}
      style={{ width: '200px', color: 'black' }}
    >
      <option value="">Sélectionner une marque</option>
      {[...new Set(stockProduits.filter(p => p.quantity > 0).map(p => p.marque))].map((marque, i) => (
  <option key={i} value={marque}>{marque}</option>
))}

    </Input>

          {/* Liste déroulante pour le modèle */}
          <Input
      type="select"
      name="modelAppareilG"
      id="modelAppareilG"
      value={client.modelAppareilG || ''}
      onChange={handleInputChange}
      onClick={(e) => e.stopPropagation()}
      disabled={!client.marqueAppareilG} // Désactiver si aucune marque n'est sélectionnée
      style={{ width: '200px', color: 'black' }}
    >
      <option value="">Sélectionner un modèle</option>
      {stockProduits
  .filter(p => p.marque === client.marqueAppareilG && p.oreilleGauche)
  .map((p, i) => (
    <option key={i} value={p.model}>{p.model}</option>
))}

    </Input>

    <Input
  type="select"
  name="codeProduitG"
  id="codeProduitG"
  value={client.codeProduitG || ''}
  onChange={handleInputChange}
  onClick={(e) => e.stopPropagation()}
  disabled={!client.modelAppareilG}
  style={{ width: '200px', color: 'black' }}
>
  <option value="">Sélectionner un code produit</option>
  {stockProduits
  .filter(p => p.model === client.modelAppareilG && p.oreilleGauche)
  .map((p, i) => (
    <option key={i} value={p.code}>{p.code}</option>
))}

</Input>

<Input
  type="select"
  name="lppG"
  id="lppG"
  value={client.lppG || ''}
  onChange={handleInputChange}
  onClick={(e) => e.stopPropagation()}
  disabled={!client.modelAppareilG}
  style={{ width: '200px', color: 'black' }}
>
  <option value="">Sélectionner un LPP</option>
  {stockProduits
  .filter(p => p.model === client.modelAppareilG && p.oreilleGauche)
  .map((p, i) => (
    <option key={i} value={p.lpp}>{p.lpp}</option>
))}

</Input>

                            <input
                              type="number"
                              name="quantityG"
                              value={client.quantityG || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Quantité"
                              style={{ width: '200px', color: 'black' }}
                            />
                            <input
                              type="number"
                              name="montantAppareilG"
                              value={client.montantAppareilG || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Montant HT"
                              style={{ width: '200px', color: 'black' }}
                            />
                            <button onClick={handleEditToggle} style={styles.saveButton}>
                              <FontAwesomeIcon icon={faSave} /> Enregistrer
                            </button>
                          </>
                        ) : (
                          <>
                          <p style={{ fontSize: '16px' }}><strong>Marque : </strong>{client.marqueAppareilG}</p>
                          <p style={{ fontSize: '16px' }}><strong>Model : </strong>{client.modelAppareilG}</p>
                            <p style={{ fontSize: '16px' }}><strong>Code : </strong>{client.codeProduitG}</p>
                            <p style={{ fontSize: '16px' }}><strong>LPP : </strong> {client.lppG}</p>
                            <p style={{ fontSize: '16px' }}><strong>Quantité : </strong> {client.quantityG}</p>
                            <p style={{ fontSize: '16px' }}><strong>Montant HT : </strong> {client.montantAppareilG}</p>
                          </>
                        )}
                        <button
                          style={styles.button}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFlip('left');
                          }}
                          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                        >
                          <FontAwesomeIcon icon={faTimes} /> Retourner
                        </button>
                      </div>
                    </div>
                  </div>

                  <div style={styles.linkContainer}>
                    <img src={LinkIcon} alt="Lien entre les cartes" style={styles.linkIcon} />
                  </div>

                  {/* Carte Oreille Droite */}
                  <div
                    style={styles.card}
                    onClick={() => toggleFlip('right')}
                    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                  >
                    <div
                      style={{
                        ...styles.cardInner,
                        transform: isFlipped.right ? 'rotateY(180deg)' : 'rotateY(0)',
                      }}
                    >
                      {/* Face avant */}
                      <div style={styles.cardFace}>
                        <h2 style={{...styles.detailsTitle, marginBottom: '40px'}} >Oreille Droite</h2>
                        <img src={EarRight} alt="Illustration de l'oreille Droite" style={styles.image} />
                        <p style={{ fontSize: '16px' }}>Marque : {client.marqueAppareilD}</p>
                        <p style={{ fontSize: '16px' }}>Modèle : {client.modelAppareilD}</p>
                        <button
                          style={styles.button}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFlip('right');
                          }}
                          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                        >
                          {isFlipped.right ? 'Retourner' : "Afficher plus d'infos"}
                        </button>
                      </div>
                      {/* Face arrière */}
                      <div style={{ ...styles.cardFace, ...styles.cardBack }}>
                        <h2>Oreille Droite - Détails</h2>
                        <button
                          type="button"
                          onClick={handleEditToggle}
                          style={{
                            ...styles.pencilButton,
                            ...(hovered ? styles.pencilButtonHover : {}),
                          }}
                          onMouseEnter={() => setHovered(true)}
                          onMouseLeave={() => setHovered(false)}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                        {isEditable ? (
                          <>
                          {/* <input
                              type="text"
                              name="marqueAppareilD"
                              value={client.marqueAppareilD || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Marque"
                            />
                            <input
                              type="text"
                              name="modelAppareilD"
                              value={client.modelAppareilD || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Model"
                            /> */}
                            {/* Liste déroulante pour la marque */}
                            <Input
      type="select"
      name="marqueAppareilD"
      id="marqueAppareilD"
      value={client.marqueAppareilD || ''}
      onChange={handleMarqueChange} // Utilisez `handleMarqueChange` ici
      onClick={(e) => e.stopPropagation()}
      style={{ width: '200px', color: 'black' }}
    >
      <option value="">Sélectionner une marque</option>
      {[...new Set(stockProduits.filter(p => p.quantity > 0).map(p => p.marque))].map((marque, i) => (
  <option key={i} value={marque}>{marque}</option>
))}

    </Input>

          {/* Liste déroulante pour le modèle */}
          <Input
      type="select"
      name="modelAppareilD"
      id="modelAppareilD"
      value={client.modelAppareilD || ''}
      onChange={handleInputChange}
      onClick={(e) => e.stopPropagation()}
      disabled={!client.marqueAppareilD} // Désactiver si aucune marque n'est sélectionnée
      style={{ width: '200px', color: 'black' }}
    >
      <option value="">Sélectionner un modèle</option>
      {stockProduits
  .filter(p => p.marque === client.marqueAppareilD && p.oreilleDroite)
  .map((p, i) => (
    <option key={i} value={p.model}>{p.model}</option>
))}
    </Input>
    <Input
  type="select"
  name="codeProduitD"
  id="codeProduitD"
  value={client.codeProduitD || ''}
  onChange={handleInputChange}
  onClick={(e) => e.stopPropagation()}
  disabled={!client.modelAppareilD}
  style={{ width: '200px', color: 'black' }}
>
  <option value="">Sélectionner un code produit</option>
  {stockProduits
  .filter(p => p.model === client.modelAppareilD && p.oreilleDroite)
  .map((p, i) => (
    <option key={i} value={p.code}>{p.code}</option>
))}

</Input>
<Input
  type="select"
  name="lppD"
  id="lppD"
  value={client.lppD || ''}
  onChange={handleInputChange}
  onClick={(e) => e.stopPropagation()}
  disabled={!client.modelAppareilD}
  style={{ width: '200px', color: 'black' }}
>
  <option value="">Sélectionner un LPP</option>
  {stockProduits
  .filter(p => p.model === client.modelAppareilD && p.oreilleDroite)
  .map((p, i) => (
    <option key={i} value={p.lpp}>{p.lpp}</option>
))}
</Input>

                            <input
                              type="number"
                              name="quantityD"
                              value={client.quantityD || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Quantité"
                              style={{ width: '200px', color: 'black' }}
                            />
                            <input
                              type="number"
                              name="montantAppareilD"
                              value={client.montantAppareilD || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Montant HT"
                              style={{ width: '200px', color: 'black' }}
                            />
                            <button onClick={handleEditToggle} style={styles.saveButton}>
                              <FontAwesomeIcon icon={faSave} /> Enregistrer
                            </button>
                          </>
                        ) : (
                          <>
                          <p style={{ fontSize: '16px' }}><strong>Marque : </strong>{client.marqueAppareilD}</p>
                          <p style={{ fontSize: '16px' }}><strong>Model : </strong>{client.modelAppareilD}</p>
                            <p style={{ fontSize: '16px' }}><strong>Code : </strong>{client.codeProduitD}</p>
                            <p style={{ fontSize: '16px' }}><strong>LPP : </strong> {client.lppD}</p>
                            <p style={{ fontSize: '16px' }}><strong>Quantité : </strong>  {client.quantityD}</p>
                            <p style={{ fontSize: '16px' }}><strong>Montant HT : </strong>{client.montantAppareilD}</p>
                          </>
                        )}
                        <button
                          style={styles.button}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFlip('right');
                          }}
                          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                        >
                          <FontAwesomeIcon icon={faTimes} /> Retourner
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>

              {/* <TabPane tabId="5">

                <Container className="mt-5">
                  <Row>
                    <Col>
                      <Card className="shadow" style={{ borderRadius: '12px', color: 'white' }}>
                        <CardHeader className="d-flex justify-content-between align-items-center" style={{ backgroundColor: 'transparent', borderBottom: 'none' }}>
                          <h3 className="mb-0">Historique des RDV du Client</h3>
                        </CardHeader>
                        <CardBody>
                          <ListGroup flush>
                            {historique.map((item, index) => (
                              <ListGroupItem key={index} style={{ backgroundColor: 'transparent', border: 'none', color: 'white', marginBottom: '10px', padding: '15px', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)' }}>
                                <Row className="align-items-center">
                                  <Col md="3">
                                    <h5 className="mb-0">{item.date}</h5>
                                  </Col>
                                  <Col md="6">
                                    <h6 className="mb-0">{item.action}</h6>
                                    <small>{item.details}</small>
                                  </Col>
                                  <Col md="3" className="text-right">
                                    <Badge color={item.statut === 'Terminé' ? 'success' : 'warning'} pill>
                                      {item.statut}
                                    </Badge>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>

              </TabPane> */}
<TabPane tabId="5">
  <Container className="mt-5">
    <Row>
      <Col>
        <Card className="shadow" style={{ borderRadius: '12px', color: 'white' }}>
          <CardHeader className="d-flex justify-content-between align-items-center" style={{ backgroundColor: 'transparent', borderBottom: 'none' }}>
            <h3 className="mb-0">Historique des RDV du Client</h3>
          </CardHeader>
          <CardBody>
            <ListGroup flush>
              {historique.map((item, index) => (
                <ListGroupItem key={index} style={{ backgroundColor: 'transparent', border: 'none', color: 'white', marginBottom: '10px', padding: '15px', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)' }}>
                  <Row className="align-items-center">
                    <Col md="3">
                      {/* <h5 className="mb-0">{item.date}</h5> */}
                      <h5 className="mb-0">{new Date(item.date).toLocaleDateString()}</h5>
                    </Col>
                    <Col md="6">
                      <h6 className="mb-0">{item.action}</h6>
                      <small>{item.details}</small>
                    </Col>
                    <Col md="3" className="text-right">
                      <Badge color={item.statut === 'Terminé' ? 'success' : 'warning'} pill>
                        {item.statut}
                      </Badge>
                    </Col>
                  </Row>
                </ListGroupItem>
              ))}
            </ListGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
</TabPane>


            </TabContent>

          </CardBody>
        </Card>

      </Container>
      {showFactureModal && (
        <Modal isOpen={showFactureModal} toggle={() => setShowFactureModal(!showFactureModal)}>
          <ModalHeader toggle={() => setShowFactureModal(!showFactureModal)}>Détails de Facturation</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Date de Facturation</Label>
              <Input
                type="date"
                name="dateFacturation"
                value={client.dateFacturation}
                onChange={handleModalInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Remise</Label>
              <Input
                type="number"
                name="remise"
                value={client.remise}
                onChange={handleModalInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Remboursement Sécurité Sociale</Label>
              <Input
                type="number"
                name="rbstSS"
                value={client.rbstSS}
                onChange={handleModalInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Remboursement Complémentaire</Label>
              <Input
                type="number"
                name="rbstCompl"
                value={client.rbstCompl}
                onChange={handleModalInputChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSubmitFacture}>Sauvegarder</Button>
            <Button color="secondary" onClick={() => setShowFactureModal(false)}>Annuler</Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}


export default NouveauClient;


// import React, { useState, useRef, useEffect } from "react";
// import { useHistory, useLocation } from "react-router-dom";
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { FaArrowLeft } from 'react-icons/fa';
// import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
// import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';
// import Select from 'react-select';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPencilAlt, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
// //import { FaPencilAlt } from 'react-icons/fa'; // Utilise FontAwesome pour l'icône de crayon
// import moment from "moment-timezone";
// import axios from "axios";

// import {
//   Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, CardBody, CardTitle, CardHeader, Form, FormGroup, Input, Label, Container, Row, Col, UncontrolledAlert,
//   Nav, NavItem, NavLink, TabContent, TabPane, ListGroup,
//   ListGroupItem,
//   Badge
// } from "reactstrap";
// import classnames from 'classnames';
// import EarRight from '../../assets/img/theme/gauche.jpg'; // Remplacez 'path/to' par le chemin approprié
// import EarLeft from '../../assets/img/theme/droite.jpg';  // Remplacez 'path/to' par le chemin approprié
// import LinkIcon from '../../assets/img/theme/link.jpg';
// import { useParams } from "react-router-dom";


// const formatDate = (dateString) => {
//   const utcDate = moment.utc(dateString);
//   return utcDate.format("YYYY-MM-DD"); // Format propre en UTC
// };


// const NouveauClient = () => {
  
// const { clientId } = useParams();
//   const history = useHistory();
//   const location = useLocation();
//   const initialActiveTab = location.state && location.state.activeTab ? location.state.activeTab : '1';
//   const [client, setClient] = useState({
//     nom: "",
//     prenom: "",
//     dateCreation: new Date().toISOString().split("T")[0],
//     centerId: localStorage.getItem("currentCenter") || "", 
//     dateNaissance: "",
//     mutuelle: "",
//     numeroSecu: "",
//     caisse: "",
//     email: "",
//     telephoneFixe: "",
//     telephonePortable: "",
//     adresse: "",
//     codePostal: "",
//     ville: "",
//     note: "",
//     audiogramme: "",
//     statut: "",
//     origine: "",
//     dateAppareillage: "", // New field for appareillage date
//     dateFacturation: "",
//     codeProduit: "",
//     quantity: "",
//     RbstSS: "",
//     RbstCompl: "",
//     Remise: "",
//     dateDevis: "",
//     numeroDevis: "",
//     dureeValiditeDevis: "",
//     marqueAppareilGDevis: "",
//     modeleAppareilGDevis: "",
//     numeroSerieGDevis: "",
//     prixHTGDevis: "",
//     prixTTCGDevis: "",
//     marqueAppareilDDevis: "",
//     modeleAppareilDDevis: "",
//     marqueAppareilG: "",
//     modelAppareilG: "",
//     codeProduitG: "",
//   lppG: "",
//   quantityG: "",
//   montantAppareilG: "",
  
//   marqueAppareilD: "",
//   modelAppareilD: "",
//   codeProduitD: "",
//   lppD: "",
//   quantityD: "",
//   montantAppareilD: "",
//     numeroSerieDDevis: "",
//     prixHTDDevis: "",
//     prixTTCDDevis: "",
//     classeAppareilDevis: "",
//     rbstSSDevis: "",
//     rbstComplDevis: "",
//     garantieDevis: "",
//     servicesInclusDevis: "",
//     prixHTAccessoiresDevis: "",
//     prixTTCAccessoiresDevis: "",
//     acompteDevis: "",
//     dateBilanAuditif: "",
//   heureBilanAuditif: "",
//   datePremierEssai: "",
//   heurePremierEssai: "",
//   dateDeuxiemeEssai: "",
//   heureDeuxiemeEssai: "",
//   dateRdvFacturation: "",
//   heureRdvFacturation: "",
//   dateRdvTeleconsultation: "",
//   heureRdvTeleconsultation: "",
//   dateRdvDevis: "",
//   heureRdvDevis: "",
//   pdfFiles: []

//   });
//   const [isEditable, setIsEditable] = useState(!location.state || !location.state.client);
//   const [activeTab, setActiveTab] = useState(initialActiveTab);
//   const [audiogrammeSuccessMessage, setAudiogrammeSuccessMessage] = useState("");
//   const [facture, setFacture] = useState({ articles: [] }); // Ensure articles is always an array
//   const [showFactureModal, setShowFactureModal] = useState(false);
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
//   const [token, setToken] = useState('');
//   const [orlOptions, setOrlOptions] = useState([]);
//   const [selectedOrl, setSelectedOrl] = useState(null);
//   const [marques, setMarques] = useState([]);
//   const [models, setModels] = useState([]);
//   const [filteredModels, setFilteredModels] = useState([]);
//   const [pdfFiles, setPdfFiles] = useState([]);
//   const [historique, setHistorique] = useState([]);
//   const [filteredModelsLeft, setFilteredModelsLeft] = useState([]);
//   const [filteredModelsRight, setFilteredModelsRight] = useState([]);
//   const [filteredMarquesLeft, setFilteredMarquesLeft] = useState([]);
//   const [filteredMarquesRight, setFilteredMarquesRight] = useState([]);
  


//   useEffect(() => {
//     // Récupérer les produits depuis l'API
//     fetch('/api/produits')
//       .then(response => response.json())
//       .then(data => {
//         console.log("Produits récupérés:", data);
//         // Extraire les marques uniques
//         const uniqueMarques = [...new Set(data.map(item => item.marque))];
//         setMarques(uniqueMarques);

//         // Stocker tous les produits pour filtrer les modèles plus tard
//         setModels(data);
//       })
//       .catch(error => console.error('Erreur lors de la récupération des produits:', error));
//   }, []);


  
//   const getDateFieldName = (statut) => {
//     switch (statut) {
//       case 'Rdv 1er essai': return 'datePremierEssai';
//       case 'Rdv 2eme essai': return 'dateDeuxiemeEssai';
//       case 'Rdv Bilan auditif': return 'dateBilanAuditif';
//       case 'Rdv Téléconsultation': return 'dateRdvTeleconsultation';
//       case 'Rdv facturation': return 'dateRdvFacturation';
//       case 'Rdv devis': return 'dateRdvDevis';
//       case 'Facturé': return 'dateFacturation';
//       default: return '';
//     }
//   };
  
//   const getHeureFieldName = (statut) => {
//     switch (statut) {
//       case 'Rdv 1er essai': return 'heurePremierEssai';
//       case 'Rdv 2eme essai': return 'heureDeuxiemeEssai';
//       case 'Rdv Bilan auditif': return 'heureBilanAuditif';
//       case 'Rdv Téléconsultation': return 'heureRdvTeleconsultation';
//       case 'Rdv facturation': return 'heureRdvFacturation';
//       case 'Rdv devis': return 'heureRdvDevis';
//       case 'Facturé': return 'heureFacturation';
//       default: return '';
//     }
//   };
//   useEffect(() => {
//     const storedCenterId = localStorage.getItem("currentCenter") || "";
//     axios.get(`/api/clients/${client._id}`)
//       .then((response) => {
//         if (response.data.centerId !== storedCenterId) {
//           console.warn("⚠️ Le client ne fait pas partie du centre actif !");
//           return;
//         }
//         setClient(prevClient => ({
//           ...prevClient,
//           ...response.data,
//           pdfFiles: response.data.pdfFiles || [] // On s'assure que `pdfFiles` est toujours un tableau
//         }));
//       })
//       .catch(error => {
//         console.error("Erreur lors de la récupération du client :", error);
//       });
//   }, [clientId]);
  
//   const handleMarqueChange = (event) => {
//     const { name, value } = event.target;
//     const isLeft = name === "marqueAppareilG";
  
//     // Filtrer les modèles correspondant à la marque sélectionnée
//     const updatedFilteredModels = models
//       .filter(item => item.marque === value)
//       .map(item => item.model);
  
//     // Mettre à jour l'état
//     setClient(prevClient => ({
//       ...prevClient,
//       [name]: value, // Met à jour la marque
//       [isLeft ? "modelAppareilG" : "modelAppareilD"]: updatedFilteredModels.length > 0 ? updatedFilteredModels[0] : "",  // Réinitialise le modèle
//       [isLeft ? "codeProduitG" : "codeProduitD"]: "", // Réinitialise le code produit
//       [isLeft ? "lppG" : "lppD"]: "" // Réinitialise le LPP
//     }));
  
//     if (isLeft) {
//       setFilteredModelsLeft(updatedFilteredModels);
//     } else {
//       setFilteredModelsRight(updatedFilteredModels);
//     }
//   };
  
//   const handleModelChange = (event) => {
//     const { name, value } = event.target;
//     const isLeft = name === "modelAppareilG";

//     const validModels = isLeft ? filteredModelsLeft : filteredModelsRight;
//   if (!validModels.includes(value)) {
//     return; // Arrêter la mise à jour si le modèle sélectionné n'existe pas
//   }
  
//     // Trouver l'objet produit correspondant au modèle sélectionné
//     const selectedProduct = models.find(item => item.model === value);
  
//     setClient(prevClient => ({
//       ...prevClient,
//       [name]: value, // Met à jour le modèle
//       [isLeft ? "codeProduitG" : "codeProduitD"]: selectedProduct ? selectedProduct.code : "",
//       [isLeft ? "lppG" : "lppD"]: selectedProduct ? selectedProduct.lpp : ""
//     }));
//   };
  
  
//   useEffect(() => {

//     //Si un client est passé dans l'état, utilisez ses valeurs pour initialiser le formulaire
//       if (location.state && location.state.client && location.state.client._id) {
//         console.log('Client trouvé:', location.state.client); 
//         const formattedClient = {
//           ...location.state.client,
//           dateNaissance: formatDate(location.state.client.dateNaissance),
//           dateAppareillage: formatDate(location.state.client.dateAppareillage),
//           dateFacturation: formatDate(location.state.client.dateFacturation),
//           prescripteurId: location.state.client.prescripteurId,
//           marqueAppareilG: location.state.client.marqueAppareilG,
//           marqueAppareilD: location.state.client.marqueAppareilD,
//           modelAppareilG: location.state.client.modelAppareilG,
//           modelAppareilD: location.state.client.modelAppareilD,
//           dateDevis: location.state.client.dateDevis ? new Date(location.state.client.dateDevis).toISOString().split('T')[0] : '',  // Assurez-vous de formater la date
//       numeroDevis: location.state.client.numeroDevis || '',  // Ajout du numéro du devis
//       dureeValiditeDevis: location.state.client.dureeValiditeDevis || '',  // Ajout de la durée de validité du devis
//       marqueAppareilGDevis: location.state.client.marqueAppareilGDevis || '',  // Appareil gauche - Type
//       modeleAppareilGDevis: location.state.client.modeleAppareilGDevis || '',  // Appareil gauche - Modèle
//       numeroSerieGDevis: location.state.client.numeroSerieGDevis || '',  // Appareil gauche - Numéro de série
//       prixHTGDevis: location.state.client.prixHTGDevis || '',  // Appareil gauche - Prix HT
//       prixTTCGDevis: location.state.client.prixTTCGDevis || '',  // Appareil gauche - Prix TTC
//       marqueAppareilDDevis: location.state.client.marqueAppareilDDevis || '',  // Appareil droit - Type
//       modeleAppareilDDevis: location.state.client.modeleAppareilDDevis || '',  // Appareil droit - Modèle
//       numeroSerieDDevis: location.state.client.numeroSerieDDevis || '',  // Appareil droit - Numéro de série
//       prixHTDDevis: location.state.client.prixHTDDevis || '',  // Appareil droit - Prix HT
//       prixTTCDDevis: location.state.client.prixTTCDDevis || '',  // Appareil droit - Prix TTC
//       classeAppareilDevis: location.state.client.classeAppareilDevis || '',  // Classe de l'appareil
//       rbstSSDevis: location.state.client.rbstSSDevis || '',  // Remboursement Sécurité Sociale
//       rbstComplDevis: location.state.client.rbstComplDevis || '',  // Remboursement Mutuelle
//       garantieDevis: location.state.client.garantieDevis || '',  // Garantie
//       servicesInclusDevis: location.state.client.servicesInclusDevis || '',  // Services inclus
//       prixHTAccessoiresDevis: location.state.client.prixHTAccessoiresDevis || '',  // Accessoires - Prix HT
//       prixTTCAccessoiresDevis: location.state.client.prixTTCAccessoiresDevis || '',  // Accessoires - Prix TTC
//       acompteDevis: location.state.client.acompteDevis|| '',  // Acompte requis
//       centerId: localStorage.getItem("currentCenter") || "", 
//       pdfFiles: location.state.client.pdfFiles || []
//         };
//         setClient(formattedClient);
//         setPdfFiles(formattedClient.pdfFiles); 

//          // Ajoutez cette ligne ici pour voir les données du client dans la console
//     console.log("Données client après formatage:", formattedClient);

//         if (location.state.client.prescripteurId && orlOptions.length) {
//           const selected = orlOptions.find(option => option.value === location.state.client.prescripteurId);
//           setSelectedOrl(selected || null);
//         }
//       }
//     }, [location.state, orlOptions]);

  
//   const toggleTab = (tabId) => {
//     if (activeTab !== tabId) {
//       setActiveTab(tabId);
//     }
//   };
  

//   const [errors, setErrors] = useState({});
//   const [successMessage, setSuccessMessage] = useState("");

//   const isMounted = useRef(false);

//   useEffect(() => {
//     const handleResize = () => setIsMobile(window.innerWidth < 768);
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   useEffect(() => {
//     isMounted.current = true;
//     return () => {
//       isMounted.current = false;  // Nettoyage en démontant le composant
//     };
//   }, []);

//   const validateForm = () => {
//     let tempErrors = {};
//     let formIsValid = true;

//     if (!client.nom.trim()) {
//       formIsValid = false;
//       tempErrors["nom"] = "Le nom est requis.";
//     }

//     if (!client.email.trim()) {
//       formIsValid = false;
//       tempErrors["email"] = "L'email est requis.";
//       toast.error("L'email est requis.");
//     }

//     // Vérification pour le champ telephonePortable
//   if (!client.telephonePortable.trim()) {
//     formIsValid = false;
//     tempErrors["telephonePortable"] = "Le numéro de téléphone portable est requis.";
//     // Ajout d'une notification toast
//     toast.error("Le numéro de téléphone portable est requis.");
//   }

//     setErrors(tempErrors);
//     return formIsValid;
//   };


//   const saveClient = async (clientData) => {
//     const url = clientData._id ? `https://app.vtalys.fr/api/clients/${clientData._id}` : 'https://app.vtalys.fr/api/clients/add';
//     const method = clientData._id ? 'PUT' : 'POST';

//     console.log('Envoi des données du client:', clientData);  // Log des données envoyées
//     console.log('URL:', url);  // Log de l'URL utilisée
//     console.log('Méthode HTTP:', method);  // Log de la méthode utilisée

    
//     try {
//       const token = localStorage.getItem('token');
//       console.log('Token JWT:', token);  // Log du token utilisé
  
//       const response = await fetch(url, {
//         method: method,
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}` // Ensure the token is correctly formatted
//         },
//         body: JSON.stringify(clientData)
//       });

//       console.log('Statut de la réponse:', response.status);  // Log du statut de réponse HTTP

//       if (!response.ok) {
//         const data = await response.json();
//         console.log('Erreur lors de l\'enregistrement:', data);  // Log de l'erreur
//         toast.error(`Erreur: ${data.message}`);
//         return;
//       }

//       const data = await response.json();
//       console.log('Réponse du serveur:', data);  // Log de la réponse
//       toast.success("Le client a été ajouté/modifié avec succès !");
//       setIsEditable(false);

//       if (clientData.statut === "Facturé" ) {
//         // Génération de la facture lorsque le client est facturé
//         generateInvoice(data._id || clientData._id, {
//           totalGeneral: clientData.prix || 0, // Assurez-vous que ce champ est bien rempli
//           articles: [{ description: 'Appareil auditif', quantite: 1, prix: clientData.prix || 1200 }] // Exemple
//         });
//       }

//     } catch (error) {
//       console.error('Erreur lors de l\'opération sur le client:', error);
//       toast.error(`Erreur lors de l'opération sur le client: ${error.message}`);
//     }
//   };

//   const saveAppareillage = () => {
//     if (validateForm()) {
//       saveClient(client); // Appelle la fonction qui sauvegarde les données
//     }
//   };
  
//   const handleSelectChange = selectedOption => {
//     setSelectedOrl(selectedOption);  // Pour gérer l'état local de l'option sélectionnée
//     // Mettre à jour l'état global du client pour le prescripteur
//     setClient(prevState => ({
//       ...prevState,
//       prescripteurId: selectedOption ? selectedOption.value : ''
//     }));
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
    
//     if (name === 'modelAppareilG' || name === 'modelAppareilD') {
//       updateProductQuantity(value);
//     }
//     if (name.includes("date") && value.trim() === "") {
//       console.warn(`Tentative d'enregistrer une date vide dans ${name}`);
//       return;  // Ignore la mise à jour de la date si elle est vide
//   }
//     const prevStatut = client.statut;
    
//   if (name === 'statut' && client.statut) {
//     const prevStatut = client.statut;
//     const prevDateKey = getDateFieldName(prevStatut);
//     const prevDate = client[prevDateKey] || 'Non renseignée';
//     const prevHeureKey = getHeureFieldName(prevStatut);
//     const prevHeure = client[prevHeureKey] || 'Non renseignée';

//     console.log('Changement de statut détecté:', prevStatut, '->', value);
//     fetch(`/api/clients/${client._id}/historique`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         nouveauStatut: value,
//         date: new Date().toISOString(), // Date actuelle pour le nouveau statut
//         heure: new Date().toLocaleTimeString(), // Heure actuelle pour le nouveau statut
//       }),
//     })
//     .then((response) => response.json())
//     .then((data) => {
//       console.log('Historique mis à jour:', data);
//     })
//     .catch((error) => {
//       console.error('Erreur lors de la mise à jour de l\'historique:', error);
//     });
//   }

  
//     setClient({ ...client, [name]: value });
  
//     if (!!errors[name]) setErrors({ ...errors, [name]: null });
//   };
  
//   useEffect(() => {
//     if (client && client._id) {
//       fetch(`/api/clients/${client._id}/historique`)
//         .then((response) => {
//           if (!response.ok) {
//             throw new Error(`Erreur HTTP : ${response.status}`);
//           }
//           return response.json();  // Si la réponse est correcte, convertis en JSON
//         })
//         .then((data) => {
//           setHistorique(data);  // Met à jour l'état avec les données récupérées
//         })
//         .catch((error) => {
//           console.error('Erreur lors de la récupération de l\'historique:', error);
//         });
//     } else {
//       console.error('client._id est indéfini');
//     }
//   }, [client._id]);
  
  
//   // Fonction pour mettre à jour la quantité du produit
// const updateProductQuantity = (model) => {
//   fetch(`/api/produits/update-quantity`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({ model })
//   })
//   .then(response => {
//     if (!response.ok) {
//       throw new Error('Erreur lors de la mise à jour de la quantité du produit');
//     }
//     return response.json();
//   })
//   .then(data => {
//     console.log('Quantité mise à jour:', data);
//     // Afficher une notification toast si la quantité est à zéro
//     if (data.produit.quantity === 0) {
//       toast.error(`Le produit ${data.produit.model} n'est plus en stock !`);
//     }
//   })
//   .catch(error => {
//     console.error('Erreur:', error);
//   });
// };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (validateForm()) {
//       saveClient(client);
//     }
//   };
//   const handleSubmitAudiogramme = async (e) => {
//     e.preventDefault();
//     const audiogrammeData = {
//       ...client,
//       audiogramme: client.audiogramme
//     };

//     const url = client._id ? `https://app.vtalys.fr/api/clients/${client._id}` : 'https://app.vtalys.fr/api/clients/add';
//     const method = client._id ? 'PUT' : 'POST';

//     try {
//       const response = await fetch(url, {
//         method: method,
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': localStorage.getItem('token')
//         },
//         body: JSON.stringify(audiogrammeData)
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         toast.error(`Erreur lors de l'enregistrement: ${errorText}`);
//         throw new Error(`HTTP status ${response.status}: ${errorText}`);
        
//       }

//       const data = await response.json();
//       console.log('Opération réussie:', data);
//       toast.success("Le bilan auditif a été enregistré avec succès.");
//       setAudiogrammeSuccessMessage("Le bilan auditif a été enregistré avec succès.");
//       setTimeout(() => {
//         setAudiogrammeSuccessMessage("");
//         history.push('/admin/clients'); // Redirection après l'affichage du message de succès
//       }, 3000); // Affichage du message pendant 3 secondes

//     } catch (error) {
//       console.error("Erreur lors de l'enregistrement du bilan auditif:", error);
//       //setErrors({ form: "Erreur lors de l'opération sur le bilan auditif." });
//       toast.error(`Erreur lors de l'enregistrement du bilan auditif`);
//     }
//   };
//   const handleBack = () => {
//     history.goBack();
//   };

//   const updateClientStatus = async (clientId, statut) => {
//     try {
//       const token = localStorage.getItem('token');  // Assurez-vous de récupérer le token ici
//       if (!token) {
//         throw new Error('Token JWT manquant.');
//       }
    
//       const response = await fetch(`https://app.vtalys.fr/api/clients/${client._id}/status`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`  // Ajouter le token dans l'en-tête Authorization
//         },
//         body: JSON.stringify({ statut })
//       });
    
//       if (!response.ok) {
//         const updateErrorData = await response.json();
//         console.error('Erreur lors de la mise à jour du statut:', updateErrorData);
//         toast.error(`Erreur lors de la mise à jour du statut du client: ${updateErrorData.error || 'Erreur inconnue'}`);
//         return;
//       }
    
//       // Afficher un message de succès pour la mise à jour du statut
//       toast.success("Statut du client mis à jour avec succès !");
      
//     } catch (error) {
//       console.error("Erreur lors de la mise à jour du statut:", error);
//       toast.error(`Erreur lors de la mise à jour du statut: ${error.message}`);
//     }
//   };
  

//   const handleSubmitAppareillage = async (e) => {
//     e.preventDefault();

//     // Collecter les données d'appareillage
//     const appareillageData = {
//       ...client, // conserve les données existantes du client
//       marqueAppareilG: client.marqueAppareilG,
//       modelAppareilG: client.modelAppareilG,
//       codeProduitG: client.codeProduitG,
//       lppG: client.lppG,
//       quantityG: client.quantityG,
//       montantAppareilG: client.montantAppareilG,
//       marqueAppareilD: client.marqueAppareilD,
//       modelAppareilD: client.modelAppareilD,
//       codeProduitD: client.codeProduitD,
//       lppD: client.lppD,
//       quantityD: client.quantityD,
//       montantAppareilD: client.montantAppareilD
//     };

//     // URL et méthode pour l'API
//     const url = client._id ? `https://app.vtalys.fr/api/clients/${client._id}` : 'https://app.vtalys.fr/api/clients/add';
//     const method = client._id ? 'PUT' : 'POST';

//     // Appel API pour enregistrer les données
//     try {
//       const response = await fetch(url, {
//         method: method,
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': localStorage.getItem('token')
//         },
//         body: JSON.stringify(appareillageData)
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(`HTTP status ${response.status}: ${errorText}`);
//       }

//       const data = await response.json();
//       console.log('Opération réussie:', data);
//       setClient(data);
//       setAudiogrammeSuccessMessage("L'appareillage a été enregistré avec succès.");
//       setTimeout(() => {
//         setAudiogrammeSuccessMessage(""); // Effacer le message après 3 secondes
//         history.push('/admin/clients'); // Redirection après l'affichage du message de succès
//       }, 3000);

//     } catch (error) {
//       console.error("Erreur lors de l'enregistrement de l'appareillage:", error);
//       setAudiogrammeSuccessMessage(`Erreur lors de l'opération: ${error.message}`);
//     }
//   };

  

//   const handleCheckboxChange = (e) => {
//     const { name, checked } = e.target;
//     setClient({
//       ...client,
//       appareillage: {
//         ...client.appareillage,
//         [name]: checked
//       }
//     });
//   };

//   const downloadPDF = async () => {
//     try {
//       const response = await fetch('https://app.vtalys.fr/api/clients/generate-pdf', {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/pdf',
//         },
//       });
//       if (response.ok) {
//         // Crée une URL Blob et ouvre un nouvel onglet pour télécharger le PDF
//         const blob = await response.blob();
//         const downloadUrl = window.URL.createObjectURL(blob);
//         const link = document.createElement('a');
//         link.href = downloadUrl;
//         link.setAttribute('download', 'devis.pdf'); // Nom du fichier à télécharger
//         document.body.appendChild(link);
//         link.click();
//         link.parentNode.removeChild(link);
//         toast.success("Le PDF a été téléchargé avec succès !");
//       } else {
//         throw new Error('Problème lors du téléchargement du PDF');
//       }
//     } catch (error) {
//       console.error('Erreur lors du téléchargement du PDF:', error);
//       toast.error("Erreur lors du téléchargement du PDF");
//     }
//   };

//   const generateInvoice = async (clientId, factureData) => {
    

//     try {
//       const token = localStorage.getItem('token');  // Assurez-vous de récupérer le token ici
//       if (!token) {
//         throw new Error('Token JWT manquant.');
//       }
//    // Vérifier si la date est valide
//    let validDate = factureData.dateFacture ? new Date(factureData.dateFacture) : new Date();
//    if (isNaN(validDate.getTime())) {
//        console.error("❌ dateFacture est invalide:", factureData.dateFacture);
//        toast.error("Erreur: date de facturation invalide.");
//        return;
//    }

//    // S'assurer que la date est au bon format (YYYY-MM-DD)
//    factureData.dateFacture = validDate.toISOString().split('T')[0];

//       const response = await fetch(`https://app.vtalys.fr/api/factures/generate/${clientId}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`  // Ajouter le token dans l'en-tête Authorization
//         },
//         body: JSON.stringify(factureData)
//       });

//       if (!response.ok) {
//         const data = await response.json();
//         // Log pour vérifier le contenu de data
//       console.log('Données de l\'erreur:', data);

//       // Vérifier si l'erreur est une duplication
//       if (data.error && data.error.includes('E11000 duplicate key error')) {
//         // Extraire l'email du message d'erreur si possible
//         const emailMatch = data.error.match(/email: \"(.+?)\"/);
//         const email = emailMatch ? emailMatch[1] : 'cet email';
//         toast.error(`Erreur: Une facture existe déjà pour ce client avec l'email ${email}`);
//       } else {
//         toast.error(`Erreur lors de la génération de la facture: ${data.error || 'Erreur inconnue'}`);
//       }
//       return;
//     }

//       toast.success("Facture générée avec succès !");
//       await updateClientStatus(clientId, 'Facture éditée');


// } catch (error) {
// toast.error(`Erreur lors de la génération de la facture: Une facture existe déjà pour ce client`);
// }
// };

//   const generateDevis = async (clientId, devisData) => {
//     if (!clientId) {
//       toast.error("Client ID manquant. Impossible de générer le devis.");
//       return;
//     }
//     try {
//       const token = localStorage.getItem('token');
//       if (!token) {
//         throw new Error('Token JWT manquant.');
//       }
  
//       const response = await fetch(`https://app.vtalys.fr/api/clients/generate-devis/${clientId}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`  // Ajouter le token dans l'en-tête Authorization
//         },
//         body: JSON.stringify({
//           ...devisData,
//           dateDevis: devisData.dateDevis || new Date().toISOString().split('T')[0]  // Ajoutez la date actuelle si nécessaire
//         })
//       });
  
//       if (!response.ok) {
//         const data = await response.json();
//         toast.error(`Erreur lors de la génération du devis: ${data.message}`);
//         return;
//       }
  
//       toast.success("Devis généré avec succès !");
//     } catch (error) {
//       toast.error(`Erreur lors de la génération du devis: ${error.message}`);
//     }
//   };

//   const generateDevisPDF = (client, user) => {
//     const doc = new jsPDF();
//     doc.setFont('Helvetica');
//     doc.setFontSize(11);

//     // Obtenir le dernier numéro de devis depuis le localStorage ou démarrer à 1
//     const dernierNumeroDevis = localStorage.getItem('dernierNumeroDevis');
//     const nouveauNumeroDevis = dernierNumeroDevis ? parseInt(dernierNumeroDevis, 10) + 1 : 1;

//     // Stocker le nouveau numéro de devis
//     localStorage.setItem('dernierNumeroDevis', nouveauNumeroDevis.toString().padStart(5, '0'));

//     // Format du numéro de devis pour qu'il soit toujours sur 5 chiffres (ex : 00001, 00002, etc.)
//     const numeroDevisFormate = nouveauNumeroDevis.toString().padStart(5, '0');

//     // En-tête du devis avec les informations de l'audioprothésiste
//     doc.text(`Nom de l'audioprothésiste: ${user.name || ''}`, 20, 10);
//     doc.text(`Téléphone: ${user.telephone || ''}`, 20, 15);
//     doc.text(`Email: ${user.email || ''}`, 20, 20);

//     // Titre du devis centré
//     doc.setFontSize(18);
//     doc.text(`Devis - ${client.nom} ${client.prenom}`, 105, 30, null, null, 'center');

//     // Date, numéro et durée de validité du devis alignés à droite
//     doc.setFontSize(11);
//     const dateDevis = client.dateDevis ? new Date(client.dateDevis).toLocaleDateString("fr-FR") : new Date().toLocaleDateString("fr-FR");
//     doc.text(`Date: ${dateDevis}`, 200, 35, null, null, 'right');
//     //doc.text(`Numéro de devis: ${client.numeroDevis || ''}`, 200, 40, null, null, 'right');
//     doc.text(`Numéro de devis: ${numeroDevisFormate}`, 200, 40, null, null, 'right');
//     doc.text(`Validité: ${client.dureeValiditeDevis || ''} jours`, 200, 45, null, null, 'right');

//     // Informations du client
//     doc.text(`Nom du patient: ${client.nom} ${client.prenom}`, 20, 55);
//     doc.text(`Téléphone: ${client.telephonePortable || ''}`, 20, 60);
//     const dateNaissanceFormatted = client.dateNaissance ? new Date(client.dateNaissance).toLocaleDateString("fr-FR") : '';
//     doc.text(`Date de Naissance: ${dateNaissanceFormatted}`, 20, 65);
//     doc.text(`Adresse: ${client.adresse || ''}, ${client.codePostal || ''}, ${client.ville || ''}`, 20, 70);

//     // Section Appareil Gauche et Droit avec classe de l'appareil
//     const totalTTCApplG = Math.round(client.prixHTGDevis * 1.055 * 100) / 100;
//     const totalTTCApplD = Math.round(client.prixHTDDevis * 1.055 * 100) / 100;
//     const totalTTC = (totalTTCApplG + totalTTCApplD).toFixed(2);

//     // Tableau des appareils avec informations supplémentaires (classe de l'appareil, garantie, etc.)
//     autoTable(doc, {
//         head: [['Désignation', 'Classe', 'Marque', 'Modèle', 'N° Série', 'Qté', 'Prix HT (€)', 'TVA', 'Prix TTC (€)', 'Garantie']],
//         body: [
//             [
//                 'Appareil Gauche',
//                 `${client.classeAppareilDevis || ''}`,
//                 `${client.marqueAppareilGDevis || ''}`,
//                 `${client.modeleAppareilGDevis || ''}`,
//                 `${client.numeroSerieGDevis || ''}`,
//                 `${client.quantityG || 1}`,
//                 `${client.prixHTGDevis || 0} €`,
//                 '5.5%',
//                 `${totalTTCApplG.toFixed(2)} €`,
//                 `${client.garantieDevis || ''}`
//             ],
//             [
//                 'Appareil Droit',
//                 `${client.classeAppareilDevis || ''}`,
//                 `${client.marqueAppareilDDevis || ''}`,
//                 `${client.modeleAppareilDDevis || ''}`,
//                 `${client.numeroSerieDDevis || ''}`,
//                 `${client.quantityD || 1}`,
//                 `${client.prixHTDDevis || 0} €`,
//                 '5.5%',
//                 `${totalTTCApplD.toFixed(2)} €`,
//                 `${client.garantieDevis || ''}`
//             ]
//         ],
//         startY: 85,
//         theme: 'grid',
//         styles: { fontSize: 10, font: 'Helvetica' },
//         headStyles: { fillColor: [0, 51, 51] },
//         columnStyles: {
//           0: { cellWidth: 30 },  // Désignation
//           1: { cellWidth: 10 },  // Classe (Réduite)
//           2: { cellWidth: 25 },  // Marque
//           3: { cellWidth: 45 },  // Modèle (Élargie)
//           4: { cellWidth: 25 },  // N° Série (Élargie)
//           5: { cellWidth: 10 },  // Qté (Réduite)
//           6: { cellWidth: 20 },  // Prix HT (€) (Réduite)
//           7: { cellWidth: 15 },  // TVA
//           8: { cellWidth: 20 },  // Prix TTC (€) (Réduite)
//           9: { cellWidth: 15 }   // Garantie (Réduite)
//       },
//         margin: { top: 10, bottom: 10 },
//     });

//     // Section Accessoires
//     const totalAccessoiresTTC = client.prixTTCAccessoiresDevis || 0;
//     autoTable(doc, {
//         head: [['Désignation', 'Prix HT (€)', 'Prix TTC (€)']],
//         body: [
//             [
//                 'Accessoires',
//                 `${client.prixHTAccessoiresDevis || 0} €`,
//                 `${totalAccessoiresTTC} €`
//             ]
//         ],
//         startY: doc.lastAutoTable.finalY + 10,
//         theme: 'grid',
//         styles: { fontSize: 10, font: 'Helvetica' },
//         headStyles: { fillColor: [0, 51, 51] },
//         margin: { top: 10, bottom: 10 },
//     });

//     // Résumé financier
//     autoTable(doc, {
//         startY: doc.lastAutoTable.finalY + 10,
//         theme: 'plain',
//         body: [
//             [`Total TTC des Appareils : ${totalTTC} €`],
//             [`Prix TTC des Accessoires : ${totalAccessoiresTTC} €`],
//             [`Montant pris en charge par la Sécurité Sociale : ${client.rbstSSDevis || 0} €`],
//             [`Montant pris en charge par la Mutuelle : ${client.rbstComplDevis || 0} €`],
//             [`Acompte à verser : ${client.acompteDevis || 0} €`],
//             [`Montant à la charge du patient : ${(totalTTC + totalAccessoiresTTC - (client.rbstSSDevis + client.rbstComplDevis)).toFixed(2)} €`]
//         ],
//         styles: {
//             fontSize: 10,
//             font: 'Helvetica',
//             cellPadding: 2,
//             lineColor: 200,
//             lineWidth: 0.5
//         },
//         margin: { top: 10, right: 20, bottom: 10, left: 20 }
//     });

//     // Sauvegarder le PDF
//     doc.save(`Devis-${client.nom}-${client.prenom}.pdf`);
// };


// // Récupérer les informations de l'utilisateur à partir de localStorage (ou d'une autre source)
// const user = JSON.parse(localStorage.getItem('user')) || {};
  
// useEffect(() => {
//   if (
//     client.statut === "Facturé" &&
//     (!client.dateFacturation || !client.rbstSS || !client.rbstCompl)
//   ) {
//     setShowFactureModal(true);
//   }
// }, [client.statut, client.dateFacturation, client.rbstSS, client.rbstCompl]);

  

//   const handleStatutChange = (event) => {
//     const newStatut = event.target.value;
//     setClient({ ...client, statut: newStatut });
  
//     // Si le nouveau statut est "Facturé" et qu'aucune date de facturation n'existe, afficher le modal
//     if (
//       newStatut === "Facturé" && 
//       (!client.dateFacturation || !client.rbstSS || !client.rbstCompl)
//     )  {
//       setShowFactureModal(true);
//     } else {
//       setShowFactureModal(false);
//     }
//   };
  

//   const handleModalInputChange = (event) => {
//     setClient({ ...client, [event.target.name]: event.target.value });
//   };

  

//   const handleSubmitFacture = async () => {
//     // Effectuer les opérations nécessaires pour sauvegarder les données
//     if (validateFactureData()) {
//       try {
//         // Sauvegarde des données de facturation
//         await saveClient(client); // Assume que saveClient va mettre à jour la base de données avec les nouvelles valeurs
  
//         // Vérifier si les données sont bien enregistrées
//         setShowFactureModal(false); // Fermer le modal seulement après la sauvegarde réussie
//         toast.success("Les informations de facturation ont été sauvegardées avec succès !");
//       } catch (error) {
//         console.error("Erreur lors de la sauvegarde des informations de facturation :", error);
//         toast.error("Erreur lors de la sauvegarde. Veuillez réessayer.");
//       }
//     } else {
//       toast.error("Veuillez remplir tous les champs obligatoires.");
//     }
//   };

//   const validateFactureData = () => {
//     return client.dateFacturation && client.rbstSS && client.rbstCompl;
//   };
  
  
//   const steps = [
//     { id: '1', name: 'Informations Générales', path: '#informations-generales', icon: 'ni ni-badge' },
//     { id: '2', name: 'Rdv Bilan auditif Auditif', path: '#bilan-auditif', icon: 'ni ni-headphones' },
//     { id: '3', name: 'Devis', path: '#devis', icon: 'ni ni-single-copy-04' },
//     { id: '4', name: 'Appareillage', path: '#appareillage', icon: 'ni ni-vector' },
//     { id: '5', name: 'Historique', path: '#historique', icon: 'ni ni-folder-17' }
//   ];


//   const navLinkStyle = {
//     padding: '8px 16px',
//     borderRadius: '8px', // Border-radius toujours appliqué
//     backgroundColor: '#f8f9fa',
//     border: '1px solid #ddd',
//     color: '#007BFF',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     transition: 'all 0.3s ease',
//     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//     fontWeight: '500',
//     textDecoration: 'none',
//     cursor: 'pointer',
//     fontSize: '15px',
//     outline: 'none',
//   };

//   // Styles pour les NavLink actifs
//   const navLinkActiveStyle = {
//     ...navLinkStyle, // Inherit all common styles including border-radius
//     backgroundColor: '#007D70',
//     color: 'white',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
//     transform: 'scale(1.05)',
//   };


//   const EarCardStyle = {
//     borderRadius: '12px',
//     boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
//     marginTop: '30px',
//     marginBottom: '30px',
//     padding: '20px',
//     background: 'linear-gradient(87deg, #f0f8ff, #e6f7ff)',
//     color: 'white',
//     transition: 'transform 0.3s ease, box-shadow 0.3s ease',
//     maxWidth: '400px', // Réduit la largeur maximale
//     width: '100%',
//   };

//   const labelStyle = {
//     color: 'black',
//     fontSize: '13px',
//     marginBottom: '5px',
//   };

//   const formGroupStyle = {
//     marginBottom: '10px',
//   };

//   const containerStyle = {
//     display: 'flex',
//     justifyContent: 'center',  // Centrer horizontalement
//     alignItems: 'center',  // Centrer verticalement
//     gap: '20px',  // Espacement entre les cartes
//     marginBottom: '20px',
//     flexWrap: 'wrap', // Permettre un bon alignement sur les petits écrans
//   };
//   useEffect(() => {
//     console.log("Options prescripteurs:", orlOptions);
//   }, [orlOptions]);

//   useEffect(() => {
//     console.log("Selected prescripteur:", selectedOrl);
//   }, [selectedOrl]);


//   const [isFlipped, setIsFlipped] = useState({
//     right: false,
//     left: false
//   });

//   const [hovered, setHovered] = useState(false); // État pour gérer le survol


//   const handleEditToggle = (e) => {
//     e.stopPropagation();
//     setIsEditable((prev) => !prev);
//   };


//   const [isEditing, setIsEditing] = useState({ left: false, right: false });

//   const toggleFlip = (side) => {
//     setIsFlipped(prev => ({
//       ...prev,
//       [side]: !prev[side]
//     }));
//   };

//   const toggleEdit = (side) => {
//     setIsEditing((prev) => ({ ...prev, [side]: !prev[side] }));
//   };

//   // Styles
//   const styles = {
//     container: {
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'flex-start',
//       height: '80vh',
//       paddingTop: '20px',
//       gap: '80px', // Augmente l'espacement entre les cartes et l'image du lien
//       position: 'relative',
//     },
//     card: {
//       width: '300px',
//       height: '500px',
//       perspective: '1000px',
//       border: '2px solid #007D70',
//       borderRadius: '10px',
//       transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
//       position: 'relative',
//       boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//       backgroundColor: '#fff',
//       overflow: 'hidden',
//       cursor: 'pointer',
//     },
//     cardInner: {
//       position: 'relative',
//       width: '100%',
//       height: '100%',
//       textAlign: 'center',
//       transition: 'transform 0.6s',
//       transformStyle: 'preserve-3d',
//     },
//     cardFace: {
//       position: 'absolute',
//       width: '100%',
//       height: '100%',
//       backfaceVisibility: 'hidden',
//       borderRadius: '10px',
//       display: 'flex',
//       flexDirection: 'column',
//       justifyContent: 'space-between', // Espace les éléments de manière uniforme
//       alignItems: 'center',
//       padding: '40px 20px', // Augmente le padding pour plus d'espace intérieur
//       boxSizing: 'border-box',
//     },
//     cardBack: {
//       position: 'relative', // Assurez-vous que le parent a 'position: relative'
//       padding: '20px', // Ajuster le padding si nécessaire
//       transform: 'rotateY(180deg)',
//     },
//     image: {
//       width: '150px',
//       height: '150px',
//       marginBottom: '20px' // Espace entre l'image et le texte
//     },
//     button: {
//       padding: '15px',
//       border: 'none',
//       borderRadius: '5px',
//       background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
//       color: 'white',
//       fontSize: '16px',
//       cursor: 'pointer',
//       marginTop: '20px', // Espace entre le texte et le bouton
//       transition: 'transform 0.3s ease',
//       width: '80%',
//       maxWidth: '180px',
//     },
//     saveButton: {
//       marginTop: '10px',
//       padding: '10px 20px',
//       borderRadius: '8px',
//       background: '#28a745',
//       border: 'none',
//       color: 'white',
//       cursor: 'pointer',
//       transition: 'all 0.3s ease',
//     },
//     pencilButton: {
//       position: 'absolute',
//       top: '10px', // Ajustez cette valeur pour l'espacement
//       right: '10px', // Positionne le bouton à droite
//       width: '30px', // Limite la largeur du bouton
//       height: '30px', // Limite la hauteur du bouton
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       padding: '5px', // Ajustez le padding si nécessaire
//       borderRadius: '50%', // Rend le bouton rond
//       transition: 'all 0.3s ease', // Transition pour l'effet de survol
//       background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
//       border: 'none',
//       color: 'white',
//       boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//       cursor: 'pointer',
//       fontSize: '14px',
//     },
//     pencilButtonHover: {
//       transform: 'scale(1.2)', // Agrandit le bouton à 120% de sa taille normale
//     },

//     linkContainer: {
//       position: 'absolute',
//       top: '50%', // Centre verticalement par rapport aux cartes
//       left: '50%', // Centre horizontalement par rapport au conteneur
//       transform: 'translate(-50%, -50%)', // Ajuste pour centrer parfaitement
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//     },
//     linkIcon: {
//       width: '50px',
//       height: '50px',
//     },
//     pencilIcon: {
//       width: '16px',
//       height: '16px',
//     },
//     detailsTitle: {
//       marginTop: '30px', // Augmentez cette valeur pour déplacer le titre plus bas
//       paddingTop: '20px', // Ajoutez un padding supérieur si nécessaire
//       fontSize: '18px', // Taille du texte du titre
//       fontWeight: 'bold', // Rendre le titre en gras
//       textAlign: 'center', // Centre le texte
//       position: 'relative', // Permet un positionnement plus flexible
//       top: '10px', 
//     },
//   };

  
//   const handlePdfDrop = async (e) => {
//     e.preventDefault();
//     const newFiles = Array.from(e.dataTransfer.files).filter(file => file.type === 'application/pdf');
    
//     for (const file of newFiles) {
//       const formData = new FormData();
//       formData.append('pdfFile', file);
//       formData.append('clientId', client._id);
  
//       try {
//         const response = await fetch(`https://app.vtalys.fr/api/upload/${client._id}`, {
//           method: 'POST',
//           body: formData
//         });
  
//         const data = await response.json();
//         if (response.ok) {
//           const newFileObj = { 
//             fileName: data.fileName, 
//             fileData: data.fileData 
//           };
  
//           await updateClientWithPdf(newFileObj);
          
//           setPdfFiles(prevFiles => [...prevFiles, newFileObj]);
//           toast.success('Fichier PDF sauvegardé avec succès!');
//         } else {
//           console.error('Erreur lors de l’upload:', data.message);
//         }
//       } catch (error) {
//         console.error('Erreur réseau:', error);
//       }
//     }
//   };
  
  
//   const handlePdfUpload = async (event) => {
//     const file = event.target.files[0];
//     if (!file) return;
  
//     const formData = new FormData();
//     formData.append("pdfFile", file);
//     formData.append('clientId', client._id);
//     try {
//       const response = await fetch(`https://app.vtalys.fr/api/upload/${client._id}`, {
//         method: "POST",
//         body: formData
//       });
  
//       const data = await response.json();
//       if (response.ok) {
//         const newFileObj = { 
//           fileName: data.fileName, 
//           fileData: data.fileData 
//         };
  
//         await updateClientWithPdf(newFileObj);
//         setPdfFiles(prevFiles => [...prevFiles, newFileObj]);
//       toast.success('Fichier PDF sauvegardé avec succès!');
//     } else {
//       console.error('Erreur lors de l’upload:', data.message);
//     }
//   } catch (error) {
//     console.error('Erreur réseau:', error);
//   }
// };
  
  
//   // const downloadPdf = (file) => {
//   //   if (!file || !file.fileData || !file.fileName) {
//   //     console.error("❌ Fichier invalide ou données manquantes :", file);
//   //     return;
//   //   }
  
//   //   const byteCharacters = atob(file.fileData);
//   //   const byteNumbers = new Array(byteCharacters.length);
//   //   for (let i = 0; i < byteCharacters.length; i++) {
//   //     byteNumbers[i] = byteCharacters.charCodeAt(i);
//   //   }
//   //   const byteArray = new Uint8Array(byteNumbers);
//   //   const blob = new Blob([byteArray], { type: 'application/pdf' });
  
//   //   const fileUrl = URL.createObjectURL(blob);
//   //   const link = document.createElement('a');
//   //   link.href = fileUrl;
//   //   link.download = file.fileName;
//   //   document.body.appendChild(link);
//   //   link.click();
//   //   document.body.removeChild(link);
//   // };
  
//   const updateClientWithPdf = async (fileObj) => {
//     try {
//       const token = localStorage.getItem('token'); // récupère le token JWT depuis localStorage ou là où il est stocké
  
//       const response = await fetch(`https://app.vtalys.fr/api/clients/${client._id}`, {
//         method: 'PUT',
//         headers: { 
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}` // 🔑 Ajout du token ici
//         },
//         body: JSON.stringify({
//           ...client,
//           pdfFiles: [...pdfFiles, fileObj]
//         })
//       });
  
//       const data = await response.json();
//       if (response.ok) {
//         console.log("✅ Mise à jour client avec PDF réussie:", data);
//       } else {
//         console.error("❌ Erreur lors de la mise à jour client:", data.message);
//       }
//     } catch (error) {
//       console.error("❌ Erreur réseau lors de l'update client:", error);
//     }
//   };
  

//   useEffect(() => {
//     const fetchPdfFiles = async () => {
//       if (!client?._id) {
//         return;
//       }
//       try {
//         const response = await fetch(`https://app.vtalys.fr/api/clients/${client._id}/files`);
//         if (!response.ok) throw new Error(`Erreur HTTP : ${response.status}`);
  
//         const data = await response.json();
//         console.log("📂 Fichiers reçus du serveur :", data.files);
//         if (data.files && Array.isArray(data.files)) {
//           setPdfFiles(data.files);
//         }
//       } catch (error) {
//         console.error("❌ Erreur lors du chargement des fichiers:", error);
//       }
//     };
  
//     fetchPdfFiles();
//   }, [client?._id]);
  
    
  
// const downloadPdf = (file) => {
//   if (!file || !file.fileData || !file.fileName) {
//     console.error("❌ Fichier invalide ou données manquantes :", file);
//     return;
//   }

//   const byteCharacters = atob(file.fileData);
//   const byteNumbers = new Array(byteCharacters.length);
//   for (let i = 0; i < byteCharacters.length; i++) {
//     byteNumbers[i] = byteCharacters.charCodeAt(i);
//   }
//   const byteArray = new Uint8Array(byteNumbers);
//   const blob = new Blob([byteArray], { type: 'application/pdf' });

//   const fileUrl = URL.createObjectURL(blob);
//   const link = document.createElement('a');
//   link.href = fileUrl;
//   link.download = file.fileName;
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

// const uploadPdf = async (file) => {
//   const formData = new FormData();
//   formData.append('pdfFile', file);

//   try {
//     const response = await fetch(`https://app.vtalys.fr/api/upload/${client._id}`, {
//       method: 'POST',
//       body: formData,
//     });

//     const data = await response.json();
//     if (response.ok) {
//       toast.success('Fichier PDF sauvegardé avec succès!');
//       setPdfFiles(prevFiles => [...prevFiles, {
//         fileName: file.fileName,
//         fileData: data.fileData,
//       }]);
//     } else {
//       toast.error('Erreur lors de la sauvegarde du fichier PDF');
//     }
//   } catch (error) {
//     console.error('Erreur lors de l\'upload du fichier:', error);
//     toast.error('Erreur lors de l\'upload du fichier');
//   }
// };

  
//   return (
    
//     //  <div style={{ paddingTop: '50px', background: 'linear-gradient(87deg, #11cdef 0, #1171ef 100%)' }}>
//     <div style={{ paddingTop: '50px', paddingBottom: '30px', background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)' }}>
//       <Container className="mt-5">
//         <Card style={{ paddingBottom: '20px' }}>
//           <ToastContainer position="bottom-left" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
//           <CardHeader className="bg-white text-white">
//             <Button color="link" onClick={handleBack} style={{ marginRight: '20px', color: 'black' }}>
//               <FaArrowLeft />
//             </Button>
//             <h4 className="mb-0">{client._id ? "Modifier Client" : "Ajouter Nouveau Client"}</h4>
//             addLineBreakAfter(headerTitle);
//             <div style={{ marginTop: '10px' }}> {/* Controlled space for line break in React */}
              

//               <Nav style={{
//                 display: 'flex',
//                 justifyContent: 'space-evenly',
//                 marginBottom: '20px'
//               }}>
//                 {steps.map(step => (
//                   <NavItem key={step.id}>
//                     <NavLink
//                       className={classnames({ active: activeTab === step.id })}
//                       onClick={() => toggleTab(step.id)}
//                       style={activeTab === step.id ? navLinkActiveStyle : navLinkStyle}
//                       onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
//                       onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
//                     >
//                       <i className={`${step.icon}`} style={{ paddingRight: '8px', color: '#28a745' }} />
//                       {step.name}
//                     </NavLink>
//                   </NavItem>
//                 ))}
//               </Nav>

//             </div>
//           </CardHeader>


//           <CardBody>

//             <Nav tabs>
//             </Nav>


//             <div style={{ height: '1px', backgroundColor: 'transparent', marginTop: '10px', marginBottom: '10px' }}></div>
//             {/* iciii */}
            
//             <div style={{ height: '1px', backgroundColor: 'transparent', marginTop: '10px', marginBottom: '10px' }}></div>
//             <TabContent activeTab={activeTab} className="mt-3">
//               <TabPane tabId="1">
//                 {successMessage && (
//                   <UncontrolledAlert
//                     color="success"
//                     className="fixed-alert"
//                     style={{ position: 'fixed', top: '0', left: '0', right: '0', zIndex: '1050' }}
//                     fade={false}
//                   >
//                     <span className="alert-inner--icon">
//                       <i className="ni ni-like-2" />
//                     </span>
//                     <span className="alert-inner--text">
//                       <strong>Succès!</strong> {successMessage}
//                     </span>
//                   </UncontrolledAlert>
//                 )}
//                 {/* Bouton Modifier placé en haut */}

//                 <Card style={{ borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', overflow: 'hidden', margin: '20px 0' }}>
//                   <CardHeader style={{ background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)', color: 'white', textAlign: 'center', padding: '15px 0', fontSize: '1.2rem', fontWeight: 'bold' }}>
//                     Informations Client
//                   </CardHeader>
//                   <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0', marginRight: '15px' }}>
//                   <Button
//                           type="submit"
//                           color="primary"
//                           onClick={() => {
//                             if (validateForm()) {
//                               saveClient(client);
//                             }
//                           }}
//                           disabled={!isEditable}
//                           style={{
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             padding: '10px 20px',
//                             borderRadius: '8px',
//                             transition: 'all 0.3s ease',
//                             background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
//                             border: 'none',
//                             color: 'white',
//                             boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//                             fontSize: '16px',
//                           }}
//                           onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
//                           onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
//                         >
//                           <FontAwesomeIcon icon={faSave} style={{ marginRight: '8px' }} />
//                           Enregistrer
//                         </Button>
//                     <Button
//                       type="button"
//                       color="info"
//                       onClick={() => setIsEditable(true)}
//                       style={{
//                         display: 'flex',
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                         padding: '10px 20px',
//                         borderRadius: '8px',
//                         transition: 'all 0.3s ease',
//                         background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
//                         border: 'none',
//                         color: 'white',
//                         boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//                         fontSize: '16px'
//                       }}
//                       onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
//                       onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
//                       disabled={isEditable}
//                     >
//                       {/* modifier */}
//                       <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '15px' }} />

//                     </Button>
//                   </div>
//                   <CardBody style={{ padding: '30px', background: '#f8f9fa' }}>
//                     <Form onSubmit={handleSubmit}>
//                     <Label for="dateCreation">
//         Date de création
//         <Input
//                               type="date"
//                               name="dateCreation"
//                               id="dateCreation"
//                               value={client.dateCreation || ''}
//                               onChange={handleInputChange}
//                               disabled={!isEditable} 
//                               className="form-control-alternative"
//                               style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//                               readOnly
//                             />
//       </Label>
//                       <Row form>
                      
//                         <Col md={6}>
//                           <FormGroup>
//                             <Label for="nom">
//                               Nom <span style={{ color: 'red', position: 'relative', fontSize: '15px' }}>*</span>
//                             </Label>
//                             <Input
//                               type="text"
//                               name="nom"
//                               id="nom"
//                               value={client.nom}
//                               onChange={handleInputChange}
//                               required
//                               disabled={!isEditable}
//                               className="form-control-alternative"
//                               placeholder="Entrez le nom du client"
//                               style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//                             />
//                             {errors.nom && <p style={{ color: 'red' }}>{errors.nom}</p>}
//                           </FormGroup>
//                         </Col>
//                         <Col md={6}>
//                           <FormGroup>
//                             <Label for="prenom">Prénom</Label>
//                             <Input
//                               type="text"
//                               name="prenom"
//                               id="prenom"
//                               value={client.prenom}
//                               onChange={handleInputChange}
//                               disabled={!isEditable}
//                               className="form-control-alternative"
//                               placeholder="Entrez le prénom du client"
//                               style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//                             />
//                           </FormGroup>
//                         </Col>
//                       </Row>

//                       <Row form>
//                         <Col md={4}>
//                           <FormGroup>
//                             <Label for="dateNaissance">Date de Naissance</Label>
//                             <Input
//                               type="date"
//                               name="dateNaissance"
//                               id="dateNaissance"
//                               value={client.dateNaissance || ''}
//                               onChange={handleInputChange}
//                               disabled={!isEditable}
//                               onFocus={(e) => e.target.showPicker()} 
//                               className="form-control-alternative"
//                               style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//                             />
//                           </FormGroup>
//                         </Col>
//                         <Col md={4}>
//                           <FormGroup>
//                             <Label for="mutuelle">Mutuelle</Label>
//                             <Input
//                               type="text"
//                               name="mutuelle"
//                               id="mutuelle"
//                               value={client.mutuelle}
//                               onChange={handleInputChange}
//                               disabled={!isEditable}
//                               className="form-control-alternative"
//                               placeholder="Entrez la mutuelle"
//                               style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//                             />
//                           </FormGroup>
//                         </Col>
//                         <Col md={4}>
//                           <FormGroup>
//                             <Label for="numeroSecu">Numéro de Sécurité Sociale</Label>
//                             <Input
//                               type="text"
//                               name="numeroSecu"
//                               id="numeroSecu"
//                               value={client.numeroSecu}
//                               onChange={handleInputChange}
//                               disabled={!isEditable}
//                               className="form-control-alternative"
//                               placeholder="Entrez le numéro de sécurité sociale"
//                               style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//                             />
//                           </FormGroup>
//                         </Col>
//                       </Row>

//                       <Row form>
//                         <Col md={4}>
//                           <FormGroup>
//                             <Label for="caisse">Caisse</Label>
//                             <Input
//                               type="text"
//                               name="caisse"
//                               id="caisse"
//                               value={client.caisse}
//                               onChange={handleInputChange}
//                               disabled={!isEditable}
//                               className="form-control-alternative"
//                               placeholder="Entrez la caisse"
//                               style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//                             />
//                           </FormGroup>
//                         </Col>
//                         <Col md={4}>
//                           <FormGroup>
//                             <Label for="origine">Origine</Label>
//                             <Input
//                               type="select"
//                               name="origine"
//                               id="origine"
//                               value={client.origine}
//                               onChange={handleInputChange}
//                               disabled={!isEditable}
//                               className="form-control-alternative"
//                               style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//                             >
//                               <option value="">Sélectionner une origine</option>
//                               <option value="Site">Site</option>
//                               <option value="Facebook">Facebook</option>
//                               <option value="Ouieqare">Ouieqare</option>
//                               <option value="Audibene">Audibene</option>
//                               <option value="Direct">Direct</option>
//                               <option value="Google">Google</option>
//                               <option value="Doctolib">Doctolib</option>
//                             </Input>
//                           </FormGroup>
//                         </Col>
//                       </Row>
                        
//                         <Row form>
//   {/* Colonne pour le champ "Statut" */}
//   <Col md={4}>
//     <FormGroup>
//       <Label for="statut">Statut</Label>
//       <Input
//         type="select"
//         name="statut"
//         id="statut"
//         value={client.statut}
//         onChange={handleStatutChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
//       >
//         <option value="">Sélectionner un statut</option>
//         <option value="Rdv Bilan auditif">Rdv Bilan auditif</option>
//         <option value="Rdv Téléconsultation">Rdv Téléconsultation</option>
//         <option value="Rdv devis">Rdv Devis</option>
//         <option value="Rdv Annulé">Rdv Annulé</option>
//         <option value="En livraison">En livraison</option>
//         <option value="Rdv livraison">Rdv livraison</option>
//         <option value="Appareillé">Appareillé</option>
//         <option value="Rdv 1er essai">Rdv 1er essai</option>
//         <option value="Rdv 2eme essai">Rdv 2eme essai</option>
//         <option value="Rdv facturation">Rdv facturation</option>
//         <option value="Facturé">Facturé</option>
//         {/* <option value="Facture éditée">Facturé éditée</option> */}
//       </Input>
//     </FormGroup>
//   </Col>

//   {/* Colonne conditionnelle pour la date d'Appareillage (reste toujours visible) */}
//   <Col md={4} style={{ minWidth: '250px' }}>
//     {(client.statut === "Appareillé" || client.statut === "Facturé") && (
//       <FormGroup>
//         <Label for="dateAppareillage">Date d'Appareillage</Label>
//         <Input
//           type="date"
//           name="dateAppareillage"
//           id="dateAppareillage"
//           value={client.dateAppareillage || ''}
//           onChange={handleInputChange}
//           disabled={!isEditable}
//           onFocus={(e) => e.target.showPicker()} 
//           className="form-control-alternative"
//           style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
//         />
//       </FormGroup>
//     )}
//   </Col>

//   {/* Colonne pour les autres dates qui changent en fonction du statut */}
//   <Col md={4} style={{ minWidth: '250px' }}>
//     {client.statut === "Facturé" ? (
//       <>
//       <FormGroup>
//         <Label for="dateFacturation">Date de Facturation</Label>
//         <Input
//           type="date"
//           name="dateFacturation"
//           id="dateFacturation"
//           value={client.dateFacturation ? formatDate(client.dateFacturation) : ''}
//           onChange={handleInputChange}
//           disabled={!isEditable}
//           onFocus={(e) => e.target.showPicker()} 
//           className="form-control-alternative"
//           style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
//         />
//       </FormGroup>
//       <FormGroup>
//           <Label for="heureFacturation">Heure de Facturation</Label>
//           <Input
//             type="time"
//             name="heureFacturation"
//             id="heureFacturation"
//             value={client.heureFacturation || ''}
//             onChange={handleInputChange}
//             disabled={!isEditable}
//             className="form-control-alternative"
//             style={{ /* styles */ }}
//           />
//         </FormGroup>
//       </>
//     ) : client.statut === "Rdv Bilan auditif" ? (
//       <>
//       <FormGroup>
//         <Label for="dateBilanAuditif">Date Bilan Auditif</Label>
//         <Input
//           type="date"
//           name="dateBilanAuditif"
//           id="dateBilanAuditif"
//           value={client.dateBilanAuditif ? formatDate(client.dateBilanAuditif) : ''}
//           onChange={handleInputChange}
//           disabled={!isEditable}
//           onFocus={(e) => e.target.showPicker()} 
//           className="form-control-alternative"
//           style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
//         />
//       </FormGroup>
//       <FormGroup>
//           <Label for="heureBilanAuditif">Heure Bilan Auditif</Label>
//           <Input
//             type="time"
//             name="heureBilanAuditif"
//             id="heureBilanAuditif"
//             value={client.heureBilanAuditif || ''}
//             onChange={handleInputChange}
//             disabled={!isEditable}
//             className="form-control-alternative"
//             style={{ /* styles */ }}
//           />
//         </FormGroup>
//       </>
//     ) : client.statut === "Rdv livraison" ? (
//       <>
//       <FormGroup>
//         <Label for="dateRdvLivraison">Date de Livraison</Label>
//         <Input
//           type="date"
//           name="dateRdvLivraison"
//           id="dateRdvLivraison"
//           value={client.dateRdvLivraison ? formatDate(client.dateRdvLivraison) : ''}
//           onChange={handleInputChange}
//           disabled={!isEditable}
//           onFocus={(e) => e.target.showPicker()} 
//           className="form-control-alternative"
//           style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
//         />
//       </FormGroup>
//       <FormGroup>
//           <Label for="heureRdvLivraison">Heure de Livraison</Label>
//           <Input
//             type="time"
//             name="heureRdvLivraison"
//             id="heureRdvLivraison"
//             value={client.heureRdvLivraison || ''}
//             onChange={handleInputChange}
//             disabled={!isEditable}
//             className="form-control-alternative"
//             style={{ /* styles */ }}
//           />
//         </FormGroup>
//       </>
//       ) : client.statut === "Rdv devis" ? (
//         <>
//         <FormGroup>
//           <Label for="dateRdvDevis">Date de Rdv Devis</Label>
//           <Input
//             type="date"
//             name="dateRdvDevis"
//             id="dateRdvDevis"
//             value={client.dateRdvDevis ? formatDate(client.dateRdvDevis) : ''}
//             onChange={handleInputChange}
//             disabled={!isEditable}
//             onFocus={(e) => e.target.showPicker()} 
//             className="form-control-alternative"
//             style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
//           />
//         </FormGroup>
//         <FormGroup>
//           <Label for="heureRdvDevis">Heure de Rdv Devis</Label>
//           <Input
//             type="time"
//             name="heureRdvDevis"
//             id="heureRdvDevis"
//             value={client.heureRdvDevis || ''}
//             onChange={handleInputChange}
//             disabled={!isEditable}
//             className="form-control-alternative"
//             style={{ /* styles */ }}
//           />
//         </FormGroup>
//       </>
//         ) : client.statut === "Rdv Téléconsultation" ? (
//           <>
//           <FormGroup>
//             <Label for="dateRdvTeleconsultation">Date de Rdv Teleconsultation</Label>
//             <Input
//               type="date"
//               name="dateRdvTeleconsultation"
//               id="dateRdvTeleconsultation"
//               value={client.dateRdvTeleconsultation ? formatDate(client.dateRdvTeleconsultation) : ''}
//               onChange={handleInputChange}
//               disabled={!isEditable}
//               onFocus={(e) => e.target.showPicker()} 
//               className="form-control-alternative"
//               style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
//             />
//           </FormGroup>
//           <FormGroup>
//           <Label for="heureRdvTeleconsultation">Heure de Rdv Téléconsultation</Label>
//           <Input
//             type="time"
//             name="heureRdvTeleconsultation"
//             id="heureRdvTeleconsultation"
//             value={client.heureRdvTeleconsultation || ''}
//             onChange={handleInputChange}
//             disabled={!isEditable}
//             className="form-control-alternative"
//             style={{ /* styles */ }}
//           />
//         </FormGroup>
//       </>
//     ) : client.statut === "Rdv 1er essai" ? (
//       <>
//       <FormGroup>
//         <Label for="datePremierEssai">Date 1er Essai</Label>
//         <Input
//           type="date"
//           name="datePremierEssai"
//           id="datePremierEssai"
//           value={client.datePremierEssai ? formatDate(client.datePremierEssai) : ''}
//           onChange={handleInputChange}
//           disabled={!isEditable}
//           onFocus={(e) => e.target.showPicker()} 
//           className="form-control-alternative"
//           style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
//         />
//       </FormGroup>
//       <FormGroup>
//           <Label for="heurePremierEssai">Heure 1er Essai</Label>
//           <Input
//             type="time"
//             name="heurePremierEssai"
//             id="heurePremierEssai"
//             value={client.heurePremierEssai || ''}
//             onChange={handleInputChange}
//             disabled={!isEditable}
//             className="form-control-alternative"
//             style={{ /* styles */ }}
//           />
//         </FormGroup>
//       </>
//     ) : client.statut === "Rdv 2eme essai" ? (
//       <>
//       <FormGroup>
//         <Label for="dateDeuxiemeEssai">Date 2ème Essai</Label>
//         <Input
//           type="date"
//           name="dateDeuxiemeEssai"
//           id="dateDeuxiemeEssai"
//           value={client.dateDeuxiemeEssai ? formatDate(client.dateDeuxiemeEssai): ''}
//           onChange={handleInputChange}
//           disabled={!isEditable}
//           onFocus={(e) => e.target.showPicker()} 
//           className="form-control-alternative"
//           style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
//         />
//       </FormGroup>
//       <FormGroup>
//           <Label for="heureDeuxiemeEssai">Heure 2ème Essai</Label>
//           <Input
//             type="time"
//             name="heureDeuxiemeEssai"
//             id="heureDeuxiemeEssai"
//             value={client.heureDeuxiemeEssai || ''}
//             onChange={handleInputChange}
//             disabled={!isEditable}
//             className="form-control-alternative"
//             style={{ /* styles */ }}
//           />
//         </FormGroup>
//       </>
//     ) : client.statut === "Rdv facturation" ? (
//       <>
//       <FormGroup>
//         <Label for="dateRdvFacturation">Date Rdv Facturation</Label>
//         <Input
//           type="date"
//           name="dateRdvFacturation"
//           id="dateRdvFacturation"
//           value={client.dateRdvFacturation ? formatDate(client.dateRdvFacturation) : ''}
//           onChange={handleInputChange}
//           disabled={!isEditable}
//           onFocus={(e) => e.target.showPicker()} 
//           className="form-control-alternative"
//           style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', width: '100%' }}
//         />
//       </FormGroup>
//       <FormGroup>
//           <Label for="heureRdvFacturation">Heure Rdv Facturation</Label>
//           <Input
//             type="time"
//             name="heureRdvFacturation"
//             id="heureRdvFacturation"
//             value={client.heureRdvFacturation || ''}
//             onChange={handleInputChange}
//             disabled={!isEditable}
//             className="form-control-alternative"
//             style={{ /* styles */ }}
//           />
//         </FormGroup>
//       </>
//     ) : (
//       <div style={{ height: '56px' }}></div> // Espace vide si aucun statut ne correspond
//     )}
//   </Col>
// </Row>


                       

//                       <Row form>
//                         <Col md={6}>
//                           <FormGroup>
//                             <Label for="telephoneFixe">Téléphone Fixe</Label>
//                             <Input
//                               type="text"
//                               name="telephoneFixe"
//                               id="telephoneFixe"
//                               value={client.telephoneFixe}
//                               onChange={handleInputChange}
//                               disabled={!isEditable}
//                               className="form-control-alternative"
//                               placeholder="Entrez le téléphone fixe"
//                               style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//                             />
//                           </FormGroup>
//                         </Col>
//                         <Col md={6}>
//                           <FormGroup>
//                             <Label for="telephonePortable">Téléphone Portable</Label>
//                             <Input
//                               type="text"
//                               name="telephonePortable"
//                               id="telephonePortable"
//                               value={client.telephonePortable}
//                               onChange={handleInputChange}
//                               disabled={!isEditable}
//                               className="form-control-alternative"
//                               placeholder="Entrez le téléphone portable"
//                               style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//                             />
//                           </FormGroup>
//                         </Col>
//                       </Row>

//                       <Row form>
//                         <Col md={6}>
//                           <FormGroup>
//                             <Label for="codePostal">Code Postal</Label>
//                             <Input
//                               type="text"
//                               name="codePostal"
//                               id="codePostal"
//                               value={client.codePostal}
//                               onChange={handleInputChange}
//                               disabled={!isEditable}
//                               className="form-control-alternative"
//                               placeholder="Entrez le code postal"
//                               style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//                             />
//                           </FormGroup>
//                         </Col>
//                         <Col md={6}>
//                           <FormGroup>
//                             <Label for="ville">Ville</Label>
//                             <Input
//                               type="text"
//                               name="ville"
//                               id="ville"
//                               value={client.ville}
//                               onChange={handleInputChange}
//                               disabled={!isEditable}
//                               className="form-control-alternative"
//                               placeholder="Entrez la ville"
//                               style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//                             />
//                           </FormGroup>
//                         </Col>
//                       </Row>

//                       <FormGroup>
//                         <Label for="adresse">Adresse</Label>
//                         <Input
//                           type="text"
//                           name="adresse"
//                           id="adresse"
//                           value={client.adresse}
//                           onChange={handleInputChange}
//                           disabled={!isEditable}
//                           className="form-control-alternative"
//                           placeholder="Entrez l'adresse"
//                           style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', minHeight: '50px', color: '#000' }}
//                         />
//                       </FormGroup>

//                       <FormGroup>
//                         <Label for="email">
//                           Email <span style={{ color: 'red', position: 'relative', fontSize: '15px' }}>*</span>
//                         </Label>
//                         <Input
//                           type="email"
//                           name="email"
//                           id="email"
//                           value={client.email}
//                           onChange={handleInputChange}
//                           required
//                           disabled={!isEditable}
//                           className="form-control-alternative"
//                           placeholder="Entrez l'email"
//                           style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//                         />
//                         {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
//                       </FormGroup>

//                       <FormGroup>
//                         <Label for="input-prescripteur" className="form-control-label">Prescripteur</Label>
//                         <Select
//                           id="input-prescripteur"
//                           value={selectedOrl}
//                           onChange={handleSelectChange}
//                           options={orlOptions}
//                           classNamePrefix="select"
//                           placeholder="Choisir un prescripteur"
//                           isClearable={true}
//                           isSearchable={true}
//                           isDisabled={!isEditable}
//                           styles={{
//                             control: (provided) => ({
//                               ...provided,
//                               minHeight: 'calc(1.5em + 1.3rem + 2px)',
//                               height: 'calc(2.4rem + 2px)',
//                               borderRadius: '8px',
//                               border: '1px solid #ddd', color: '#000'
//                             }),
//                             valueContainer: (provided) => ({
//                               ...provided,
//                               height: 'calc(2.4rem + 2px)'
//                             })
//                           }}
//                         />
//                       </FormGroup>

//                       <FormGroup>
//                         <Label for="note">Note</Label>
//                         <Input
//                           type="textarea"
//                           name="note"
//                           id="note"
//                           value={client.note}
//                           onChange={handleInputChange}
//                           disabled={!isEditable}
//                           className="form-control-alternative"
//                           placeholder="Entrez une note"
//                           style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000', minHeight: '100px', maxHeight: '300px' }}
//                         />
//                       </FormGroup>
//                       <div
//   onDrop={handlePdfDrop}
//   onDragOver={(e) => e.preventDefault()}
//   style={{
//     border: '2px dashed #007D70',
//     borderRadius: '10px',
//     padding: '20px',
//     textAlign: 'center',
//     marginBottom: '20px',
//     cursor: 'pointer',
//     backgroundColor: '#f8f9fa'
//   }}
// >
//   <p>Glissez et déposez les fichiers PDF ici ou</p>
//   <input
//     type="file"
//     accept="application/pdf"
//     multiple
//     onChange={handlePdfUpload}
//     style={{ display: 'none' }}
//     id="file-upload"
//   />
//   <label htmlFor="file-upload" style={{ cursor: 'pointer', color: '#007D70', textDecoration: 'underline' }}>
//     Cliquez pour sélectionner des fichiers PDF
//   </label>
// </div>
// {console.log("📂 Liste des fichiers avant affichage :", pdfFiles)}

// <ul>
//   {pdfFiles.length > 0 ? (
//     pdfFiles.map((file) => (
//       <li key={file.fileId}>
//         <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '8px', color: '#007D70' }} />
//         {file.fileName}
//         <Button color="link" onClick={() => downloadPdf(file.fileId)}>Télécharger</Button>
//       </li>
//     ))
//   ) : (
//     <p>Aucun fichier disponible.</p>
//   )}
// </ul>






//                       <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>


                        
//                         {/* <div style={{ float: 'right' }}> */}
//               {/* {client._id && (
//                 <>
//                   <Button
//                     color="primary"
//                     onClick={() => {
//                       if (validateForm()) {
//                         saveClient(client);
//                       }
//                     }}
//                     disabled={!isEditable}
//                     style={{
//                       marginLeft: '10px',
//                       background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
//                       border: 'none' // Ajouté pour enlever la bordure par défaut qui peut altérer le rendu du dégradé
//                     }}
//                   >
//                     Enregistrer Modifications
//                   </Button>

//                 </>
//               )} */}
//               {/* <Button
//     color="primary"
//     onClick={() => {
//       if (validateForm()) {
//         saveClient(client);
//       }
//     }}
//     disabled={!isEditable}
//     style={{
//       marginLeft: '10px',
//       background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
//       border: 'none' // Ajouté pour enlever la bordure par défaut qui peut altérer le rendu du dégradé
//     }}
//   >
//     {client._id ? "Enregistrer Modifications" : "Enregistrer Nouveau Client"}
//   </Button>
//             </div> */}
//                         <Button
//                           type="button"
//                           color="secondary"
//                           onClick={() => history.push('/admin/clients')}
//                           style={{
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             padding: '10px 20px',
//                             borderRadius: '8px',
//                             transition: 'all 0.3s ease',
//                             background: 'linear-gradient(87deg, #6c757d 0%, #5a6268 100%)',
//                             border: 'none',
//                             color: 'white',
//                             boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//                             fontSize: '16px',
//                           }}
//                           onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
//                           onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
//                         >
//                           <FontAwesomeIcon icon={faTimes} style={{ marginRight: '8px' }} />
//                           Annuler
//                         </Button>
//                       </div>
//                     </Form>
//                   </CardBody>
//                 </Card>
//               </TabPane>

//               <TabPane tabId="2">
//                 {audiogrammeSuccessMessage && (
//                   <UncontrolledAlert color="success" className="fixed-alert" fade={false}>
//                     <span className="alert-inner--icon"><i className="ni ni-like-2" /></span>
//                     <span className="alert-inner--text"><strong>Succès!</strong> {audiogrammeSuccessMessage}</span>
//                   </UncontrolledAlert>
//                 )}
//                 <Form onSubmit={handleSubmitAudiogramme} style={{ paddingTop: '50px' }}>
//                   <FormGroup>
//                     <Label for="audiogramme">Audiogramme</Label>
//                     <Input type="text" name="audiogramme" id="audiogramme" value={client.audiogramme} onChange={handleInputChange} disabled={!isEditable} />
//                   </FormGroup>
//                   {/* Ajoutez plus de champs selon vos besoins ici */}
//                   <Button type="submit" color="primary">Enregistrer Rdv Bilan auditif</Button>
//                 </Form>
//               </TabPane>
//               <TabPane tabId="3">
//                 {/* <Form onSubmit={handleSubmitAudiogramme} style={{ paddingTop: '50px' }}>
//                   <FormGroup>
//                     <Label for="typeAppareil">Type d'Appareil</Label>
//                     <Input type="text" name="typeAppareil" id="typeAppareil" value={client.typeAppareil} onChange={handleInputChange} disabled={!isEditable} />
//                   </FormGroup>
//                   <FormGroup>
//                     <Label for="modeleAppareil">Modèle de l'Appareil</Label>
//                     <Input type="text" name="modeleAppareil" id="modeleAppareil" value={client.modeleAppareil} onChange={handleInputChange} disabled={!isEditable} />
//                   </FormGroup>
//                   <FormGroup>
//                     <Label for="numeroSerie">Numéro de Série</Label>
//                     <Input type="text" name="numeroSerie" id="numeroSerie" value={client.numeroSerie} onChange={handleInputChange} disabled={!isEditable} />
//                   </FormGroup>
//                   <FormGroup>
//                     <Label for="prix">Prix</Label>
//                     <Input type="number" name="prix" id="prix" value={client.prix} onChange={handleInputChange} disabled={!isEditable} />
//                   </FormGroup>
//                   <FormGroup>
//                     <Label for="garantie">Durée de la Garantie</Label>
//                     <Input type="text" name="garantie" id="garantie" value={client.garantie} onChange={handleInputChange} disabled={!isEditable} />
//                   </FormGroup>
//                   <FormGroup>
//                     <Label for="servicesInclus">Services Inclus</Label>
//                     <Input type="text" name="servicesInclus" id="servicesInclus" value={client.servicesInclus} onChange={handleInputChange} disabled={!isEditable} />
//                   </FormGroup>
//                   <FormGroup>
//                     <Label for="acompte">Acompte Requis</Label>
//                     <Input type="number" name="acompte" id="acompte" value={client.acompte} onChange={handleInputChange} disabled={!isEditable} />
//                   </FormGroup>
//                   <Button color="secondary" onClick={downloadPDF}>Générer Devis PDF</Button>
//                   <Button type="submit" color="primary">Enregistrer Devis</Button>
//                 </Form> */}
//                 {console.log("Données du client dans l'onglet Devis:", client)}
//                 <Card style={{ borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', overflow: 'hidden', margin: '20px 0' }}>
//                   <CardHeader style={{ background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)', color: 'white', textAlign: 'center', padding: '15px 0', fontSize: '1.2rem', fontWeight: 'bold' }}>
//                     Devis
//                   </CardHeader>
                 
//                   <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0', marginRight: '15px' }}>
//                   <Button
//   color="secondary"
//   onClick={() => generateDevisPDF(client, user)}  // Passez l'ID du client et les données du devis
//   style={{
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     padding: '10px 20px',
//     borderRadius: '8px',
//     transition: 'all 0.3s ease',
//     background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)',
//     border: 'none',
//     color: 'white',
//     boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//     fontSize: '16px',
//   }}
//   onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
//   onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
// >
//   <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '8px' }} />
//   Générer Devis PDF
// </Button>
//                     <Button
//                       type="button"
//                       color="info"
//                       onClick={() => setIsEditable(true)}
//                       style={{
//                         display: 'flex',
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                         padding: '10px 20px',
//                         borderRadius: '8px',
//                         transition: 'all 0.3s ease',
//                         background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)',
//                         border: 'none',
//                         color: 'white',
//                         boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//                         fontSize: '16px'
//                       }}
//                       onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
//                       onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
//                       disabled={isEditable}
//                     >
//                       {/* modifier */}
//                       <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '15px' }} />

//                     </Button>
//                   </div>
//                   <CardBody style={{ padding: '30px', background: '#f8f9fa' }}>
//                     <Form onSubmit={handleSubmitAudiogramme}>
//                       <Row form>
                      
// </Row>

// <Row form>
//   <Col md={6}>
//     <FormGroup>
//       <Label for="dateDevis">Date du Devis</Label>
//       <Input
//         type="date"
//         name="dateDevis"
//         id="dateDevis"
//         value={client.dateDevis || ''}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         onFocus={(e) => e.target.showPicker()} 
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//   </Col>
//   <Col md={6}>
//     <FormGroup>
//       <Label for="numeroDevis">Numéro du Devis</Label>
//       <Input
//         type="text"
//         name="numeroDevis"
//         id="numeroDevis"
//         placeholder="Numéro du Devis"
//         value={client.numeroDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//   </Col>
//   <Col md={6}>
//     <FormGroup>
//       <Label for="dureeValiditeDevis">Durée de Validité du Devis (en jours)</Label>
//       <Input
//         type="number"
//         name="dureeValiditeDevis"
//         id="dureeValiditeDevis"
//         placeholder="Durée de validité"
//         value={client.dureeValiditeDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//   </Col>
// </Row>

// {/* Section Appareil Gauche et Droit */}
// <Row form>
//   <Col md={6}>
//     <h5 className="text-center">Appareil Gauche</h5>
//     <FormGroup>
//       <Label for="marqueAppareilGDevis">Marque Appareil</Label>
//       <Input
//         type="text"
//         name="marqueAppareilGDevis"
//         id="marqueAppareilGDevis"
//         placeholder="Ex: Appareil auditif"
//         value={client.marqueAppareilGDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//     <FormGroup>
//       <Label for="modeleAppareilGDevis">Modèle de l'Appareil</Label>
//       <Input
//         type="text"
//         name="modeleAppareilGDevis"
//         id="modeleAppareilGDevis"
//         placeholder="Ex: Modèle XYZ"
//         value={client.modeleAppareilGDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//     <FormGroup>
//       <Label for="numeroSerieGDevis">Numéro de Série</Label>
//       <Input
//         type="text"
//         name="numeroSerieGDevis"
//         id="numeroSerieGDevis"
//         placeholder="Ex: 123456789"
//         value={client.numeroSerieGDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//     <FormGroup>
//       <Label for="prixHTGDevis">Prix HT (€)</Label>
//       <Input
//         type="number"
//         name="prixHTGDevis"
//         id="prixHTGDevis"
//         placeholder="Prix Hors Taxes"
//         value={client.prixHTGDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//     {/* <FormGroup>
//       <Label for="prixTTCGDevis">Prix TTC (€)</Label>
//       <Input
//         type="number"
//         name="prixTTCGDevis"
//         id="prixTTCG"
//         placeholder="Prix Toutes Taxes Comprises"
//         value={client.prixTTCGDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup> */}
//   </Col>

//   <Col md={6}>
//     <h5 className="text-center">Appareil Droit</h5>
//     <FormGroup>
//       <Label for="marqueAppareilDDevis">Marque Appareil</Label>
//       <Input
//         type="text"
//         name="marqueAppareilDDevis"
//         id="marqueAppareilDDevis"
//         placeholder="Ex: Appareil auditif"
//         value={client.marqueAppareilDDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//     <FormGroup>
//       <Label for="modeleAppareilDDevis">Modèle de l'Appareil</Label>
//       <Input
//         type="text"
//         name="modeleAppareilDDevis"
//         id="modeleAppareilDDevis"
//         placeholder="Ex: Modèle XYZ"
//         value={client.modeleAppareilDDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//     <FormGroup>
//       <Label for="numeroSerieDDevis">Numéro de Série</Label>
//       <Input
//         type="text"
//         name="numeroSerieDDevis"
//         id="numeroSerieDDevis"
//         placeholder="Ex: 123456789"
//         value={client.numeroSerieDDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//     <FormGroup>
//       <Label for="prixHTDDevis">Prix HT (€)</Label>
//       <Input
//         type="number"
//         name="prixHTDDevis"
//         id="prixHTDDevis"
//         placeholder="Prix Hors Taxes"
//         value={client.prixHTDDevis || ''}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//     {/* <FormGroup>
//       <Label for="prixTTCDDevis">Prix TTC (€)</Label>
//       <Input
//         type="number"
//         name="prixTTCDDevis"
//         id="prixTTCDDevis"
//         placeholder="Prix Toutes Taxes Comprises"
//         value={client.prixTTCDDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup> */}
//   </Col>
// </Row>

// <Row form>
//   <Col md={6}>
//     <FormGroup>
//       <Label for="classeAppareilDevis">Classe de l'Appareil</Label>
//       <Input
//         type="text"
//         name="classeAppareilDevis"
//         id="classeAppareilDevis"
//         placeholder="Classe 1 ou Classe 2"
//         value={client.classeAppareilDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//   </Col>
  
//   </Row>

// <Row form>
//   <Col md={6}>
//     <FormGroup>
//       <Label for="rbstSSDevis">Remboursement Sécurité Sociale (€)</Label>
//       <Input
//         type="number"
//         name="rbstSSDevis"
//         id="rbstSSDevis"
//         placeholder="Montant remboursé"
//         value={client.rbstSSDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//   </Col>
//   <Col md={6}>
//     <FormGroup>
//       <Label for="rbstComplDevis">Remboursement Mutuelle (€)</Label>
//       <Input
//         type="number"
//         name="rbstComplDevis"
//         id="rbstComplDevis"
//         placeholder="Montant remboursé par la Mutuelle"
//         value={client.rbstComplDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//   </Col>
// </Row>

// <Row form>
//   <Col md={6}>
//     <FormGroup>
//       <Label for="garantieDevis">Durée de la Garantie</Label>
//       <Input
//         type="text"
//         name="garantieDevis"
//         id="garantieDevis"
//         placeholder="Ex: 2 ans"
//         value={client.garantieDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//   </Col>
//   <Col md={6}>
//     <FormGroup>
//       <Label for="servicesInclusDevis">Services Inclus</Label>
//       <Input
//         type="text"
//         name="servicesInclusDevis"
//         id="servicesInclusDevis"
//         placeholder="Ex: Ajustements gratuits"
//         value={client.servicesInclusDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//   </Col>
// </Row>
// {/* Prix accessoires */}
// <Row form>
//   <Col md={6}>
//     <FormGroup>
//       <Label for="prixHTAccessoiresDevis">Prix HT des Accessoires (€)</Label>
//       <Input
//         type="number"
//         name="prixHTAccessoiresDevis"
//         id="prixHTAccessoiresDevis"
//         placeholder="Prix Hors Taxes des accessoires"
//         value={client.prixHTAccessoiresDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//   </Col>
//   {/* <Col md={6}>
//     <FormGroup>
//       <Label for="prixTTCAccessoiresDevis">Prix TTC des Accessoires (€)</Label>
//       <Input
//         type="number"
//         name="prixTTCAccessoiresDevis"
//         id="prixTTCAccessoiresDevis"
//         placeholder="Prix Toutes Taxes Comprises des accessoires"
//         value={client.prixTTCAccessoiresDevis}
//         onChange={handleInputChange}
//         disabled={!isEditable}
//         className="form-control-alternative"
//         style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//       />
//     </FormGroup>
//   </Col> */}
// </Row>

// <FormGroup>
//   <Label for="acompteDevis">Acompte Requis (€)</Label>
//   <Input
//     type="number"
//     name="acompteDevis"
//     id="acompteDevis"
//     placeholder="Ex: 200"
//     value={client.acompteDevis}
//     onChange={handleInputChange}
//     disabled={!isEditable}
//     className="form-control-alternative"
//     style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', color: '#000' }}
//   />
// </FormGroup>

// <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                      

//                         {/* <Button
//                           type="submit"
//                           color="primary"
//                           style={{
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             padding: '10px 20px',
//                             borderRadius: '8px',
//                             transition: 'all 0.3s ease',
//                             background: 'linear-gradient(87deg, #007BFF 0%, #0056b3 100%)',
//                             border: 'none',
//                             color: 'white',
//                             boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//                             fontSize: '16px',
//                           }}
//                           onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
//                           onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
//                         >
//                           <FontAwesomeIcon icon={faSave} style={{ marginRight: '8px' }} />
//                           Enregistrer Devis
//                         </Button> */}
//                         <Button
//                         type="submit"
//     color="primary"
//     onClick={() => {
//       if (validateForm()) {
//         saveClient(client);
//       }
//     }}
//     disabled={!isEditable}
//     style={{
//       marginLeft: '10px',
//       background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
//       border: 'none' // Ajouté pour enlever la bordure par défaut qui peut altérer le rendu du dégradé
//     }}
//   >
//     {client._id ? "Enregistrer Modifications" : "Enregistrer Nouveau Client"}
//   </Button>
//                       </div>
//                     </Form>
//                   </CardBody>
//                 </Card>
//               </TabPane>

//               {/* <TabPane tabId="4">
//   <Form onSubmit={handleSubmitAppareillage} style={{ paddingTop: '50px' }}>
//     <FormGroup>
//       <Label for="marqueAppareilG">Produit 1 </Label>
//       <Input type="text" name="marqueAppareilG" id="marqueAppareilG" value={client.marqueAppareilG} onChange={handleInputChange} />
//     </FormGroup>
//     <FormGroup>
//       <Label for="marqueAppareil2">Produit 2 </Label>
//       <Input type="text" name="marqueAppareil" id="marqueAppareil" value={client.marqueAppareil2} onChange={handleInputChange} />
//     </FormGroup>
//     <FormGroup>
//       <Label for="codeProduit">Code du Produit</Label>
//       <Input type="text" name="codeProduit" id="codeProduit" value={client.codeProduit} onChange={handleInputChange} />
//     </FormGroup>
//     <FormGroup>
//               <Label for="productQuantity">Quantité</Label>
//               <Input
//                 type="number"
//                 id="productQuantity"
//                 name="quantity"
//                 value={client.quantity}
//                 onChange={handleInputChange}
//                 required
//               />
//             </FormGroup>
//     <FormGroup>
//       <Label for="montantAppareil">Montant du Produit</Label>
//       <Input type="text" name="montantAppareil" id="montantAppareil" value={client.montantAppareil} onChange={handleInputChange} />
//     </FormGroup>
//     <FormGroup check>
//       <Label check>
//         <Input type="checkbox" name="monoAppareil" id="monoAppareil" checked={client.monoAppareil || false} onChange={handleCheckboxChange} />
//         Mono Appareil
//       </Label>
//     </FormGroup>
//     <FormGroup>
//       <Label for="observations">Observations</Label>
//       <Input type="textarea" name="Appobservations" id="Appobservations" value={client.Appobservations} onChange={handleInputChange} />
//     </FormGroup>
//     <Button type="submit" color="primary">Enregistrer Appareillage</Button>
//   </Form>
// </TabPane> */}

//               {/* <TabPane tabId="4">
//               {audiogrammeSuccessMessage && (
//                   <UncontrolledAlert color="success" className="fixed-alert" fade={false}>
//                     <span className="alert-inner--icon"><i className="ni ni-like-2" /></span>
//                     <span className="alert-inner--text"><strong>Succès!</strong> {audiogrammeSuccessMessage}</span>
//                   </UncontrolledAlert>
//                 )}
//                 <Form onSubmit={handleSubmitAppareillage} style={{ paddingTop: '30px' }}>
//                   <div style={containerStyle}>
//                     <div style={EarCardStyle}>
//                       <h5><strong>Appareil Gauche</strong></h5>
//                       {[
//                         { id: 'marqueAppareilG', label: 'Marque' },
//                         { id: 'modelAppareilG', label: 'Modèle' },
//                         { id: 'codeProduitG', label: 'Code' },
//                         { id: 'lppG', label: 'LPP' },
//                         { id: 'quantityG', label: 'Quantité', type: 'number' },
//                         { id: 'montantAppareilG', label: 'Montant HT' }
//                       ].map(({ id, label, type }) => (
//                         <FormGroup key={id} style={formGroupStyle}>
//                           <Label for={id} style={labelStyle}>{label}</Label>
//                           <Input
//                             type={type || 'text'}
//                             name={id}
//                             id={id}
//                             value={client.id}
//                             onChange={handleInputChange}
//                             style={{ fontSize: '12px', padding: '6px' }}
//                             disabled={!isEditable} // Désactive le champ si isEditable est false
//                           />
//                         </FormGroup>
//                       ))}
//                     </div>

//                     <div style={EarCardStyle}>
//                       <h5><strong>Appareil Droit</strong></h5>
//                       {[
//                         { id: 'marqueAppareilD', label: 'Marque' },
//                         { id: 'modelAppareilD', label: 'Modèle' },
//                         { id: 'codeProduitD', label: 'Code' },
//                         { id: 'lppD', label: 'LPP' },
//                         { id: 'quantiteD', label: 'Quantité', type: 'number' },
//                         { id: 'montantAppareilD', label: 'Montant HT' }
//                       ].map(({ id, label, type }) => (
//                         <FormGroup key={id} style={formGroupStyle}>
//                           <Label for={id} style={labelStyle}>{label}</Label>
//                           <Input
//                             type={type || 'text'}
//                             name={id}
//                             id={id}
//                             value={client.id}
//                             onChange={handleInputChange}
//                             style={{ fontSize: '12px', padding: '6px' }}
//                             disabled={!isEditable} // Désactive le champ si isEditable est false
//                           />
//                         </FormGroup>
//                       ))}
//                     </div>
//                   </div>
//                   <Button type="submit" color="primary" style={{ width: '100%', maxWidth: '300px', margin: '0 auto', display: 'block' }}>Enregistrer Appareillage</Button>
//                 </Form>
//               </TabPane> */}
//               <TabPane tabId="4">
//               <Button
//                           type="submit"
//                           color="primary"
//                           onClick={saveAppareillage}
//                           style={{
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             padding: '10px 20px',
//                             borderRadius: '8px',
//                             transition: 'all 0.3s ease',
//                             background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
//                             border: 'none',
//                             color: 'white',
//                             boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//                             fontSize: '16px',
//                           }}
//                           onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
//                           onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
//                         >
//                           <FontAwesomeIcon icon={faSave} style={{ marginRight: '8px' }} />
//                           Enregistrer
//                         </Button>
//                 <div style={styles.container}>
//                   {/* Carte Oreille Gauche */}
//                   <div
//                     style={styles.card}
//                     onClick={() => toggleFlip('left')}
//                     onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
//                     onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
//                   >
//                     <div
//                       style={{
//                         ...styles.cardInner,
//                         transform: isFlipped.left ? 'rotateY(180deg)' : 'rotateY(0)',
//                       }}
//                     >
//                       {/* Face avant */}
//                       <div style={styles.cardFace}>
//                         <h2 style={{...styles.detailsTitle, marginBottom: '40px'}}>Oreille Gauche</h2>
//                         <img src={EarLeft} alt="Illustration de l'oreille gauche" style={styles.image} />
//                         <p style={{ fontSize: '16px' }}>Marque : {client.marqueAppareilG}</p>
//                         <p style={{ fontSize: '16px' }}>Modèle : {client.modelAppareilG}</p>
//                         <button
//                           style={styles.button}
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             toggleFlip('left');
//                           }}
//                           onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
//                           onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
//                         >
//                           {isFlipped.left ? 'Retourner' : "Afficher plus d'infos"}
//                         </button>
//                       </div>
//                       {/* Face arrière */}
//                       <div style={{ ...styles.cardFace, ...styles.cardBack }}>
//                         <h2>Oreille Gauche - Détails</h2>
//                         <button
//                           type="button"
//                           onClick={handleEditToggle}
//                           style={{
//                             ...styles.pencilButton,
//                             ...(hovered ? styles.pencilButtonHover : {}),
//                           }}
//                           onMouseEnter={() => setHovered(true)}
//                           onMouseLeave={() => setHovered(false)}
//                         >
//                           <FontAwesomeIcon icon={faPencilAlt} />
//                         </button>
//                         {isEditable ? (
//                           <>
//                           {/* <input
//                               type="text"
//                               name="marqueAppareilG"
//                               value={client.marqueAppareilG || ''}
//                               onChange={handleInputChange}
//                               onClick={(e) => e.stopPropagation()}
//                               placeholder="Marque"
//                             />
//                             <input
//                               type="text"
//                               name="modelAppareilG"
//                               value={client.modelAppareilG || ''}
//                               onChange={handleInputChange}
//                               onClick={(e) => e.stopPropagation()}
//                               placeholder="Model"
//                             /> */}
//                             {/* Liste déroulante pour la marque */}
//                             <Input
//       type="select"
//       name="marqueAppareilG"
//       id="marqueAppareilG"
//       value={client.marqueAppareilG || ''}
//       onChange={handleMarqueChange} // Utilisez `handleMarqueChange` ici
//       onClick={(e) => e.stopPropagation()}
//       style={{ width: '200px', color: 'black' }}
//     >
//       <option value="">Sélectionner une marque</option>
//       {marques.map((marque, index) => (
//         <option key={index} value={marque}>
//           {marque}
//         </option>
//       ))}
//     </Input>

//           {/* Liste déroulante pour le modèle */}
//           <Input
//       type="select"
//       name="modelAppareilG"
//       id="modelAppareilG"
//       value={client.modelAppareilG || ''}
//       onChange={handleModelChange}
//       onClick={(e) => e.stopPropagation()}
//       disabled={!client.marqueAppareilG} // Désactiver si aucune marque n'est sélectionnée
//       style={{ width: '200px', color: 'black' }}
//     >
//       <option value="">Sélectionner un modèle</option>
//       {filteredModelsLeft.map((model, index) => (
//         <option key={index} value={model}>
//           {model}
//         </option>
//       ))}
//     </Input>

//     <Input
//   type="select"
//   name="codeProduitG"
//   value={client.codeProduitG || ''}
//   onChange={handleInputChange}
//   onClick={(e) => e.stopPropagation()}
//   disabled={!client.modelAppareilG}
//   style={{ width: '200px', color: 'black' }}
// >
//   <option value="">Sélectionner un code produit</option>
//   {models.filter(item => item.model === client.modelAppareilG)
//     .map((item, index) => (
//       <option key={index} value={item.code}>{item.code}</option>
//     ))}
// </Input>

// <Input
//   type="select"
//   name="lppG"
//   value={client.lppG || ''}
//   onChange={handleInputChange}
//   onClick={(e) => e.stopPropagation()}
//   disabled={!client.modelAppareilG}
//   style={{ width: '200px', color: 'black' }}
// >
//   <option value="">Sélectionner un LPP</option>
//   {models.filter(item => item.model === client.modelAppareilG)
//     .map((item, index) => (
//       <option key={index} value={item.lpp}>{item.lpp}</option>
//     ))}
// </Input>

//                             <input
//                               type="number"
//                               name="quantityG"
//                               value={client.quantityG || ''}
//                               onChange={handleInputChange}
//                               onClick={(e) => e.stopPropagation()}
//                               placeholder="Quantité"
//                               style={{ width: '200px', color: 'black' }}
//                             />
//                             <input
//                               type="number"
//                               name="montantAppareilG"
//                               value={client.montantAppareilG || ''}
//                               onChange={handleInputChange}
//                               onClick={(e) => e.stopPropagation()}
//                               placeholder="Montant HT"
//                               style={{ width: '200px', color: 'black' }}
//                             />
//                             <button onClick={handleEditToggle} style={styles.saveButton}>
//                               <FontAwesomeIcon icon={faSave} /> Enregistrer
//                             </button>
//                           </>
//                         ) : (
//                           <>
//                           <p style={{ fontSize: '16px' }}><strong>Marque : </strong>{client.marqueAppareilG}</p>
//                           <p style={{ fontSize: '16px' }}><strong>Model : </strong>{client.modelAppareilG}</p>
//                             <p style={{ fontSize: '16px' }}><strong>Code : </strong>{client.codeProduitG}</p>
//                             <p style={{ fontSize: '16px' }}><strong>LPP : </strong> {client.lppG}</p>
//                             <p style={{ fontSize: '16px' }}><strong>Quantité : </strong> {client.quantityG}</p>
//                             <p style={{ fontSize: '16px' }}><strong>Montant HT : </strong> {client.montantAppareilG}</p>
//                           </>
//                         )}
//                         <button
//                           style={styles.button}
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             toggleFlip('left');
//                           }}
//                           onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
//                           onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
//                         >
//                           <FontAwesomeIcon icon={faTimes} /> Retourner
//                         </button>
//                       </div>
//                     </div>
//                   </div>

//                   <div style={styles.linkContainer}>
//                     <img src={LinkIcon} alt="Lien entre les cartes" style={styles.linkIcon} />
//                   </div>

//                   {/* Carte Oreille Droite */}
//                   <div
//                     style={styles.card}
//                     onClick={() => toggleFlip('right')}
//                     onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
//                     onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
//                   >
//                     <div
//                       style={{
//                         ...styles.cardInner,
//                         transform: isFlipped.right ? 'rotateY(180deg)' : 'rotateY(0)',
//                       }}
//                     >
//                       {/* Face avant */}
//                       <div style={styles.cardFace}>
//                         <h2 style={{...styles.detailsTitle, marginBottom: '40px'}} >Oreille Droite</h2>
//                         <img src={EarRight} alt="Illustration de l'oreille Droite" style={styles.image} />
//                         <p style={{ fontSize: '16px' }}>Marque : {client.marqueAppareilD}</p>
//                         <p style={{ fontSize: '16px' }}>Modèle : {client.modelAppareilD}</p>
//                         <button
//                           style={styles.button}
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             toggleFlip('right');
//                           }}
//                           onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
//                           onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
//                         >
//                           {isFlipped.right ? 'Retourner' : "Afficher plus d'infos"}
//                         </button>
//                       </div>
//                       {/* Face arrière */}
//                       <div style={{ ...styles.cardFace, ...styles.cardBack }}>
//                         <h2>Oreille Droite - Détails</h2>
//                         <button
//                           type="button"
//                           onClick={handleEditToggle}
//                           style={{
//                             ...styles.pencilButton,
//                             ...(hovered ? styles.pencilButtonHover : {}),
//                           }}
//                           onMouseEnter={() => setHovered(true)}
//                           onMouseLeave={() => setHovered(false)}
//                         >
//                           <FontAwesomeIcon icon={faPencilAlt} />
//                         </button>
//                         {isEditable ? (
//                           <>
//                           {/* <input
//                               type="text"
//                               name="marqueAppareilD"
//                               value={client.marqueAppareilD || ''}
//                               onChange={handleInputChange}
//                               onClick={(e) => e.stopPropagation()}
//                               placeholder="Marque"
//                             />
//                             <input
//                               type="text"
//                               name="modelAppareilD"
//                               value={client.modelAppareilD || ''}
//                               onChange={handleInputChange}
//                               onClick={(e) => e.stopPropagation()}
//                               placeholder="Model"
//                             /> */}
//                             {/* Liste déroulante pour la marque */}
//                             <Input
//       type="select"
//       name="marqueAppareilD"
//       id="marqueAppareilD"
//       value={client.marqueAppareilD || ''}
//       onChange={handleMarqueChange} // Utilisez `handleMarqueChange` ici
//       onClick={(e) => e.stopPropagation()}
//       style={{ width: '200px', color: 'black' }}
//     >
//       <option value="">Sélectionner une marque</option>
//       {marques.map((marque, index) => (
//         <option key={index} value={marque}>
//           {marque}
//         </option>
//       ))}
//     </Input>

//           {/* Liste déroulante pour le modèle */}
//           <Input
//       type="select"
//       name="modelAppareilD"
//       id="modelAppareilD"
//       value={client.modelAppareilD || ''}
//       onChange={handleModelChange}
//       onClick={(e) => e.stopPropagation()}
//       disabled={!client.marqueAppareilD} // Désactiver si aucune marque n'est sélectionnée
//       style={{ width: '200px', color: 'black' }}
//     >
//       <option value="">Sélectionner un modèle</option>
//       {filteredModelsRight.map((model, index) => (
//         <option key={index} value={model}>
//           {model}
//         </option>
//       ))}
//     </Input>
//     <Input
//   type="select"
//   name="codeProduitD"
//   value={client.codeProduitD || ''}
//   onChange={handleInputChange}
//   onClick={(e) => e.stopPropagation()}
//   disabled={!client.modelAppareilD}
//   style={{ width: '200px', color: 'black' }}
// >
//   <option value="">Sélectionner un code produit</option>
//   {models.filter(item => item.model === client.modelAppareilD)
//     .map((item, index) => (
//       <option key={index} value={item.code}>{item.code}</option>
//     ))}
// </Input>
// <Input
//   type="select"
//   name="lppD"
//   value={client.lppD || ''}
//   onChange={handleInputChange}
//   onClick={(e) => e.stopPropagation()}
//   disabled={!client.modelAppareilD}
//   style={{ width: '200px', color: 'black' }}
// >
//   <option value="">Sélectionner un LPP</option>
//   {models.filter(item => item.model === client.modelAppareilD)
//     .map((item, index) => (
//       <option key={index} value={item.lpp}>{item.lpp}</option>
//     ))}
// </Input>

//                             <input
//                               type="number"
//                               name="quantityD"
//                               value={client.quantityD || ''}
//                               onChange={handleInputChange}
//                               onClick={(e) => e.stopPropagation()}
//                               placeholder="Quantité"
//                               style={{ width: '200px', color: 'black' }}
//                             />
//                             <input
//                               type="number"
//                               name="montantAppareilD"
//                               value={client.montantAppareilD || ''}
//                               onChange={handleInputChange}
//                               onClick={(e) => e.stopPropagation()}
//                               placeholder="Montant HT"
//                               style={{ width: '200px', color: 'black' }}
//                             />
//                             <button onClick={handleEditToggle} style={styles.saveButton}>
//                               <FontAwesomeIcon icon={faSave} /> Enregistrer
//                             </button>
//                           </>
//                         ) : (
//                           <>
//                           <p style={{ fontSize: '16px' }}><strong>Marque : </strong>{client.marqueAppareilD}</p>
//                           <p style={{ fontSize: '16px' }}><strong>Model : </strong>{client.modelAppareilD}</p>
//                             <p style={{ fontSize: '16px' }}><strong>Code : </strong>{client.codeProduitD}</p>
//                             <p style={{ fontSize: '16px' }}><strong>LPP : </strong> {client.lppD}</p>
//                             <p style={{ fontSize: '16px' }}><strong>Quantité : </strong>  {client.quantityD}</p>
//                             <p style={{ fontSize: '16px' }}><strong>Montant HT : </strong>{client.montantAppareilD}</p>
//                           </>
//                         )}
//                         <button
//                           style={styles.button}
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             toggleFlip('right');
//                           }}
//                           onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
//                           onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
//                         >
//                           <FontAwesomeIcon icon={faTimes} /> Retourner
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </TabPane>

//               {/* <TabPane tabId="5">

//                 <Container className="mt-5">
//                   <Row>
//                     <Col>
//                       <Card className="shadow" style={{ borderRadius: '12px', color: 'white' }}>
//                         <CardHeader className="d-flex justify-content-between align-items-center" style={{ backgroundColor: 'transparent', borderBottom: 'none' }}>
//                           <h3 className="mb-0">Historique des RDV du Client</h3>
//                         </CardHeader>
//                         <CardBody>
//                           <ListGroup flush>
//                             {historique.map((item, index) => (
//                               <ListGroupItem key={index} style={{ backgroundColor: 'transparent', border: 'none', color: 'white', marginBottom: '10px', padding: '15px', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)' }}>
//                                 <Row className="align-items-center">
//                                   <Col md="3">
//                                     <h5 className="mb-0">{item.date}</h5>
//                                   </Col>
//                                   <Col md="6">
//                                     <h6 className="mb-0">{item.action}</h6>
//                                     <small>{item.details}</small>
//                                   </Col>
//                                   <Col md="3" className="text-right">
//                                     <Badge color={item.statut === 'Terminé' ? 'success' : 'warning'} pill>
//                                       {item.statut}
//                                     </Badge>
//                                   </Col>
//                                 </Row>
//                               </ListGroupItem>
//                             ))}
//                           </ListGroup>
//                         </CardBody>
//                       </Card>
//                     </Col>
//                   </Row>
//                 </Container>

//               </TabPane> */}
// <TabPane tabId="5">
//   <Container className="mt-5">
//     <Row>
//       <Col>
//         <Card className="shadow" style={{ borderRadius: '12px', color: 'white' }}>
//           <CardHeader className="d-flex justify-content-between align-items-center" style={{ backgroundColor: 'transparent', borderBottom: 'none' }}>
//             <h3 className="mb-0">Historique des RDV du Client</h3>
//           </CardHeader>
//           <CardBody>
//             <ListGroup flush>
//               {historique.map((item, index) => (
//                 <ListGroupItem key={index} style={{ backgroundColor: 'transparent', border: 'none', color: 'white', marginBottom: '10px', padding: '15px', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)' }}>
//                   <Row className="align-items-center">
//                     <Col md="3">
//                       {/* <h5 className="mb-0">{item.date}</h5> */}
//                       <h5 className="mb-0">{new Date(item.date).toLocaleDateString()}</h5>
//                     </Col>
//                     <Col md="6">
//                       <h6 className="mb-0">{item.action}</h6>
//                       <small>{item.details}</small>
//                     </Col>
//                     <Col md="3" className="text-right">
//                       <Badge color={item.statut === 'Terminé' ? 'success' : 'warning'} pill>
//                         {item.statut}
//                       </Badge>
//                     </Col>
//                   </Row>
//                 </ListGroupItem>
//               ))}
//             </ListGroup>
//           </CardBody>
//         </Card>
//       </Col>
//     </Row>
//   </Container>
// </TabPane>


//             </TabContent>

//           </CardBody>
//         </Card>

//       </Container>
//       {showFactureModal && (
//         <Modal isOpen={showFactureModal} toggle={() => setShowFactureModal(!showFactureModal)}>
//           <ModalHeader toggle={() => setShowFactureModal(!showFactureModal)}>Détails de Facturation</ModalHeader>
//           <ModalBody>
//             <FormGroup>
//               <Label>Date de Facturation</Label>
//               <Input
//                 type="date"
//                 name="dateFacturation"
//                 value={client.dateFacturation}
//                 onChange={handleModalInputChange}
//               />
//             </FormGroup>
//             <FormGroup>
//               <Label>Remise</Label>
//               <Input
//                 type="number"
//                 name="remise"
//                 value={client.remise}
//                 onChange={handleModalInputChange}
//               />
//             </FormGroup>
//             <FormGroup>
//               <Label>Remboursement Sécurité Sociale</Label>
//               <Input
//                 type="number"
//                 name="rbstSS"
//                 value={client.rbstSS}
//                 onChange={handleModalInputChange}
//               />
//             </FormGroup>
//             <FormGroup>
//               <Label>Remboursement Complémentaire</Label>
//               <Input
//                 type="number"
//                 name="rbstCompl"
//                 value={client.rbstCompl}
//                 onChange={handleModalInputChange}
//               />
//             </FormGroup>
//           </ModalBody>
//           <ModalFooter>
//             <Button color="primary" onClick={handleSubmitFacture}>Sauvegarder</Button>
//             <Button color="secondary" onClick={() => setShowFactureModal(false)}>Annuler</Button>
//           </ModalFooter>
//         </Modal>
//       )}
//     </div>
//   );
// }


// export default NouveauClient;
