// import React, { createContext, useState, useContext, useEffect } from 'react';
// import axios from 'axios';

// const StatusContext = createContext();

// export const useStatus = () => useContext(StatusContext);

// export const StatusProvider = ({ children }) => {
//   const [statusCounts, setStatusCounts] = useState({});

//   useEffect(() => {
//     const fetchStatusCounts = async () => {
//       const token = localStorage.getItem('token');
      
//       if (!token) {
//         console.error('❌ Aucun token trouvé, requête annulée.');
//         return;
//       }
//       console.log("🔍 Tentative de fetch avec token :", token);
//       try {
//         const response = await axios.get('https://app.vtalys.fr/api/clients/count-by-status', {
//           headers: { Authorization: `Bearer ${token}` }
//         });
//         console.log("✅ Réponse reçue de count-by-status :", response.data);
//         setStatusCounts(response.data);
//       } catch (error) {
//         console.error('Impossible de faire le compte:', error);
//       }
//     };

//     fetchStatusCounts();
//   }, []);

//   return (
//     <StatusContext.Provider value={statusCounts}>
//       {children}
//     </StatusContext.Provider>
//   );
// };
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const StatusContext = createContext();

export const useStatus = () => useContext(StatusContext);

export const StatusProvider = ({ children }) => {
  const [statusCounts, setStatusCounts] = useState({});
  const [counts, setCounts] = useState({
    totalClients: 0,
    totalAppareilles: 0,
    totalFactures: 0
  });

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('❌ Aucun token trouvé, requête annulée.');
      return;
    }
    
    console.log("🔍 Tentative de fetch avec token :", token);

    const fetchData = async () => {
      try {
        // 🟢 Récupérer les comptes par statut
        const statusResponse = await axios.get('https://app.vtalys.fr/api/clients/count-by-status', {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log("✅ Réponse reçue de count-by-status :", statusResponse.data);
        setStatusCounts(statusResponse.data);

        // 🟢 Récupérer les comptes globaux
        const countResponse = await axios.get('https://app.vtalys.fr/api/clients/counts', {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log("✅ Réponse reçue de clients/counts :", countResponse.data);
        setCounts(countResponse.data);
      } catch (error) {
        console.error('❌ Erreur lors de la récupération des comptes:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <StatusContext.Provider value={{ statusCounts, counts }}>
      {children}
    </StatusContext.Provider>
  );
};
