import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import HeaderA from "../../components/Headers/HeaderA";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Table,
    Button
} from "reactstrap";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

const UsersTableSuperAdmin = () => {
    console.log("🟢 UsersTableSuperAdmin monté");
    const [users, setUsers] = useState([]);
    const history = useHistory(); // Pour la redirection
    const [currentCenter, setCurrentCenter] = useState(null);

    useEffect(() => {
        console.log("🟢 useEffect démarré");
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("❌ Aucun token trouvé, redirection vers login");
            history.push("/login");
            return;
        }

        try {
            const decoded = jwtDecode(token);
            console.log("🔑 Token décodé:", decoded);

            if (!decoded || !decoded.role) {
                console.error("❌ Token invalide, redirection vers login");
                history.push("/login");
                return;
            }

            if (decoded.role !== "superAdmin") {
                console.warn("⛔ Accès refusé, redirection vers /admin/index");
                history.push("/admin/index");
                return;
            }
            
            if (!decoded.currentCenter) {
                console.error("❌ Aucun centerId détecté ! Redirection bloquée.");
                return;
            }

            setCurrentCenter(decoded.currentCenter);
            console.log("✅ Centre actuel défini :", decoded.currentCenter);

            const fetchUsers = async () => {
                const token = localStorage.getItem("token");
                console.log("🔍 Token utilisé pour l'API:", token);
            
                if (!token) {
                    console.error("❌ Aucun token trouvé !");
                    return;
                }
            
                try {
                    const response = await axios.get("https://app.vtalys.fr/api/admin/users/superAdmin", {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    console.log("📥 Réponse API fetchUsers:", response.data);
                    if (response.data.success) {
                        setUsers(response.data.users);
                    }
                } catch (error) {
                    console.error("❌ Erreur lors de la récupération des utilisateurs:", error.response?.data || error);
                }
            };
                       
            fetchUsers();
        } catch (error) {
            console.error("❌ Erreur lors du décodage du token:", error);
            history.push("/login");
        }
    }, [history]);

    return (
        <>
            <HeaderA />
            <Container>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Utilisateurs du centre actuel</h3>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Noms</th>
                                        <th scope="col">E-mail</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Statut</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user => (
                                        <tr key={user._id}>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.role}</td>
                                            <td>
                                                {user.accountConfirmation ? (
                                                    <span className="badge badge-success">Actif</span>
                                                ) : (
                                                    <span className="badge badge-danger">Bloqué</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default UsersTableSuperAdmin;