// src/components/Fullcalendar.js
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import 'moment/locale/fr';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  Button, Card, CardHeader, CardBody, Container, Row, Col,
  Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label
} from "reactstrap";
import HeaderA from "components/Headers/HeaderA.js";
import axios from 'axios';
import Select from 'react-select';

moment.tz.setDefault("Europe/Paris");


const localizer = momentLocalizer(moment);
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Récupère le token JWT depuis localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Ajoute le token dans les en-têtes
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



function Fullcalendar() {
  const [ events, setEvents] = useState([]);
  const [ setGoogleEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [clients, setClients] = useState([]);

  const [selectedEvent, setSelectedEvent] = useState({
    type: '',
    color: '',
    start: new Date(),
    end: new Date(),
    clientId: '',
    note: '',
  });
  const [selectedEventId, setSelectedEventId] = useState(null);
  const minTime = moment().startOf('day').add(7, 'hours').toDate();
  const maxTime = moment().startOf('day').add(22, 'hours').toDate();

  // Définition des couleurs associées aux types d'événements
const colors = {
  "1er rdv - bilan auditif": "#4e73df",
  "rdv livraison": "#28a745",
  "1er essai": "#dc3545",
  "2nd essai": "#6c757d",
  "rdv facturation": "#17a2b8",
  "appareillage": "#ff0000",
  "facturation": "#00ff00",
  "rdv teleconsultation": "#ffc107",
  "rdv devis": "#007bff"
};

  useEffect(() => {
    const fetchAllEvents = async () => {
      const localEvents = await fetchEvents();
      //const googleEventsData = await fetchGoogleEvents();
      // Combiner les événements locaux et Google
      console.log("📆 Événements locaux:", localEvents);
    //console.log("📆 Événements Google:", googleEventsData);
      //setAllEvents([...localEvents, ...googleEventsData]);
      setAllEvents([...localEvents]);
    };

    fetchAllEvents();
    fetchClients();
  }, []);

  const fetchEvents = async () => {
    try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/events", {
            headers: { Authorization: `Bearer ${token}` },
        });

        console.log("📅 Événements avant affichage:", response.data);

        if (!Array.isArray(response.data)) {
            console.error("❌ Erreur: Les données retournées ne sont pas un tableau !");
            return [];
        }

        const formattedEvents = response.data.map(event => ({
            ...event,
            // start: new Date(event.start),
            // end: new Date(event.end),
            start: moment.utc(event.start).tz("Europe/Paris").toDate(),
            end: moment.utc(event.end).tz("Europe/Paris").toDate(),
        }));

        console.log("📅 Événements après formatage:", formattedEvents);
        setEvents(formattedEvents);
        return formattedEvents;  // 🛠 Assure-toi de bien retourner un tableau

    } catch (error) {
        console.error('❌ Erreur lors de la récupération des événements:', error);
        toast.error('Erreur lors de la récupération des événements.');
        return [];  // 🛠 Retourner un tableau vide en cas d'erreur
    }
};



  // const fetchGoogleEvents = async () => {
  //   try {
  //     const response = await axios.get('/api/events/google');  // Nouvelle route backend pour récupérer les événements Google
  //     const formattedEvents = formatEventDates(response.data);
  //     setGoogleEvents(formattedEvents);
  //     return formattedEvents;
  //   } catch (error) {
  //     //console.error('Error fetching Google events:', error);
  //     //toast.error('Erreur lors de la récupération des événements Google.');
  //     return [];
  //   }
  // };

  const fetchClients = async () => {
    try {
      const response = await axios.get('/api/clients');
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
      toast.error('Erreur lors de la récupération des clients.');
    }
  };

    const formatEventDates = (events) => {
      if (!Array.isArray(events)) {
          console.error('Les événements ne sont pas sous forme de tableau:', events);
          return [];
      }
  
      return events.map(event => {
          console.log("📅 Avant conversion:", event.start, typeof event.start);
  
          // Forcer la conversion en UTC et empêcher le double décalage
          let start = new Date(event.start); // Garantit que React-Big-Calendar reçoit un objet Date en UTC
        let end = new Date(event.end);

  
          if (isNaN(start.getTime()) || isNaN(end.getTime())) {
              console.error("❌ Date invalide détectée:", event.start, event.end);
              return null;
          }
  
          console.log("🕒 Après conversion (Sans ajout de fuseau horaire) :", start, end);
  
          return {
              ...event,
              start, 
              end,
              color: event.color || '#007bff',
          };
      }).filter(event => event !== null);
  };
  
  

  const toggleModal = () => {
    setModal(!modal);
    if (modal) {
      resetSelectedEvent();
    }
  };

  const resetSelectedEvent = () => {
    setSelectedEvent({
      type: '',
      color: '',
      start: new Date(),
      end: new Date(),
      clientId: '',
      note: '',
    });
  };

  const handleSelectSlot = ({ start, end , action}) => {
    let adjustedStart = start;
    let adjustedEnd = end;
  
    if (start.getTime() === end.getTime()) {
      // Clic simple → on crée un créneau de 1h à 9h
      adjustedStart = moment(start).hour(9).minute(0).second(0).toDate();
      adjustedEnd = moment(adjustedStart).add(1, 'hours').toDate();
    }
  

    // Convertir UTC en heure locale (Europe/Paris)
    const localStart = moment(adjustedStart).tz("Europe/Paris").toDate();
    const localEnd = moment(adjustedEnd).tz("Europe/Paris").toDate();

    console.log("🕒 Date locale après conversion :", localStart, localEnd);

    setSelectedEvent({
      type: '',
      color: '',
      start: localStart,
      end: localEnd,
      clientId: '',
      note: '',
    });
    toggleModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start' || name === 'end') {
      const adjustedDate = moment(value).toDate();
      setSelectedEvent(prevState => ({
        ...prevState,
        [name]: adjustedDate,
      }));
    } else if (name === 'type') {
      const color = e.target[e.target.selectedIndex].getAttribute('data-color');
      setSelectedEvent(prevState => ({
        ...prevState,
        type: value,
        color: color,
      }));
    } else {
      setSelectedEvent(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleEventSubmit = () => {
    if (!selectedEvent.clientId) {
      toast.error("Veuillez sélectionner un client pour l'événement.");
      return;
    }

    
    // Récupérer le centerId depuis localStorage (et s'assurer que ce n'est pas une string JSONifiée)
    const storedCenter = localStorage.getItem("currentCenter");
    let currentCenter;

    console.log("🔍 Center stocké dans le LocalStorage:", localStorage.getItem("currentCenter"));


    try {
      currentCenter = JSON.parse(storedCenter); // S'assurer que c'est bien un objet
      if (typeof currentCenter === "string") {
        currentCenter = { _id: currentCenter }; // Si c'est une simple string, la convertir en objet
      }
    } catch (error) {
      console.error("❌ Erreur lors de la récupération du centerId :", error);
      toast.error("Erreur lors de la récupération du centerId.");
      return;
    }
    if (!currentCenter) {
      console.error("❌ Aucun centerId trouvé dans localStorage !");
      toast.error("Impossible de créer un événement sans centerId.");
      return;
    }
  
    // Normaliser le type de l'événement pour s'assurer qu'on récupère la bonne couleur
    const normalizedType = selectedEvent.type.trim().toLowerCase();
    const eventColor = selectedEvent.color || colors[normalizedType] || "#007bff";

    console.log("🎨 Vérification avant envoi :");
    console.log("- Type d'événement :", selectedEvent.type);
    console.log("- Type normalisé :", normalizedType);
    console.log("- Couleur trouvée :", eventColor);
    

    const eventData = {
      ...selectedEvent,
      start: moment(selectedEvent.start).utc().toISOString(), // Conversion forcée en UTC
    end: moment(selectedEvent.end).utc().toISOString(),
      color: eventColor,
      centerId: currentCenter._id || null,
  };

  if (!eventData.centerId) {
    console.error("❌ Avertissement : centerId non défini !");
    toast.error("Erreur : Impossible de créer l'événement sans centerId.");
    return;
  }
  
   console.log("📌 Données envoyées au backend :", eventData);
  
    const request = selectedEvent._id
      ? axios.put(`/api/events/${selectedEvent._id}`, eventData)
      : axios.post('/api/events', eventData);
  
      request
      .then(response => {
        console.log("Réponse de l'API :", response.data); // DEBUG
        
        // Vérification des données reçues
        if (!response.data || !response.data.start || !response.data.end) {
            console.error("Données invalides reçues :", response.data);
            toast.error("Erreur : l'événement n'est pas valide.");
            return;
        }
        const client = clients.find(client => client._id === response.data.clientId);
        const formattedEvent = {
            ...response.data,
            start: new Date(response.data.start),
            end: new Date(response.data.end),
            clientId: client ? { _id: client._id, nom: client.nom, prenom: client.prenom } : response.data.clientId
        };
        console.log("📆 Événement formaté après récupération :", formattedEvent);
        if (selectedEvent._id) {
          setAllEvents(prevEvents => prevEvents.map(ev => ev._id === response.data._id ? formattedEvent : ev));
        } else {
          setAllEvents(prevEvents => [...prevEvents, formattedEvent]); // Ajoute l'événement directement
        }

        toast.success(selectedEvent._id ? "Événement mis à jour avec succès" : "Événement ajouté avec succès");
        toggleModal();
      })
      .catch(error => {
        console.error("Erreur lors de l'enregistrement de l'événement", error);
        toast.error("Erreur lors de l'enregistrement de l'événement");
      });
  };
  
  const handleEventClick = (event) => {
    if (event.isGoogleEvent) {
      // Informer l'utilisateur qu'il ne peut pas modifier les événements Google
      toast.info("Vous ne pouvez pas modifier les événements Google depuis cette application.");
    } else {
      setSelectedEvent({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
        clientId: event.clientId?._id || event.clientId || '', 
      });
      toggleModal();
    }
  };
  

  const toggleDeleteModal = (eventId = null) => {
    setDeleteModal(!deleteModal);
    setSelectedEventId(eventId);
  };

  const handleDeleteEvent = () => {
    if (!selectedEventId) {
      toast.error("Aucun événement sélectionné pour la suppression.");
      return;
    }
  
    axios.delete(`/api/events/${selectedEventId}`)
      .then(response => {
        setAllEvents(prevEvents => prevEvents.filter(event => event._id !== selectedEventId)); // Suppression immédiate
        toast.success("Événement supprimé avec succès");
        toggleDeleteModal();
        toggleModal();
      })
      .catch(error => {
        console.error("Erreur lors de la suppression de l'événement", error);
        toast.error("Erreur lors de la suppression de l'événement");
      });
  };
  
  
  return (
    <>
      <HeaderA />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h3>Calendrier</h3>
                {/* <Button onClick={handleGoogleLoginClick}>Se connecter à Google</Button> */}
              </CardHeader>
              <CardBody>
              <div style={{ position: 'relative', zIndex: 0 }}>
                <Calendar
                  localizer={localizer}
                  //events={[...events, ...googleEvents]}
                  events={allEvents}
                  startAccessor="start"
                  endAccessor="end"
                  //titleAccessor="type"
                  titleAccessor={(event) => {
                    if (!event) return "Événement inconnu";  // 🔍 Vérification si event est null/undefined
                    if (!event.clientId) return `${event.type}`; // 🔍 Vérification si clientId est null
                    return `${event.clientId.nom || "Client inconnu"} ${event.clientId.prenom || ""}\n${event.type}`;
                }}
                              
                  defaultView="week"
                  style={{ height: '75vh', width: '100%' }}
                  culture="fr"
                  selectable={true}
                  step={30}
  timeslots={2}
                  onView={(view) => console.log("Vue actuelle :", view)}
                  onSelectSlot={handleSelectSlot}
                  onSelectEvent={handleEventClick}
                  views={['month', 'week', 'day', 'agenda']}
                  min={minTime}
                  max={maxTime}
                  eventPropGetter={(event) => ({
                    style: { backgroundColor: event.color },
                  })}
                  showMultiDayTimes
                  messages={{
                    next: "Suivant",
                    previous: "Précédent",
                    today: "Aujourd'hui",
                    month: "Mois",
                    week: "Semaine",
                    day: "Jour",
                    agenda: "Agenda",
                    date: "Date",
                    time: "Heure",
                    event: "Événement",
                    noEventsInRange: "Aucun événement à afficher",
                    showMore: total => `+ ${total} événement(s) supplémentaire(s)`,
                  }}
                />
                </div>
                <Modal isOpen={modal} toggle={toggleModal}>
                  <ModalHeader toggle={toggleModal}>
                    {selectedEvent._id ? 'Modifier l\'événement' : 'Ajouter un événement'}
                  </ModalHeader>
                  <ModalBody>
                  
                    <FormGroup>
  <Label for="clientSelect">Client <span style={{ color: 'red' }}>*</span></Label>
  <Select
    id="clientSelect"
    name="clientId"
    // value={clients.find(client => client._id === selectedEvent.clientId) || ''}
    // onChange={(selectedOption) => handleChange({ target: { name: 'clientId', value: selectedOption.value } })}
    value={
      clients
        .map(client => ({ value: client._id, label: `${client.nom} ${client.prenom}` }))
        .find(option => option.value === selectedEvent.clientId) || ''
    }
    onChange={(selectedOption) =>
      handleChange({ target: { name: 'clientId', value: selectedOption ? selectedOption.value : '' } })
    }
    options={clients.map(client => ({
      value: client._id,
      label: `${client.nom} ${client.prenom}`
    }))}
    placeholder="Choisissez un client"
    isClearable
  />
</FormGroup>
                    <FormGroup>
                      <Label for="eventType">Type d'événement</Label>
                      <Input
                        type="select"
                        name="type"
                        id="eventType"
                        value={selectedEvent.type}
                        onChange={handleChange}
                      >
                        <option value="" disabled>Choisissez un type</option>
                        <option data-color="#4e73df" value="1er RDV - Bilan auditif">1er RDV - Bilan auditif</option>
                        <option data-color="#ffc107" value="Rdv Téléconsultation">Rdv Teleconsultation/ORL</option>
                        <option data-color="#007bff" value="Rdv devis">RDV Devis</option>
                        <option data-color="#28a745" value="Rdv livraison">RDV Livraison</option>
                        <option data-color="#dc3545" value="Rdv 1er essai">1er essai</option>
                        <option data-color="#6c757d" value="Rdv 2eme essai">2nd essai</option>
                        <option data-color="#17a2b8" value="Rdv facturation">RDV Facturation</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="eventStart">Début</Label>
                      <Input
                        type="datetime-local"
                        name="start"
                        id="eventStart"
                        value={moment.utc(selectedEvent.start).tz("Europe/Paris").format('YYYY-MM-DDTHH:mm')}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="eventEnd">Fin</Label>
                      <Input
                        type="datetime-local"
                        name="end"
                        id="eventEnd"
                        value={moment(selectedEvent.end).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="note">Note</Label>
                      <Input
                        type="textarea"
                        name="note"
                        id="note"
                        placeholder="Ajouter une note ici..."
                        value={selectedEvent.note}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={handleEventSubmit}>
                      {selectedEvent._id ? 'Mettre à jour' : 'Ajouter'}
                    </Button>
                    {selectedEvent._id && (
                      <Button color="danger" onClick={() => toggleDeleteModal(selectedEvent._id)}>
                        Supprimer
                      </Button>
                    )}
                    <Button color="secondary" onClick={toggleModal}>Annuler</Button>
                  </ModalFooter>
                </Modal>
                <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                  <ModalHeader toggle={toggleDeleteModal}>Confirmer la suppression</ModalHeader>
                  <ModalBody>
                    Êtes-vous sûr de vouloir supprimer cet événement ?
                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" onClick={handleDeleteEvent}>Supprimer</Button>
                    <Button color="secondary" onClick={toggleDeleteModal}>Annuler</Button>
                  </ModalFooter>
                </Modal>
                <ToastContainer position="bottom-left" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Fullcalendar;
