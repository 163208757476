// ✅ VERSION FONCTIONNELLE DU COMPOSANT StockPage AVEC CORRECTION centerId

import React, { useState, useEffect } from "react";
import ImageDropzone from './ImageDropzone';
import {
  Button, Card, CardHeader, CardBody, Container, Row, Col,
  Input, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function StockPage({ user }) {
  const [allStock, setAllStock] = useState([]);
  const [filteredStock, setFilteredStock] = useState([]);
  const [selectedMarque, setSelectedMarque] = useState("Toutes");
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [newProduct, setNewProduct] = useState({
    name: "",
    marque: "",
    model: "",
    lpp: "",
    montantHT: "",
    code: 0,
    quantity: 0,
    price: "",
    oreilleGauche: false,
    oreilleDroite: false,
    description: "",
    imageUrl: "",
    image: null
  });

  useEffect(() => {
    const stored = localStorage.getItem("currentCenter");
    console.log("🧪 currentCenter brut:", stored);
    let centerId = stored;
  
    try {
      centerId = JSON.parse(stored);
      if (typeof centerId === "string") centerId = { _id: centerId };
    } catch (e) {
      console.error("Erreur parsing currentCenter:", e);
    }
    console.log("📦 currentCenter final:", centerId);
    if (centerId?._id) {
      loadStockData(centerId._id);
    } else {
      console.error("❌ Aucun centerId trouvé !");
    }
  }, []);
  

  const loadStockData = (centerId) => {
    if (!centerId) {
      console.error("❌ centerId non fourni à loadStockData");
      return;
    }
    fetch(`/api/produits?centerId=${centerId}`)
      .then(res => res.json())
      .then(data => {
        if (!Array.isArray(data)) {
          console.error("❌ Données reçues non valides:", data);
          return;
        }
        setAllStock(data);
        setFilteredStock(data);
      })
      
      .catch(err => console.error("Erreur lors du chargement du stock:", err));
  };

  const filterByMarque = (marque) => {
    setSelectedMarque(marque);
    if (marque === "Toutes") {
      setFilteredStock(allStock);
    } else {
      const filtered = allStock.filter(item => item.marque === marque);
      setFilteredStock(filtered);
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    setIsEditing(false);
    setNewProduct({
      name: "", marque: "", model: "", lpp: "", montantHT: "", code: 0,
      quantity: 0, price: "", oreilleGauche: false, oreilleDroite: false,
      description: "", imageUrl: "", image: null
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setNewProduct(prev => ({ ...prev, [name]: val }));
  };

  const handleImageChange = (e) => {
    setNewProduct(prev => ({ ...prev, image: e.target.files?.[0] || e }));
  };

  const addOrUpdateProduct = () => {
    const stored = localStorage.getItem("currentCenter");
    let centerId = stored;

    try {
      centerId = JSON.parse(stored);
      if (typeof centerId === "string") centerId = { _id: centerId };
    } catch (e) {
      console.error("Erreur parsing currentCenter:", e);
    }

    if (!centerId?._id) {
      toast.error("Impossible d’ajouter le produit : centerId manquant.");
      return;
    }

    const formData = new FormData();
    


    Object.keys(newProduct).forEach(key => {
      if (key === 'image') {
        if (newProduct[key]) {
          formData.append('image', newProduct[key], newProduct[key].name);
        }
      } else  if (key !== 'centerId'){
        formData.append(key, newProduct[key]);
      }
    });
    formData.append('centerId', centerId._id); 
    const url = isEditing ? `/api/produits/update/${newProduct._id}` : '/api/produits/add';

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(res => {
        if (!res.ok) throw new Error('Server responded with ' + res.status);
        return res.json();
      })
      .then(data => {
        const msg = isEditing ? "Produit modifié avec succès." : "Produit ajouté avec succès.";
        setSuccessMessage(msg);
        if (isEditing) {
          setFilteredStock(prev => prev.map(p => p._id === data._id ? data : p));
        } else {
          setFilteredStock(prev => [...prev, data]);
        }
        toggleModal();
      })
      .catch(err => {
        console.error("Erreur lors de l'ajout/mise à jour du produit:", err);
        toast.error("Erreur: " + err.message);
      });
  };

  const editProduct = (item) => {
    setNewProduct({ ...item });
    setModalOpen(true);
    setIsEditing(true);
  };

  const openDeleteModal = (product) => {
    setProductToDelete(product);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setProductToDelete(null);
    setDeleteModalOpen(false);
  };

  const confirmDeleteProduct = () => {
    if (!productToDelete) return;
    fetch(`/api/produits/delete/${productToDelete._id}`, { method: 'DELETE' })
      .then(res => res.json())
      .then(data => {
        setFilteredStock(prev => prev.filter(p => p._id !== productToDelete._id));
        setSuccessMessage("Produit supprimé avec succès.");
        closeDeleteModal();
      })
      .catch(err => {
        console.error("Erreur lors de la suppression:", err);
        setSuccessMessage("Erreur lors de la suppression.");
        closeDeleteModal();
      });
  };
    return (
      <>
      <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false} />
        <div style={{ paddingTop: '20px', background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)' }}>
          <Container className="mt-5" style={{ paddingBottom: '50px' }}>
            <div style={{ textAlign: 'left', marginBottom: '20px' }}>
              {/* <Button color="link" style={{ color: '#FF5A5F', textDecoration: 'none' }}>
                &lt; Retour
              </Button> */}
              <h2 style={{ color: '#FF5A5F', display: 'inline-block', marginLeft: '20px' }}></h2>
            </div>
            <Card className="card-calendar">
              <CardHeader style={{ paddingTop: '20px', background: '#fff', borderBottom: '1px solid #e3e3e3' }}>
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                <Row style={{ marginTop: '10px' }} className="align-items-center py-2">
                  <Col lg="9" md="8" sm="6">
                    <h6 className="h2 text-dark d-inline-block mb-0 mr-1">
                      Stock des Matériels
                    </h6>

                  </Col>
                  <Col lg="3" md="4" sm="6" className="text-lg-right">
                    <Button color="success" onClick={toggleModal} style={{ marginTop: '20px' }}>
                      Ajouter un produit
                    </Button>
                  </Col>
                  <Col lg="6" className="text-lg-left">
                  <FormGroup>
  <Label for="marqueSelect" className="text-dark" style={{ textAlign: 'left' }}>
    Filtrer par Marque:
  </Label>
  <Input
    type="select"
    id="marqueSelect"
    value={selectedMarque}
    onChange={(e) => filterByMarque(e.target.value)}
  >
    <option value="Toutes">Toutes</option>
    {/* Générer dynamiquement les marques disponibles */}
    {allStock.length > 0 &&
      [...new Set(allStock.map(item => item.marque))].map((marque, index) => (
        <option key={index} value={marque}>
          {marque}
        </option>
      ))}
  </Input>
</FormGroup>

                  </Col>
                </Row>
              </CardHeader>


              <CardBody className="p-0" style={{ margin: '20px' }}>
                <Row>
                  {filteredStock.map((item) => (
                    <Col lg="3" md="6" key={item._id}>
                      <Card className="mb-4" style={{ border: 'none', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                        <CardBody style={{ textAlign: 'center', position: 'relative' }}>
                          {/* Afficher le logo du site à la place de l'image du produit */}
                          <img
                            src={require("assets/img/brand/logo.png").default}
                            alt={item.name}
                            style={{ maxHeight: '100px', marginBottom: '15px' }} // Réduction de la taille de l'image
                          />

                          {/* Afficher la quantité dans un cercle centré sur l'image */}
                          <div style={{
                            position: 'absolute',
                            top: '35%',  // Positionné encore plus haut
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            borderRadius: '50%',
                            width: '50px',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontSize: '18px',
                            zIndex: 1
                          }}>
                            {item.quantity}
                          </div>

                          {/* Nom et Marque du produit */}
                          <div style={{ marginTop: '40px' }}> {/* Augmentation de la marge supérieure */}
                            <h5 className="card-title">{item.name}</h5>
                            <p className="card-text">{item.marque}</p>
                            
                            {item.oreilleGauche && <p className="card-text"><strong>Oreille Gauche</strong></p>}
                            {item.oreilleDroite && <p className="card-text"><strong>Oreille Droite</strong></p>}
                            <p className="card-text">{item.model}</p>
                          </div>

                          <p className="card-text"><strong>{item.price}€</strong></p>
                          <Button color="primary" onClick={() => editProduct(item)}>Modifier</Button>
                          <Button color="danger" onClick={() => openDeleteModal(item)}>
  <i className="fa fa-trash"></i>
</Button>

                            {/* Barre diagonale "Épuisé" si la quantité est zéro */}
  {item.quantity === 0 && (
    <div
    style={{
      position: 'absolute',
      top: '15px',
      right: '-25px',
      transform: 'rotate(45deg)',
      backgroundColor: 'red',
      color: 'white',
      padding: '3px 20px',
      fontWeight: 'bold',
      borderRadius: '5px',
      fontSize: '14px',
      zIndex: 2,
    }}
    >
      Épuisé
    </div>
  )}
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </CardBody>


            </Card>
          </Container>
        </div >

        {/* Modal pour ajouter un produit */}
        < Modal isOpen={modalOpen} toggle={toggleModal} >
          <ModalHeader toggle={toggleModal}>
            {isEditing ? "Modifier un produit" : "Ajouter un nouveau produit"}
          </ModalHeader>

          <ModalBody>

            <FormGroup>
              <Label for="productImage">Image du produit</Label>
              {/* <Input
                type="file"
                id="productImage"
                name="imageUrl"
                onChange={handleImageChange}
              /> */}
              <ImageDropzone onFileSelected={(file) => handleImageChange({ target: { name: 'imageUrl', files: [file] } })} />
            </FormGroup>

            <FormGroup>
              <Label for="productName">Nom du produit</Label>
              <Input
                type="text"
                id="productName"
                name="name"
                value={newProduct.name}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="productMarque">Marque</Label>
              <Input
                type="text"
                id="productMarque"
                name="marque"
                value={newProduct.marque}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="productMarque">Model</Label>
              <Input
                type="text"
                id="productMarque"
                name="model"
                value={newProduct.model}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="Code">Code Produit</Label>
              <Input
                type="text"
                id="Code"
                name="code"
                value={newProduct.code}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="CodeLpp">Code LPP</Label>
              <Input
                type="text"
                id="CodeLpp"
                name="lpp"
                value={newProduct.lpp}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="productPrice">Prix HT</Label>
              <Input
                type="text"
                id="productPrice"
                name="price"
                value={newProduct.price}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="productQuantity">Quantité</Label>
              <Input
                type="number"
                id="productQuantity"
                name="quantity"
                value={newProduct.quantity}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="oreilleGauche"
                  checked={newProduct.oreilleGauche}
                  onChange={handleInputChange}
                />{' '}
                Oreille gauche
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="oreilleDroite"
                  checked={newProduct.oreilleDroite}
                  onChange={handleInputChange}
                />{' '}
                Oreille droite
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="productDescription">Descriptif</Label>
              <Input
                type="textarea"
                id="productDescription"
                name="description"
                value={newProduct.description}
                onChange={handleInputChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={addOrUpdateProduct}>
              OK
            </Button>{' '}
            <Button color="secondary" onClick={toggleModal}>
              Annuler
            </Button>
          </ModalFooter>
        </Modal >
        <Modal isOpen={deleteModalOpen} toggle={closeDeleteModal}>
  <ModalHeader toggle={closeDeleteModal}>
    Confirmation de suppression
  </ModalHeader>
  <ModalBody>
    <p>Êtes-vous sûr de vouloir supprimer ce produit ?</p>
    <p><strong>{productToDelete?.name}</strong></p>
  </ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={confirmDeleteProduct}>
      Oui, Supprimer
    </Button>
    <Button color="secondary" onClick={closeDeleteModal}>
      Annuler
    </Button>
  </ModalFooter>
</Modal>

      </>
    );
}

export default StockPage;
