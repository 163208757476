import React, { useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Table
} from 'reactstrap';
import HeaderAb from "components/Headers/HeaderAb.js";

const MesFactures = () => {
    const [factures, setFactures] = useState([
        { id: 1, objet: 'Facture 001', numeroFacture: '2024-001', dateFacture: '2024-09-01', nomClient: 'Client A', totalGeneral: 1200, statut: 'Créée' },
        { id: 2, objet: 'Facture 002', numeroFacture: '2024-002', dateFacture: '2024-09-05', nomClient: 'Client B', totalGeneral: 850, statut: 'Envoyée' }
    ]);
    const [newFacture, setNewFacture] = useState({ objet: '', numeroFacture: '', dateFacture: '', nomClient: '', totalGeneral: '', statut: 'Créée' });
    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    const handleAddFacture = () => {
        const newId = factures.length ? Math.max(...factures.map(f => f.id)) + 1 : 1;
        setFactures([...factures, { ...newFacture, id: newId }]);
        setNewFacture({ objet: '', numeroFacture: '', dateFacture: '', nomClient: '', totalGeneral: '', statut: 'Créée' });
        toggleModal();
    };

    // const handleDeleteFacture = (id) => {
    //     setFactures(factures.filter(facture => facture.id !== id));
    // };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewFacture({ ...newFacture, [name]: value });
    };

    return (
        <>
            <HeaderAb />
            <Container className="mt-5">
                <Row>
                    <Card className="shadow">
                        <CardHeader className="d-flex justify-content-between align-items-center">
                            <h3 className="mb-0">Gestion des Factures</h3>
                            <Button color="primary" onClick={toggleModal}>Ajouter Facture</Button>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Objet</th>
                                        <th>Numéro de Facture</th>
                                        <th>Date de la Facture</th>
                                        <th>Nom du Client</th>
                                        <th>Total Général (€)</th>
                                        <th>Statut</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {factures.map(facture => (
                                        <tr key={facture.id}>
                                            <td>{facture.id}</td>
                                            <td>{facture.objet}</td>
                                            <td>{facture.numeroFacture}</td>
                                            <td>{facture.dateFacture}</td>
                                            <td>{facture.nomClient}</td>
                                            <td>{facture.totalGeneral.toFixed(2)}</td>
                                            <td>{facture.statut}</td>
                                            {/* <td>
                                                <Button color="danger" size="sm" onClick={() => handleDeleteFacture(facture.id)}>Supprimer</Button>
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Row>

                {/* Modal pour ajouter une nouvelle facture */}
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Ajouter une Facture</ModalHeader>
                    <ModalBody>
                        <Input
                            type="text"
                            name="objet"
                            placeholder="Objet"
                            value={newFacture.objet}
                            onChange={handleInputChange}
                            className="mb-3"
                        />
                        <Input
                            type="text"
                            name="numeroFacture"
                            placeholder="Numéro de Facture"
                            value={newFacture.numeroFacture}
                            onChange={handleInputChange}
                            className="mb-3"
                        />
                        <Input
                            type="date"
                            name="dateFacture"
                            placeholder="Date de la Facture"
                            value={newFacture.dateFacture}
                            onChange={handleInputChange}
                            className="mb-3"
                        />
                        <Input
                            type="text"
                            name="nomClient"
                            placeholder="Nom du Client"
                            value={newFacture.nomClient}
                            onChange={handleInputChange}
                            className="mb-3"
                        />
                        <Input
                            type="number"
                            name="totalGeneral"
                            placeholder="Total Général (€)"
                            value={newFacture.totalGeneral}
                            onChange={handleInputChange}
                            className="mb-3"
                        />
                        <Input
                            type="select"
                            name="statut"
                            value={newFacture.statut}
                            onChange={handleInputChange}
                            className="mb-3"
                        >
                            <option value="Créée">Créée</option>
                            <option value="Envoyée">Envoyée</option>
                            <option value="Payée">Payée</option>
                            <option value="Annulée">Annulée</option>
                        </Input>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleAddFacture}>Ajouter</Button>
                        <Button color="secondary" onClick={toggleModal}>Annuler</Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    );
};

export default MesFactures;
