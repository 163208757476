// import React from 'react';
// import {Route, Redirect} from 'react-router-dom';

// const PrivateRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={(props) => (
//         localStorage.getItem("token") && localStorage.getItem("user")
//             ? <Component {...props} />
//             : <Redirect to='/auth/login' />
//     )} />
// );

// export default PrivateRoute;
///vrai
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  //const user = localStorage.getItem("user");
  const user = JSON.parse(localStorage.getItem("user"));

  // Log pour vérifier que PrivateRoute s'exécute
  console.log('Exécution de PrivateRoute');
  console.log('Token:', token);
  console.log('User:', user);

  return (
    <Route
        {...rest}
        render={(props) => {
            if (!token || !user) {
                console.log("🔒 Redirection vers /auth/login");
                return <Redirect to="/auth/login" />;
            }

            if (user.temporaryPassword === true && props.location.pathname !== "/auth/change-password") {
                console.log("🔄 Redirection forcée vers /auth/change-password !");
                return <Redirect to="/auth/change-password" />;
            }

            console.log("✅ Autorisation accordée à :", props.location.pathname);
            return <Component {...props} />;
        }}
    />
);
};

export default PrivateRoute;


