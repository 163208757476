import React, { useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import Header from "components/Headers/HeaderA.js";

const Support = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.error("Votre message a été envoyé avec succès !");
    setFormData({ name: "", email: "", subject: "", message: "" });
  };

  return (
<>
<ToastContainer position="top-right" autoClose={4000} hideProgressBar={false} />
      <Header />
      <Container className="mt--7" fluid>
      <Row className="justify-content-center">
        <Col md="8">
          <Card className="p-4 shadow">
            <CardHeader className="text-center">
              <h2>Support & Assistance</h2>
              <p>Besoin d'aide ? Contactez-nous via ce formulaire.</p>
            </CardHeader>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">Nom</Label>
                <Input type="text" name="name" id="name" placeholder="Votre nom" value={formData.name} onChange={handleChange} required />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input type="email" name="email" id="email" placeholder="Votre email" value={formData.email} onChange={handleChange} required />
              </FormGroup>
              <FormGroup>
                <Label for="subject">Sujet</Label>
                <Input type="text" name="subject" id="subject" placeholder="Sujet" value={formData.subject} onChange={handleChange} required />
              </FormGroup>
              <FormGroup>
                <Label for="message">Message</Label>
                <Input type="textarea" name="message" id="message" placeholder="Votre message" value={formData.message} onChange={handleChange} required rows={5} />
              </FormGroup>
              <Button type="submit" color="primary" className="w-100">Envoyer</Button>
            </Form>
            <CardFooter className="text-center mt-3">
              <small>Nous vous répondrons dans les plus brefs délais.</small>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default Support;
