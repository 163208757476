import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import HeaderA from "../../components/Headers/HeaderA";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Select from "react-select"; 
import { getAll } from "../../network/ApiAxios";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

const UsersTable = () => {
    const [users, setUsers] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [newUser, setNewUser] = useState({ name: "", email: "", role: "employe", centers: [] });
    const [loading, setLoading] = useState(false);
    const history = useHistory(); // Pour la redirection
    const [centers, setCenters] = useState([]);
    const [customCenter, setCustomCenter] = useState("");

    useEffect(() => {
        // Récupérer la liste des centres
        const fetchCenters = async () => {
          try {
            const response = await axios.get("https://app.vtalys.fr/api/admin/centers");
            setCenters(response.data);
          } catch (error) {
            console.error("Erreur lors du chargement des centres :", error);
          }
        };
        fetchCenters();
      }, []);


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            history.push("/login");
            return;
        }

        try {
            const decoded = jwtDecode(token);
            if (decoded.role !== "accountAdmin") {
                history.push("/admin/index"); // Redirige les non-admins vers l'accueil
                return;
            }

            const runAsync = async () => {
                const response = await getAll();
                const { data } = response;
                if (data.success) {
                    setUsers(data.users);
                }
            };
            runAsync();
            
        } catch (error) {
            console.error("Erreur lors du décodage du token :", error);
            history.push("/login");
        }
    }, [history]);

    const openModalForUser = (user) => {
        console.log("📌 Utilisateur sélectionné :", user);
         // Convertir les IDs des centres en noms correspondants
    const userCenters = user.centers.map(centerId => {
        const center = centers.find(c => c._id === centerId); // Trouver le centre par ID
        return center ? center.name : centerId; // Si trouvé, afficher le nom, sinon afficher l'ID brut
    });
        setSelectedUser(user);
        setNewUser({
            name: user.name,
            email: user.email,
            role: user.role,
            centers: userCenters
        });
        setModalOpen(true);
    };

    const handleUserUpdate = async () => {
        if (!selectedUser) return;
        setLoading(true);
        
        try {
            // Convertir les noms des centres en ObjectIds
            let centersToSend = newUser.centers.map(centerName => {
                const center = centers.find(c => c.name === centerName); // Trouver l'objet centre
                return center ? center._id : centerName; // Si trouvé, renvoyer l'ID, sinon garder la valeur
            });

            const response = await axios.put(`https://app.vtalys.fr/api/admin/users/${selectedUser._id}`, {
                ...newUser,
                centers: centersToSend
            });

            console.log("📩 Réponse API :", response.data);

            if (response.data.success) {
                toast.success("Utilisateur mis à jour !");
                setUsers(users.map(user => user._id === selectedUser._id ? { ...user, ...newUser } : user));
                setModalOpen(false);
            } else {
                toast.error(response.data.message || "Erreur inconnue.");
            }
        } catch (error) {
            console.error("❌ Erreur lors de la mise à jour :", error);
            toast.error("Erreur lors de la mise à jour.");
        }

        setLoading(false);
    };


    // ✅ Fonction pour bloquer/débloquer un utilisateur
    const toggleBlockUser = async (userId) => {
        try {
            const response = await axios.put(`https://app.vtalys.fr/api/admin/users/${userId}/toggle-block`);
            if (response.data.success) {
                setUsers(users.map(user =>
                    user._id === userId ? { ...user, accountConfirmation: response.data.accountConfirmation } : user
                ));
            }
        } catch (error) {
            console.error("Erreur lors du changement du statut:", error);
        }
    };

    // ✅ Fonction pour supprimer un utilisateur
    const deleteUser = async (userId) => {
        if (window.confirm("Voulez-vous vraiment supprimer cet utilisateur ?")) {
            try {
                const response = await axios.delete(`https://app.vtalys.fr/api/admin/users/${userId}`);
                if (response.data.success) {
                    setUsers(users.filter(user => user._id !== userId));
                }
            } catch (error) {
                console.error("Erreur lors de la suppression de l'utilisateur:", error);
            }
        }
    };

    const inviteUser = async () => {
        setLoading(true);
        console.log("🚀 Envoi de l'invitation avec les données :", newUser); // 🔍 Vérifier les données avant l'envoi
        
        try {
            let centersToSend = newUser.centers || []; 
            if (centersToSend.includes("new") && customCenter.trim() !== "") {
                centersToSend = [...centersToSend.filter(c => c !== "new"), customCenter];
            }
    const response = await axios.post("https://app.vtalys.fr/api/admin/users/invite", {
        ...newUser,
        centers: centersToSend, // Envoyer le centre correct
      });
      console.log("📩 Réponse API :", response);
            if (response.data.success) {
                toast.error("Invitation envoyée !");
                setModalOpen(false);
                setNewUser({ name: "", email: "", role: "employe", centers: [] });
                setCustomCenter("");
            } else {
                console.warn("⚠ Problème avec la réponse API :", response.data);
                toast.error(response.data.message || "Erreur inconnue.");
        }
    } catch (error) {
        console.error("❌ Erreur lors de l'invitation:", error);

        if (error.response) {
            console.error("🔴 Réponse erreur :", error.response.data);
            toast.error("Erreur API: " + (error.response.data.message || "Vérifiez les logs."));
        } else {
            toast.error("Erreur inconnue. Vérifiez la console.");
        }
    }

    setLoading(false);
    };    


    return (
        <>
        <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false} />
            <HeaderA />
            <Container>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Utilisateurs</h3>
                                <Button color="primary" onClick={() => setModalOpen(true)}>+ Ajouter un utilisateur</Button>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Noms</th>
                                        <th scope="col">E-mail</th>
                                        <th scope="col">Statut</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {users.map(user => (
                                        <tr key={user._id}>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>
                                                {user.accountConfirmation ? (
                                                    <span className="badge badge-success">Actif</span>
                                                ) : (
                                                    <span className="badge badge-danger">Bloqué</span>
                                                )}
                                            </td>
                                            <td>
                                                <Button
                                                    color={user.accountConfirmation ? "danger" : "success"}
                                                    size="sm"
                                                    onClick={() => toggleBlockUser(user._id)}
                                                >
                                                    {user.accountConfirmation ? "Bloquer" : "Débloquer"}
                                                </Button>
                                                {" "}
                                                <Button
                                                    color="danger"
                                                    size="sm"
                                                    onClick={() => deleteUser(user._id)}
                                                >
                                                    🗑
                                                </Button>
                                            </td>
                                            <td>
                                            <Button color="info" size="sm" onClick={() => openModalForUser(user)}>✏</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card>
                    </div>
                </Row>
            </Container>
            {/* ✅ Modal pour ajouter un utilisateur */}
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Ajouter un utilisateur</ModalHeader>
                <ModalBody>
                    <Form>

<FormGroup>
  <Label>Centres :</Label>
  <Select
    isMulti
    options={[
      ...centers.map(center => ({ value: center._id, label: center.name })),
      { value: "new", label: "🆕 Ajouter un nouveau centre" }
    ]}
    value={newUser.centers.map(centerName => {
        const center = centers.find(c => c.name === centerName);
        return center ? { value: center._id, label: center.name } : { value: centerName, label: centerName };
    })}
    onChange={(selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setNewUser({ ...newUser, centers: selectedValues });
        
      // Vérifier si l'utilisateur veut ajouter un nouveau centre
      if (selectedValues.includes("new")) {
        setCustomCenter("");
      }
    }}
    placeholder="Sélectionnez les centres..."
    className="basic-multi-select"
    classNamePrefix="select"
  />

  {/* Champ pour entrer un nouveau centre si "new" est sélectionné */}
  {newUser.centers.includes("new") && (
    <Input
      type="text"
      value={customCenter}
      onChange={(e) => setCustomCenter(e.target.value)}
      placeholder="Nom du nouveau centre"
    />
  )}
</FormGroup>



                        <FormGroup>
                            <Label>Nom</Label>
                            <Input
                                type="text"
                                value={newUser.name}
                                onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input
                                type="email"
                                value={newUser.email}
                                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Rôle</Label>
                            <div>
                                {["admin", "employe", "superAdmin"].map((role) => (
                                    <FormGroup check inline key={role}>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="role"
                                                value={role}
                                                checked={newUser.role === role}
                                                onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                                            />
                                            {role}
                                        </Label>
                                    </FormGroup>
                                ))}
                            </div>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={inviteUser} disabled={loading}>
                        {loading ? "Envoi..." : "Envoyer invitation"}
                    </Button>
                    <Button color="secondary" onClick={() => setModalOpen(false)}>Annuler</Button>
                </ModalFooter>
            </Modal>


<Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
                    {selectedUser ? "Modifier l'utilisateur" : "Ajouter un utilisateur"}
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>Nom</Label>
                            <Input
                                type="text"
                                value={newUser.name}
                                onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input
                                type="email"
                                value={newUser.email}
                                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Rôle</Label>
                            <Select
                                options={[
                                    { value: "admin", label: "Admin" },
                                    { value: "employe", label: "Employé" },
                                    { value: "superAdmin", label: "Super Admin" }
                                ]}
                                value={{ value: newUser.role, label: newUser.role }}
                                onChange={(option) => setNewUser({ ...newUser, role: option.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Centres</Label>
                            <Select
                                isMulti
                                options={[
                                    ...centers.map(center => ({ value: center.name, label: center.name })),
                                    { value: "new", label: "🆕 Ajouter un nouveau centre" }
                                ]}
                                value={newUser.centers.map(center => ({ value: center, label: center }))}
                                onChange={(selectedOptions) => {
                                    const selectedValues = selectedOptions.map(option => option.value);
                                    setNewUser({ ...newUser, centers: selectedValues });
                                    if (selectedValues.includes("new")) setCustomCenter("");
                                }}
                            />
                            {newUser.centers.includes("new") && (
                                <Input
                                    type="text"
                                    value={customCenter}
                                    onChange={(e) => setCustomCenter(e.target.value)}
                                    placeholder="Nom du nouveau centre"
                                />
                            )}
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={selectedUser ? handleUserUpdate : () => {}} disabled={loading}>
                        {loading ? "Envoi..." : selectedUser ? "Mettre à jour" : "Ajouter"}
                    </Button>
                    <Button color="secondary" onClick={() => setModalOpen(false)}>Annuler</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default UsersTable;
