import React from "react";
import { Container } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import photoCv from "../../assets/img/theme/photoCv.jpg";

class HeaderCv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readerStatus: 'Lecteur disponible', // États initiaux possibles
    };
  }

  componentDidMount() {
    // Simulation des changements d'état du lecteur
    setTimeout(() => {
      this.setState({ readerStatus: 'Lecture en cours' });
      setTimeout(() => {
        this.setState({ readerStatus: 'Lecteur non connecté' });
      }, 10000); // Change l'état après 10 secondes
    }, 5000); // Commence à lire après 5 secondes
  }

  render() {
    const { readerStatus } = this.state;

    // Définition des styles pour le conteneur
    const containerStyle = {
      padding: '10px 20px',
      borderRadius: '15px',
      display: 'inline-flex',
      alignItems: 'center',
      backgroundColor: '#fff', // Fond blanc par défaut
      color: '#333', // Texte noir par défaut
      transition: 'background-color 0.5s ease, color 0.5s ease',
    };

    // Changement de couleur en fonction de l'état
    if (readerStatus === 'Lecture en cours') {
      containerStyle.backgroundColor = '#007D70'; // Vert
      containerStyle.color = '#ffffff';
    } else if (readerStatus === 'Lecteur disponible') {
      containerStyle.backgroundColor = '#003D33'; // Bleu
      containerStyle.color = '#ffffff';
    } else if (readerStatus === 'Lecteur non connecté') {
      containerStyle.backgroundColor = '#ff6347'; // Rouge
      containerStyle.color = '#ffffff';
    }

    return (
      <>
        <div className="header pb-8 pt-5 pt-md-8" style={{ background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)' }}>
          <Container fluid>
            <div className="header-body">

              <img src={photoCv} alt="Image de carte vitale" style={{ maxWidth: '20%', height: 'auto', marginLeft: '20px' }} />
              <div style={containerStyle}>
                <FontAwesomeIcon icon={faCreditCard} style={{ marginRight: '10px' }} />
                <span>{readerStatus}</span>
              </div>


            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default HeaderCv;