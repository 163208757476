import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
} from "reactstrap";
import {login} from "../../network/ApiAxios";

const clientId = "675407903558-q3s41p9vqj4op4u2p6kfdfb9okskbgnv.apps.googleusercontent.com";

const Login = props => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const history = useHistory();
    
    function handleCallbackResponse(response) {
        console.log("Encoded JWT ID token: " + response.credential);
        
        // Stocker le token Google pour vérification côté serveur
        localStorage.setItem("google_token", response.credential);
    
        // Envoyer le token JWT Google au serveur avec Bearer
        fetch("https://app.vtalys.fr/api/auth/google/verify", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${response.credential}` // Utilisation du Bearer token
            },
        })
        .then(async (res) => {
            if (!res.ok) {
                const text = await res.text();
                console.error('Erreur HTTP:', res.status, text);
                throw new Error('Erreur HTTP: ' + res.status);
            }
            return res.json(); // Parse le JSON seulement si la réponse est OK
        })
        .then((data) => {
            if (data.success) {
              setError("");
              localStorage.setItem("token", data.token); // Stocker le token JWT
              localStorage.setItem("currentCenter", data.user.currentCenter);
              console.log('Stored token:', localStorage.getItem('token'));
              localStorage.setItem("user", JSON.stringify(data.user));
              history.push("/admin/index");
            } else {
              console.error("Échec de l'authentification :", data.message);
              setError(data.message || 'Erreur d\'authentification.');
            }
          })
          
        .catch((error) => {
            console.error("Erreur lors de la vérification du token :", error);
            setError('Erreur lors de la vérification du token.');
        });
    }
    
    
    

    useEffect(() => {
        // Initialisation et rendu du bouton de connexion
        const initializeGoogleSignIn = () => {
          if (window.google) {
            window.google.accounts.id.initialize({
              client_id: clientId,
              callback: handleCallbackResponse,
            });
            window.google.accounts.id.renderButton(
              document.getElementById("signInDiv"),
              { theme: "outline", size: "large" }
            );
            window.google.accounts.id.prompt(); // Affiche la fenêtre de connexion
          }
        };
    
        // Assurez-vous que l'objet google est disponible et lancez l'initialisation
        if (document.readyState === 'complete') {
          initializeGoogleSignIn();
        } else {
          window.addEventListener('load', initializeGoogleSignIn);
          return () => window.removeEventListener('load', initializeGoogleSignIn);
        }
      }, []);
    

      const tryLogin = async () => {
        
        const response = await login(email, password);
        const { data } = response;
    
        if (data.success) {
            setError("");
            localStorage.removeItem("currentCenter"); // 🔁 Réinitialise le currentCenter
            localStorage.setItem("token", data.token);
           // On ne met rien si currentCenter est null → vue globale
if (data.user.currentCenter && typeof data.user.currentCenter === 'object') {
    localStorage.setItem("currentCenter", JSON.stringify(data.user.currentCenter));
  } else {
    localStorage.removeItem("currentCenter"); // mode "Compte global"
  }
  
            localStorage.setItem("user", JSON.stringify(data.user));
    
            console.log("🚀 User data enregistrée :", data.user);
            console.log("🔑 Token enregistré :", localStorage.getItem("token"));
            console.log("📌 Valeur de temporaryPassword :", data.user.temporaryPassword);
    
            // Vérifier si localStorage enregistre bien la donnée
            console.log("🛠️ Vérification après stockage :");
            console.log("👤 Utilisateur localStorage :", JSON.parse(localStorage.getItem("user")));
    
            if (data.user.temporaryPassword) {
                console.log("🔄 Redirection vers la modification du mot de passe...");
                history.push("/change-password");
            } else {
                console.log("✅ Connexion réussie, redirection vers l'admin...");
                history.push("/admin/index");
            }
        } else {
            setPassword("");
            setError(data.msg);
        }
    };
    
      


    return (
        <>
            <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-5">
                        <div  className="text-muted text-center mt-2 mb-3">
                            <small>Se connecter avec</small>
                        </div>
                        <div id="signInDiv" className="btn-wrapper text-center">                           
                        </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Ou inscrivez-vous avec vos identifiants</small>
                        </div>
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Email" type="email" autoComplete="email" value={email}
                                           onChange={e => setEmail(e.target.value)}/>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Mot de Passe" type="password" autoComplete="password" value={password}
                                           onChange={e => setPassword(e.target.value)}/>
                                </InputGroup>
                            </FormGroup>
                            {/*<div className="custom-control custom-control-alternative custom-checkbox">*/}
                            {/*    <input*/}
                            {/*        className="custom-control-input"*/}
                            {/*        id=" customCheckLogin"*/}
                            {/*        type="checkbox"*/}
                            {/*    />*/}
                            {/*    <label*/}
                            {/*        className="custom-control-label"*/}
                            {/*        htmlFor=" customCheckLogin"*/}
                            {/*    >*/}
                            {/*        <span className="text-muted">Remember me</span>*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                            {error ?
                                <div className="text-muted font-italic">
                                    <small>
                                        error:{" "}
                                        <span className="text-red font-weight-700">{error}</span>
                                    </small>
                                </div> : null }
                            <div className="text-center">
                                <Button className="my-4" color="primary" type="button" onClick={tryLogin}>
                                    Sign in
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                <Row className="mt-3">
                    <Col xs="6">
                        {/* <a
                            className="text-light"
                            onClick={() => props.history.push('/auth/reset-password')}
                        >
                            <small>Mot de passe oublié?</small>
                        </a> */}
                        <Button className="text-light p-0" color="link" onClick={() => props.history.push('/auth/reset-password')}>
                            <small>Mot de passe oublié ?</small>
                        </Button>
                    </Col>
                    <Col className="text-right" xs="6">
                        {/* <a
                            className="text-light"
                            onClick={() => props.history.push('/auth/register')}
                        >
                            <small>Créer un nouveau compte</small>
                        </a> */}
                         <Button className="text-light p-0" color="link" onClick={() => props.history.push('/auth/register')}>
                            <small>Créer un nouveau compte</small>
                        </Button>
                    </Col>
                </Row>
            </Col>
        </>
    );
}

export default Login;
