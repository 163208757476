import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
} from "reactstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
console.log("✅ ChangePassword component chargé !"); 

const ChangePassword = props => {
    const [oldPassword, setOldPassword] = useState(""); // ✅ Ajout de l'ancien mot de passe
    const [newPassword, setNewPassword] = useState("");
    const [error, setError] = useState("");
    const history = useHistory();

    const handleChangePassword = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem("token");
            console.log("📤 Envoi de la requête à l'API...");
            console.log("🔑 Ancien mot de passe :", oldPassword);
            console.log("🔑 Nouveau mot de passe :", newPassword);
            console.log("🛠️ URL appelée :", "https://app.vtalys.fr/api/users/change-password");
        console.log("🛠️ Token envoyé :", token);

        if (!oldPassword || !newPassword) {
            console.log("❌ Erreur : Un des champs est vide !");
            toast.error("Veuillez remplir tous les champs !");
            return;
        }

        if (!token) {
            console.log("❌ Erreur : Aucun token trouvé dans localStorage !");
            toast.error("Erreur d'authentification. Veuillez vous reconnecter.");
            return;
        }
            const response = await axios.post("https://app.vtalys.fr/api/users/change-password", {
                oldPassword,
                newPassword,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            console.log("✅ Réponse API :", response.data);

            if (response.data.success) {
                toast.success("Mot de passe changé avec succès !");
                
                // ✅ Mettre à jour localStorage
                let updatedUser = JSON.parse(localStorage.getItem("user"));
                updatedUser.temporaryPassword = false; // Forcer la mise à jour
                localStorage.setItem("user", JSON.stringify(updatedUser));
            
                console.log("🛠️ Mise à jour du localStorage après changement de mot de passe :", updatedUser);
            
                localStorage.removeItem("token");
                setTimeout(() => {
                    history.push("/login");
                }, 2000); // Laisse le temps au toast de s’afficher
            } else {
                toast.error(response.data.message || "Une erreur est survenue.");
            }
        } catch (error) {
            console.error("❌ Erreur changement de mot de passe :", error);
            toast.error("Erreur lors de la modification du mot de passe.");
        }
    };

    return (
        <>
             <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false} />
             <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-5">
                        <div className="text-muted text-center mt-2 mb-3">
                            <small>Modifier votre mot de passe</small>
                        </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Veuillez saisir votre ancien et nouveau mot de passe</small>
                        </div>
                        <Form role="form" onSubmit={handleChangePassword}>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Ancien mot de passe"
                                        type="password"
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Nouveau mot de passe"
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>

                            {error && (
                                <div className="text-muted font-italic">
                                    <small>
                                        <span className="text-red font-weight-700">{error}</span>
                                    </small>
                                </div>
                            )}

                            <div className="text-center">
                                <Button className="my-4" color="primary" type="submit">
                                    Modifier le mot de passe
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                <Row className="mt-3">
                                    <Col xs="6">
                                        {/* <a
                                            className="text-light"
                                            onClick={() => props.history.push('/auth/reset-password')}
                                        >
                                            <small>Mot de passe oublié?</small>
                                        </a> */}
                                        <Button className="text-light p-0" color="link" onClick={() => props.history.push('/auth/reset-password')}>
                                            <small>Mot de passe oublié ?</small>
                                        </Button>
                                    </Col>
                                    <Col className="text-right" xs="6">
                                        {/* <a
                                            className="text-light"
                                            onClick={() => props.history.push('/auth/register')}
                                        >
                                            <small>Créer un nouveau compte</small>
                                        </a> */}
                                         <Button className="text-light p-0" color="link" onClick={() => props.history.push('/auth/register')}>
                                            <small>Créer un nouveau compte</small>
                                        </Button>
                                    </Col>
                                </Row>
            </Col>
        </>
    );
};

export default ChangePassword;