// import axios from 'axios';
// import config from "../config";

// // const https = require('https');
// //
// // const agent = new https.Agent({
// //     rejectUnauthorized: false,
// // });

// const instance = axios.create({
//     baseURL: config.WS_BASE_URL,
// });

// instance.interceptors.request.use(async (config) => {
//     const token = localStorage.getItem('token');
//     config.headers.Authorization = (token ? token : '');
//     config.headers.ContentType = 'application/json';
//     return config;
// });

// export const getAll = async () => (
//     await instance.post('users/all')
// );

// export const register = async (name, email, password) => {
//     console.log("Attempting to register to:", `${config.WS_BASE_URL}users/register`);
//     return await instance.post('/users/register', { name, email, password });
// };

// export const confirmRegister = async id => (
//     await instance.post(`users/confirm/${id}`)
// );

// export const forgotPassword = async email => (
//     await instance.post('users/forgotpassword', {email})
// );

// export const confirmReset = async (id, password) => (
//     await instance.post(`users/resetpass/${id}`, {password})
// );

// export const login = async (email, password) => {
//     console.log("Attempting to login to:", `${config.WS_BASE_URL}/users/login`);
//     return await instance.post('/users/login', { email, password });
// };

// // export const logout = async token => (
// //     await instance.post('users/logout', {token})
// // );
// export const logout = async token => {
//     console.log("Sending logout request with token:", token);
//     return await instance.post('users/logout', {token});
// };

// export const edit = async (userID, name, email) => (
//     await instance.post('/users/edit', {userID, name, email})
// );
import axios from 'axios';
import config from "../config";

const instance = axios.create({
    baseURL: config.WS_BASE_URL,
});
console.log(config.WS_BASE_URL);

instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');
    const currentCenter = localStorage.getItem('currentCenter');
    console.log("Token récupéré depuis localStorage:", token);
    console.log("🧭 currentCenter utilisé :", currentCenter);

    config.headers.Authorization = token ? `Bearer ${token}` : ''; // Corrige l'en-tête Authorization
    config.headers['Content-Type'] = 'application/json';

    // ✅ N’ajoute pas x-current-center si on est sur /users/login ou /users/register
  const url = config.url || '';
  if (
    !url.includes('/users/login') &&
    !url.includes('/users/register') &&
    currentCenter &&
    currentCenter !== 'null'
  ) {
    try {
      const parsed = JSON.parse(currentCenter);
      config.headers['x-current-center'] = parsed._id || parsed;
    } catch (e) {
      console.warn("currentCenter non JSON parsable:", currentCenter);
    }
  }

  return config;
});

export const getAll = async () => (
    await instance.post('users/all')
);

export const register = async (name, email, password) => {
    console.log("Attempting to register to:", `${config.WS_BASE_URL}users/register`);
    return await instance.post('/users/register', { name, email, password });
};

export const confirmRegister = async id => (
    await instance.post(`users/confirm/${id}`)
);

export const forgotPassword = async email => (
    await instance.post('users/forgotpassword', {email})
);

export const confirmReset = async (id, password) => (
    await instance.post(`users/resetpass/${id}`, {password})
);

// export const login = async (email, password) => {
//     console.log("Tentative de connexion à:", `${config.WS_BASE_URL}/users/login`);
//     return await instance.post('/users/login', { email, password });
// };

export const login = async (email, password) => {
    console.log("Tentative de connexion à :", `${config.WS_BASE_URL}/users/login`);
    
    const response = await instance.post('/users/login', { email, password });

    if (response.data.token) {
        console.log("Token reçu après login:", response.data.token);
        localStorage.setItem('token', response.data.token);
    } else {
        console.error("Aucun token reçu après le login.");
    }
    
    return response;
};

// export const logout = async token => (
//     await instance.post('users/logout', {token})
// );
export const logout = async token => {
    console.log("Sending logout request with token:", token);
    return await instance.post('users/logout', {token});
};

// export const edit = async (userID, name, email) => (
//     await instance.post('/users/edit', {userID, name, email})
// );

export const edit = async (userID, userDetails) => (
    await instance.post('/users/edit', {
        userID,
        ...userDetails
    })
);

