import React from 'react';
import {Route, Redirect} from 'react-router-dom';

const AuthRoutes = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => {
        const isAuthenticated = localStorage.getItem("token") && localStorage.getItem("user");
        
        if (isAuthenticated && props.location.pathname !== "/auth/change-password") {
            console.log("🔄 Redirection de l'utilisateur connecté vers /admin/agenda");
            return <Redirect to='/admin/agenda' />;
        }
    
        console.log("✅ Autorisation d'accès à :", props.location.pathname);
        return <Component {...props} />;
    }} />
    
);

export default AuthRoutes;
