import React, {useEffect, useState} from "react";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
//import UserHeader from "components/Headers/UserHeader.js";
import EditHeader from "../../components/Headers/EditHeader";
import {edit} from "../../network/ApiAxios";

const EditProfile = props => {

    // let user = JSON.parse(localStorage.getItem("user"));
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [isTestUser, setIsTestUser] = useState(false);
    const [telephone, setTelephone] = useState(user.telephone || '');
const [adresse, setAdresse] = useState(user.adresse || '');
const [ville, setVille] = useState(user.ville || '');
const [codePostal, setCodePostal] = useState(user.codePostal || '');
//const [prescripteur, setPrescripteur] = useState(user.prescripteur || '');
const [numeroAdeli, setNumeroAdeli] = useState(user.numeroAdeli || '');
const [image, setImage] = useState(user.image|| '');


    useEffect(() => {
        if (JSON.parse(localStorage.getItem("user")).email === "test@test.com") {
            setIsTestUser(true);
        }
    }, []);

    const editUser = async () => {
        const userDetails = {
            name, 
            email, 
            telephone, 
            adresse, 
            ville, 
            codePostal,  
            numeroAdeli, 
            image
        };
    
        try {
            const response = await edit(user._id, userDetails);
            if (response.data.success) {
                const updatedUser = { ...user, ...userDetails };
                setUser(updatedUser);
                localStorage.setItem("user", JSON.stringify(updatedUser));
                props.history.push("/admin/user-profile");
                toast.success("Les informations ont été mises à jour avec succès.");
            } else {
                toast.error("Erreur lors de la mise à jour des informations.");
            }
        } catch (err) {
            console.error("Erreur lors de l'enregistrement des données:", err);
            toast.error("Une erreur est survenue, veuillez réessayer.");
        }
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('userID', user._id);

            try {
                const response = await axios.post('/api/users/upload-image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                const { data } = response;
                if (data.success) {
                    setImage(URL.createObjectURL(file));
                    user.image = data.imagePath;  // Update user image path
                    localStorage.setItem("user", JSON.stringify(user));
                    toast.success("Image mise à jour avec succès.");
                }
            } catch (err) {
                console.error("Error uploading image:", err);
                toast.error("Le service est temporairement indisponible. Veuillez réessayer plus tard.");
            }
        }
    };
    

    return (
        <>
        <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false} />
            <EditHeader/>
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                        <Card className="card-profile shadow">
                            <Row className="justify-content-center">
                                <Col className="order-lg-2" lg="3">
                                    <div className="card-profile-image">
                                        <a href="#pablo" onClick={e => {
                                            e.preventDefault()
                                            document.getElementById('imageInput').click();
                                            }}>
                                            <img
    alt="..."
    className="rounded-circle"
    src={user.image ? `/${user.image}` : require("assets/img/theme/sketch.jpg").default}
/>

                                        </a>
                                        <input
                                type="file"
                                id="imageInput"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                                accept="image/*"
                            />
                                    </div>
                                </Col>
                            </Row>
                            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                <div className="d-flex justify-content-between">
                                    {/* <Button
                                        className="mr-4"
                                        color="info"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                        size="sm"
                                    >
                                        Connect
                                    </Button> */}
                                    {/* <Button
                                        className="float-right"
                                        color="default"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                        size="sm"
                                    >
                                        Message
                                    </Button> */}
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0 pt-md-4">
                                <Row>
                                    <div className="col">
                                        <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                            <div>
                                                <span className="heading"></span>
                                                <span className="description"></span>
                                            </div>
                                            <div>
                                                <span className="heading"></span>
                                                <span className="description"></span>
                                            </div>
                                            <div>
                                                <span className="heading"></span>
                                                <span className="description"></span>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                                <div className="text-center">
                                    <h3>
                                        {user.name}
                                        <span className="font-weight-light"></span>
                                    </h3>
                                    <div className="h5 font-weight-300">
                                        <i className="ni location_pin mr-2"/>
                                        {user.ville}
                                    </div>
                                    {/* <div className="h5 mt-4">
                                        <i className="ni business_briefcase-24 mr-2"/>
                                        Solution Manager - Creative Tim Officer
                                    </div>
                                    <div>
                                        <i className="ni education_hat mr-2"/>
                                        University of Computer Science
                                    </div> */}
                                    <hr className="my-4"/>
                                    {/* <p>
                                        Ryan — the name taken by Melbourne-raised, Brooklyn-based
                                        Nick Murphy — writes, performs and records all of his own
                                        music.
                                    </p>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                        Show more
                                    </a> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <div className="col">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Mon compte</h3>
                                        {isTestUser ? <h5>Vous n'êtes pas autorisé à modifier l'utilisateur de test. Créez un autre utilisateur pour tester cette fonctionnalité.</h5> : null}
                                    </Col>
                                    <Col className="text-right" xs="4">
                                        <Button
                                            color="primary"
                                            href="#pablo"
                                            onClick={editUser}
                                            size="sm"
                                            disabled={isTestUser}
                                        >
                                            Sauvegarder
                                        </Button>
                                    </Col>
                                </Row>
                                
                            </CardHeader>
                            
                            <CardBody>
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                    Informations utilisateur
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Utilisateur
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={name}
                                                        id="input-username"
                                                        placeholder="Utilisateur"
                                                        onChange={e => setName(e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Adresse e-mail
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-email"
                                                        value={email}
                                                        onChange={e => setEmail(e.target.value)}
                                                        type="email"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
  <Col lg="6">
    <FormGroup>
      <label className="form-control-label" htmlFor="input-telephone">
        Téléphone
      </label>
      <Input
        className="form-control-alternative"
        value={telephone}
        id="input-telephone"
        placeholder="Téléphone"
        onChange={e => setTelephone(e.target.value)}
        type="text"
      />
    </FormGroup>
  </Col>
  <Col lg="6">
    <FormGroup>
      <label className="form-control-label" htmlFor="input-adresse">
        Adresse
      </label>
      <Input
        className="form-control-alternative"
        value={adresse}
        id="input-adresse"
        placeholder="Adresse"
        onChange={e => setAdresse(e.target.value)}
        type="text"
      />
    </FormGroup>
  </Col>
</Row>
<Row>
  <Col lg="6">
    <FormGroup>
      <label className="form-control-label" htmlFor="input-ville">
        Ville
      </label>
      <Input
        className="form-control-alternative"
        value={ville}
        id="input-ville"
        placeholder="Ville"
        onChange={e => setVille(e.target.value)}
        type="text"
      />
    </FormGroup>
  </Col>
  <Col lg="6">
    <FormGroup>
      <label className="form-control-label" htmlFor="input-code-postal">
        Code Postal
      </label>
      <Input
        className="form-control-alternative"
        value={codePostal}
        id="input-code-postal"
        placeholder="Code Postal"
        onChange={e => setCodePostal(e.target.value)}
        type="text"
      />
    </FormGroup>
  </Col>
</Row>
<Row>
  {/* <Col lg="6">
    <FormGroup>
      <label className="form-control-label" htmlFor="input-prescripteur">
        Prescripteur
      </label>
      <Input
        className="form-control-alternative"
        value={prescripteur}
        id="input-prescripteur"
        placeholder="Prescripteur"
        onChange={e => setPrescripteur(e.target.value)}
        type="text"
      />
    </FormGroup>
  </Col> */}
  <Col lg="6">
    <FormGroup>
      <label className="form-control-label" htmlFor="input-numero-adeli">
        Numéro Adeli
      </label>
      <Input
        className="form-control-alternative"
        value={numeroAdeli}
        id="input-numero-adeli"
        placeholder="Numéro Adeli"
        onChange={e => setNumeroAdeli(e.target.value)}
        type="text"
      />
    </FormGroup>
  </Col>
</Row>

                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
);
}

export default EditProfile;
