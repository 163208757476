import React from "react";
import axios from 'axios';
import { Line } from "react-chartjs-2";
import { Button, Card, CardHeader, CardBody, Progress, Table, Container, Row, Col } from "reactstrap";
import { chartExample1 } from "variables/charts.js";
import Header from "components/Headers/Header.js";
import moment from 'moment';
import { FaUserPlus } from 'react-icons/fa'; // Importer une icône pour "nouveaux clients"
import { FaGlobe, FaFacebook, FaUsers, FaHeadphones, FaTruck } from 'react-icons/fa';
import { Doughnut } from "react-chartjs-2";
import { FaChartLine } from 'react-icons/fa'; // Importer l'icône
import OuieqareImage from '../assets/img/theme/Ouieqare.png'; // Assurez-vous que le chemin est correct
import DoctolibImage from '../assets/img/theme/doctolib.jpg'; // Assurez-vous que le chemin est correct
import GoogleImage from '../assets/img/theme/google.jpg'; // Assurez-vous que le chemin est correct
import AppareillagesChart from '../variables/AppareillagesChart'; // Assurez-vous que le chemin est correct

// Format des mois en français
moment.locale('fr');

class Index extends React.Component {
  constructor(props) {
    super(props);
    // ✅ Lecture sécurisée du currentCenter depuis localStorage
    let parsedCenterId = "";
    try {
      const stored = localStorage.getItem("currentCenter");
      if (stored && stored !== "null") {
        const parsed = JSON.parse(stored);
        parsedCenterId = parsed._id || parsed; // accepte objet ou ID direct
      }
    } catch (e) {
      console.warn("❌ currentCenter mal formé ou invalide :", localStorage.getItem("currentCenter"));
    }

    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      chartData: {},
      appareillageData: {},
      originsData: {},
      errorMessage: '',
      centerId: parsedCenterId,
    };
  }


  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const queryToken = query.get('token');
  
    if (queryToken && queryToken.startsWith('JWT ')) {
      console.log("🔐 Token reçu depuis URL :", queryToken);
      localStorage.setItem("token", queryToken);
      // Nettoie l’URL sans recharger
      this.props.history.replace("/admin/index");
      return; // on stoppe ici
    }
  
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    // const centers = user?.centers || [];
  
    if (!token) {
      console.warn("❌ Aucun token trouvé, redirection vers login.");
      this.props.history.push("/auth/login");
      return;
    }
  
    // // Si plusieurs centres et pas de sélection
    // if (centers.length > 1 && !localStorage.getItem("currentCenter")) {
    //   console.log("↪️ Redirection vers /admin/global-dashboard car pas de centre sélectionné.");
    //   this.props.history.push("/admin/global-dashboard");
    //   return;
    // }
  
    // Sinon, on continue normalement
    // this.setState({ centerId: localStorage.getItem("currentCenter") }, () => {
    //   this.fetchData();
    // });
  
    // window.addEventListener("storage", (event) => {
    //   if (event.key === "currentCenter") {
    //     this.setState({ centerId: event.newValue }, () => {
    //       this.fetchData();
    //     });
    //   }
    // });

    
      // 🔄 Lecture du currentCenter
      const storedCenter = localStorage.getItem("currentCenter");
      let parsedCenterId = "";
    
      if (storedCenter && storedCenter !== "null") {
        try {
          const parsed = JSON.parse(storedCenter);
          parsedCenterId = parsed._id || parsed; // support objet ou string
          console.log("🏢 Centre sélectionné :", parsedCenterId);
        } catch (e) {
          console.warn("❌ currentCenter mal formé ou invalide :", storedCenter);
        }
      } else {
        console.log("🌍 Aucun currentCenter trouvé → mode global activé");
      }
    
      this.setState({ centerId: parsedCenterId }, () => {
        console.log("🎯 centerId state:", this.state.centerId, typeof this.state.centerId);
        this.fetchData();
      });
    
      // 🔁 Écouteur si le center change dans un autre onglet
      window.addEventListener("storage", (event) => {
        if (event.key === "currentCenter") {
          const updated = event.newValue && event.newValue !== "null" ? JSON.parse(event.newValue)._id : "";
          this.setState({ centerId: updated }, () => {
            this.fetchData();
          });
        }
      });
    }
    



  fetchData = async () => {
    const token = localStorage.getItem('token').trim().replace('JWT ', '');
    const { centerId } = this.state;

    if (!token) {
      console.warn("❌ Aucun token, requête annulée.");
      return;
    }
  //   try {
  //     const facturesResponse = await axios.get(`https://app.vtalys.fr/api/factures?centerId=${centerId}`, {
  //       headers: { Authorization: `Bearer ${token}` }
  //     });
  //     const facturesData = this.processFacturesData(facturesResponse.data);
  //     this.setState({ chartData: facturesData });

  //     // Fetching clients data for appareillage
  //     const clientsResponse = await axios.get(`https://app.vtalys.fr/api/clients?centerId=${centerId}`, {
  //       headers: { Authorization: `Bearer ${token}` }
  //     });
  //     const appareillageData = this.processAppareillageData(clientsResponse.data);
  //     this.setState({ appareillageData });

  //     const originsResponse = await axios.get(`https://app.vtalys.fr/api/counts/origines?centerId=${centerId}`, {
  //       headers: { Authorization: `Bearer ${token}` }
  //     });
  //     const originsData = this.processOriginsData(originsResponse.data.clientsParOrigine);
  //     this.setState({ originsData });
      

  //   } catch (error) {
  //     console.error('Erreur lors de la récupération des factures:', error);
  //     this.setState({ errorMessage: 'Impossible de récupérer les données.' });
  //     // }
  //   }
  // };
  try {
    // 🔁 Construire les URLs dynamiquement selon qu'on est en vue globale ou non
    const facturesUrl = centerId 
      ? `https://app.vtalys.fr/api/factures?centerId=${centerId}` 
      : `https://app.vtalys.fr/api/factures`;

    const clientsUrl = centerId 
      ? `https://app.vtalys.fr/api/clients?centerId=${centerId}` 
      : `https://app.vtalys.fr/api/clients`;

    const originsUrl = centerId 
      ? `https://app.vtalys.fr/api/counts/origines?centerId=${centerId}` 
      : `https://app.vtalys.fr/api/counts/origines`;

    const facturesResponse = await axios.get(facturesUrl, {
      headers: { Authorization: `Bearer ${token}` }
    });
    const facturesData = this.processFacturesData(facturesResponse.data);
    this.setState({ chartData: facturesData });

    const clientsResponse = await axios.get(clientsUrl, {
      headers: { Authorization: `Bearer ${token}` }
    });
    const appareillageData = this.processAppareillageData(clientsResponse.data);
    this.setState({ appareillageData });

    const originsResponse = await axios.get(originsUrl, {
      headers: { Authorization: `Bearer ${token}` }
    });
    const originsData = this.processOriginsData(originsResponse.data.clientsParOrigine);
    this.setState({ originsData });

  } catch (error) {
    console.error('❌ Erreur lors de la récupération des données:', error);
    this.setState({ errorMessage: 'Impossible de récupérer les données.' });
  }
};

  // processFacturesData = (factures) => {
  //   const { centerId } = this.state;
    
  //   let totals = {};
  //   factures
  //   .filter(facture => facture.centerId === centerId)
  //   .forEach(facture => {
  //     const mois = new Date(facture.dateFacture).getMonth() + 1;
  //     if (!totals[mois]) {
  //       totals[mois] = 0;
  //     }
  //     totals[mois] += facture.totalGeneral;
  //   });

  //   return this.prepareChartData(totals);
  // };

  processFacturesData = (factures) => {
    const { centerId } = this.state;
    let totals = {};
  
    factures
      .filter(facture => !centerId || facture.centerId === centerId) // 🔁 filtrer seulement si centerId est défini
      .forEach(facture => {
        const mois = new Date(facture.dateFacture).getMonth() + 1;
        if (!totals[mois]) {
          totals[mois] = 0;
        }
        totals[mois] += facture.totalGeneral;
      });
  
    return this.prepareChartData(totals);
  };
  

  processAppareillageData = (clients) => {
    let totals = {};
    clients.forEach(client => {
      if (client.statut === "Appareillé") {
        const mois = new Date(client.dateAppareillage).getMonth() + 1;
        totals[mois] = (totals[mois] || 0) + 1;
      }
    });

    return this.prepareChartData(totals);
  };


  processOriginsData = (origins) => {
    let totals = {};
    origins.forEach(client => {
      const origine = client.origine;
      totals[origine] = (totals[origine] || 0) + 1;
    });

    const labels = Object.keys(totals);
    const data = Object.values(totals);

    return {
      labels,
      datasets: [
        {
          label: 'Origines des Clients',
          data,
          backgroundColor: ['#17a2b8', '#007bff', '#6f42c1', '#28a745', '#ffc107', '#dc3545'],
        }
      ]
    };
  };


  prepareChartData = (totals) => {
    const sortedMonths = Object.keys(totals).sort((a, b) => a - b);
    const labels = sortedMonths.map(mois =>
      new Date(0, mois - 1).toLocaleString('default', { month: 'long' })
    );
    const data = sortedMonths.map(mois => totals[mois]);

    return {
      labels,
      datasets: [{
        label: 'Total',
        data,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    };
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data: this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
  };


  calculateSalesRate = (clients, invoices) => {
    return invoices !== 0 ? Math.round((clients / invoices) * 100) : 0;
  }

  


  render() {


    const lastMonthsData = [
      { month: moment().subtract(1, 'months').format('MMMM YYYY'), count: Math.floor(Math.random() * 100) + 1 },
      { month: moment().subtract(2, 'months').format('MMMM YYYY'), count: Math.floor(Math.random() * 100) + 1 },
      { month: moment().subtract(3, 'months').format('MMMM YYYY'), count: Math.floor(Math.random() * 100) + 1 },
      { month: moment().subtract(4, 'months').format('MMMM YYYY'), count: Math.floor(Math.random() * 100) + 1 },
      { month: moment().subtract(5, 'months').format('MMMM YYYY'), count: Math.floor(Math.random() * 100) + 1 },
      { month: moment().format('MMMM YYYY'), count: Math.floor(Math.random() * 100) + 1 },
    ];


    const lastThreeMonths = [
      moment().subtract(2, 'months').format('MMMM'),
      moment().subtract(1, 'months').format('MMMM'),
      moment().format('MMMM'),
    ];


    const currentMonth = moment().format('MMMM YYYY');





    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            {/* <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="text-white mb-0">Chiffre d'affaires</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={this.state.chartData}
                      options={chartExample1.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            {/* CHIFFRE DAFFAIRE */}
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="bg-gradient-default shadow" style={{ borderRadius: "12px", height: '450px' }}>
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="text-white mb-0">Chiffre d'affaires</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart" style={{ position: 'relative', width: '100%', height: '300px' }}>
                    <Line
                      data={this.state.chartData}
                      options={{
                        ...chartExample1.options,
                        responsive: true,
                        maintainAspectRatio: false, // Pour permettre un ajustement de la taille
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* TOTAL APPAREILLAGE */}
            {/* <Col xl="4">
              <Card className="shadow-lg border-0" style={{ borderRadius: '12px', overflow: 'hidden', height: '450px' }}>
                <CardHeader
                  className="bg-gradient-primary text-white"
                  style={{ background: 'linear-gradient(135deg, #6a11cb, #2575fc)' }}
                >
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0 text-white">Total Appareillage</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody style={{ backgroundColor: '#f8f9fa' }}>
                  {/* Chart */}
                  {/*<div
                    className="chart-container"
                    style={{
                      position: 'relative',
                      height: '300px',
                      width: '100%',
                    }}
                  >
                    <Bar
                      data={{
                        labels: lastThreeMonths, // Utilise les trois derniers mois comme labels
                        datasets: [
                          {
                            label: 'Appareillages',
                            data: [12, 19, 23], // Remplace ces valeurs par tes vraies données pour chaque mois
                            backgroundColor: 'rgba(103, 58, 183, 0.7)', // Couleur des barres
                            borderColor: '#673AB7', // Bordure des barres
                            borderWidth: 2,
                            hoverBackgroundColor: 'rgba(103, 58, 183, 1)', // Couleur au survol
                            hoverBorderColor: '#5E35B1',
                          },
                        ],
                      }}
                      options={{
                        animation: {
                          duration: 1500, // Animation plus rapide
                          easing: 'easeInOutCubic',
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            labels: {
                              font: {
                                size: 14,
                                family: 'Arial',
                                weight: 'bold',
                              },
                              color: '#333',
                            },
                          },
                          tooltip: {
                            backgroundColor: '#673AB7',
                            titleColor: '#fff',
                            bodyColor: '#fff',
                          },
                        },
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            <Col xl="4">
            <Card className="shadow-lg border-0" style={{ borderRadius: '12px', overflow: 'hidden', height: '450px' }}>
              <CardHeader
                className="bg-gradient-primary text-white"
                style={{ background: 'linear-gradient(135deg, #6a11cb, #2575fc)' }}
              >
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0 text-white">Total Appareillage</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody style={{ backgroundColor: '#f8f9fa' }}>
                {/* Chart */}
                <div
                  className="chart-container"
                  style={{
                    position: 'relative',
                    height: '300px',
                    width: '100%',
                  }}
                >
                  <AppareillagesChart /> {/* Ajout du composant graphique */}
                </div>
              </CardBody>
            </Card>
          </Col>
          
          </Row>


          {/* NOUVEAU CLIENT */}
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="6"> {/* Ajusté à 50% */}
              <Card className="shadow-lg border-0" style={{ borderRadius: "12px", overflow: "hidden", backgroundColor: "#fff", height: '500px' }}>
                <CardHeader
                  style={{
                    background: "linear-gradient(135deg, #4e73df, #1cc88a)",
                    borderRadius: "12px 12px 0 0",
                    padding: "1.5rem",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaUserPlus style={{ fontSize: '1.5rem', color: "#fff", marginRight: '10px' }} />
                    <h3 className="mb-0 text-white font-weight-bold">Nouveaux Clients Mensuels</h3>
                  </div>
                </CardHeader>
                <CardBody style={{ padding: '1.5rem' }}>
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    style={{ borderRadius: '10px', overflow: 'hidden', marginBottom: '0' }}
                  >
                    <thead style={{ backgroundColor: '#f8f9fc', color: '#4e73df' }}>
                      <tr>
                        <th scope="col" style={{ fontWeight: '600', color: '#4e73df' }}>Mois</th>
                        <th scope="col" style={{ fontWeight: '600', color: '#4e73df' }}>Nouveaux Clients</th>
                        <th scope="col" style={{ fontWeight: '600', color: '#4e73df' }}>Progression</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastMonthsData.map((data, index) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor: index % 2 === 0 ? "#fff" : "#f1f1f1",
                            transition: 'all 0.3s ease-in-out',
                            cursor: 'pointer',
                          }}
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#e0e0e0"}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = index % 2 === 0 ? "#fff" : "#f1f1f1"}
                        >
                          <th scope="row" style={{ fontWeight: '500', color: '#5a5c69' }}>
                            {data.month}
                          </th>
                          <td style={{ fontWeight: '500', color: '#5a5c69' }}>{data.count}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="mr-2" style={{ fontWeight: '500', color: '#1cc88a' }}>
                                {Math.round((data.count / 100) * 100)}%
                              </span>
                              <div style={{ flexGrow: 1 }}>
                                <Progress
                                  max="100"
                                  value={Math.round((data.count / 100) * 100)}
                                  barClassName="bg-success"
                                  animated
                                  style={{ height: '8px', borderRadius: '5px' }}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>

            </Col>


            {/* ORIGINE CLIENT */}
            <Col xl="6"> {/* Ajusté à 50% */}
              <Card
                className="shadow"
                style={{
                  borderRadius: '15px',
                  overflow: 'hidden',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  marginBottom: '2rem',
                  maxWidth: '600px',
                  margin: 'auto',
                  height: '500px',
                }}
              >
                <CardHeader
                  className="border-0"
                  style={{
                    background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)',
                    color: '#fff',
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                    padding: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '70px', // Ou définir une hauteur fixe
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaUsers style={{ fontSize: '1.5rem', color: "#fff", marginRight: '8px' }} />
                    <h3
                      className="mb-0"
                      style={{
                        fontSize: '1.3rem',
                        margin: 0,
                        color: '#fff',
                        fontWeight: 'bold',
                      }}
                    >
                      Origine des Clients
                    </h3>
                  </div>

                </CardHeader>
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <thead
                    className="thead-dark"
                    style={{
                      backgroundColor: '#005046',
                      color: '#fff',
                    }}
                  >
                    <tr>
                      <th scope="col" style={{ fontSize: '0.9rem', textAlign: 'left', paddingLeft: '1rem', color: '#fff' }}>Source</th>
                      <th scope="col" style={{ fontSize: '0.9rem', textAlign: 'left', paddingLeft: '1rem', color: '#fff' }}>Clients</th>
                      <th scope="col" style={{ fontSize: '0.9rem', textAlign: 'left', paddingLeft: '1rem', color: '#fff' }}>Progrès</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      { name: 'Site Web', icon: <FaGlobe style={{ color: '#17a2b8' }} />, clients: 1480, progress: 60, color: 'info' },
                      { name: 'Ouieqare', icon: <img src={OuieqareImage} alt="Ouieqare" style={{ width: '24px', height: '24px' }} />, clients: 4807, progress: 80, color: 'warning' },
                      { name: 'Google', icon: <img src={GoogleImage} alt="Google" style={{ width: '24px', height: '24px' }} />, clients: 5600, progress: 90, color: 'danger' },
                      { name: 'Facebook', icon: <FaFacebook style={{ color: '#007bff' }} />, clients: 3678, progress: 75, color: 'primary' },
                      { name: 'Doctolib', icon: <img src={DoctolibImage} alt="Doctolib" style={{ width: '24px', height: '24px' }} />, clients: 5480, progress: 70, color: 'success' },
                      { name: 'Audibene', icon: <FaHeadphones style={{ color: '#6f42c1' }} />, clients: 2645, progress: 50, color: 'secondary' },
                    ].map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          cursor: 'pointer',
                          transition: 'all 0.3s ease',
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = '#f1f1f1';
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = '#fff';
                        }}
                      >
                        <th
                          scope="row"
                          style={{
                            textAlign: 'left',
                            padding: '0.75rem 1rem',
                            fontSize: '0.9rem',
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{ gap: '0.5rem' }}
                          >
                            <span style={{ fontSize: '1.3rem' }}>{item.icon}</span>
                            <span style={{ fontWeight: 'bold' }}>{item.name}</span>
                          </div>
                        </th>
                        <td
                          style={{
                            fontSize: '0.9rem',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            padding: '0.75rem 1rem',
                          }}
                        >
                          {item.clients.toLocaleString()}
                        </td>
                        <td
                          style={{
                            textAlign: 'left',
                            padding: '0.75rem 1rem',
                            width: '120px',
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              gap: '0.4rem',
                              width: '100%',
                            }}
                          >
                            <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>{item.progress}%</span>
                            <div style={{ flex: 1 }}>
                              <Progress
                                animated
                                max="100"
                                value={item.progress}
                                barClassName={`bg-gradient-${item.color}`}
                                style={{ height: '6px', borderRadius: '5px' }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
            {/* <Col xl="6">
              <Card className="shadow" style={{ borderRadius: '15px', height: '500px' }}>
                <CardHeader className="border-0" style={{ background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)', padding: '1rem' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaUsers style={{ fontSize: '1.5rem', color: "#fff", marginRight: '8px' }} />
                    <h3 className="mb-0" style={{ color: '#fff', fontWeight: 'bold' }}>Répartition des Origines des Clients</h3>
                  </div>
                </CardHeader>
                <CardBody>
                  <div style={{ position: 'relative', height: '300px' }}>
                    <Doughnut data={this.state.originsData} options={{ responsive: true, maintainAspectRatio: false }} />
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>



          <Row className="mt-5">
            {/* Taux de vente */}
            <Col xl="6">
              <Card
                className="shadow-lg border-0"
                style={{
                  borderRadius: '20px',
                  overflow: 'hidden',
                  background: 'linear-gradient(135deg, #1b1f3a, #0f0c29)', // Bleu foncé amélioré
                  color: '#fff',
                  height: '380px',
                  textAlign: 'center',
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // Ombre subtile pour le relief
                }}
              >
                <CardHeader
                  style={{
                    padding: '1.5rem',
                    fontSize: '1.3rem',
                    fontWeight: '600',
                    color: '#fff',
                    borderBottom: 'none',
                    background: 'linear-gradient(135deg, #3a6073, #2a2f3e)', // Dégradé plus foncé pour le header
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  {/* Icône et texte pour "Taux de Vente" */}
                  <FaChartLine style={{ fontSize: '1.8rem' }} />
                  Taux de Vente
                </CardHeader>
                <CardBody style={{ padding: '2rem', position: 'relative' }}>
                  {/* Sous-titre affichant le mois actuel */}
                  <div style={{ marginBottom: '1.5rem', fontSize: '1rem', color: '#b0b8c1', fontWeight: '500' }}>
                    {currentMonth}
                  </div>

                  {/* Configuration du graphique stylisé */}
                  <div style={{ position: 'relative', height: '200px' }}>
                    <Doughnut
                      data={{
                        datasets: [
                          {
                            data: [75, 25],
                            backgroundColor: ['#3ac47d', '#2a2f3a'], // Couleurs modernes
                            borderWidth: 0,
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        cutoutPercentage: 80, // Ajuster le pourcentage pour rendre l'anneau plus fin
                        plugins: {
                          legend: {
                            display: false,
                          },
                          tooltip: {
                            enabled: false, // Désactiver les tooltips
                          },
                        },
                      }}
                    />
                    {/* Texte central */}
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '2.2rem',
                        fontWeight: 'bold',
                        color: '#3ac47d',
                      }}
                    >
                      75%
                    </div>
                  </div>

                  {/* Texte de satisfaction */}
                  <div
                    style={{
                      marginTop: '1.5rem',
                      padding: '1rem',
                      borderRadius: '12px',
                      fontSize: '1.8rem',
                      fontWeight: '700',
                      color: '#3ac47d',
                    }}
                  >
                    Taux de Vente
                  </div>
                </CardBody>
              </Card>
            </Col>












            <Col xl="6">
              {/* NOMBRE LIVRAIsON */}
              <Card
                className="shadow"
                style={{
                  borderRadius: '15px',
                  overflow: 'hidden',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  transition: 'all 0.3s ease',
                  marginBottom: '2rem',
                  backgroundColor: '#fff',
                  height: '380px'
                }}
              >
                <CardHeader
                  style={{
                    background: 'linear-gradient(135deg, #1cc88a, #20b2aa)',
                    color: '#fff',
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                    padding: '1.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaTruck style={{ fontSize: '2rem', color: '#fff', marginRight: '10px' }} />
                    <h3
                      style={{
                        fontSize: '1.5rem',
                        margin: 0,
                        color: '#fff',
                        fontWeight: 'bold',
                      }}
                    >
                      Nombre de Livraison
                    </h3>
                  </div>
                  <Button
                    color="light"
                    size="sm"
                    style={{
                      fontWeight: 'bold',
                      borderRadius: '20px',
                      padding: '0.4rem 1rem',
                      backgroundColor: '#fff',
                      color: '#20b2aa',
                      transition: 'all 0.3s ease',
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = '#e0f7f4';
                      e.currentTarget.style.color = '#1cc88a';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = '#fff';
                      e.currentTarget.style.color = '#20b2aa';
                    }}
                  >
                    Voir Détails
                  </Button>
                </CardHeader>
                <CardBody
                  style={{
                    textAlign: 'center',
                    padding: '2rem 1.5rem',
                    background: '#f4f4f9',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  <h1
                    style={{
                      fontSize: '3rem',
                      fontWeight: 'bold',
                      color: '#20b2aa',
                      marginBottom: '0.5rem',
                      transition: 'color 0.3s ease',
                    }}
                  >
                    120
                  </h1>
                  <p
                    style={{
                      fontSize: '1.2rem',
                      color: '#6c757d',
                      marginBottom: 0,
                    }}
                  >
                    Livraisons effectuées ce mois-ci
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Index;