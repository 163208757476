import React, { useState, useEffect }  from "react";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
// reactstrap components
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Form,
    Navbar,
    Nav,
    Container,
    Media
} from "reactstrap";
import {logout} from "../../network/ApiAxios";
//import Select from 'react-select';
import Autosuggest from 'react-autosuggest';

const AdminNavbar = props => {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const [clientOptions, setClientOptions] = useState([]);
    const [currentCenter, setCurrentCenter] = useState(null);
const [userCenters, setUserCenters] = useState([]);

    const history = useHistory();
    let username = JSON.parse(localStorage.getItem("user")).name;
    const [userImage, setUserImage] = useState(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.image ? `/${user.image}` : require("assets/img/theme/sketch.jpg").default;
    });

    useEffect(() => {
        const updateImage = () => {
            const user = JSON.parse(localStorage.getItem("user"));
            setUserImage(user?.image ? `/${user.image}` : require("assets/img/theme/sketch.jpg").default);
        };

        window.addEventListener("profileImageUpdated", updateImage);
        return () => {
            window.removeEventListener("profileImageUpdated", updateImage);
        };
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = localStorage.getItem("token")?.trim().replace("JWT ", "");
    console.log("🔍 Token récupéré :", token);
        if (user && user.centers) {
            console.log("📋 Centres stockés dans l'utilisateur :", user.centers);
            // Vérifie si les centres sont sous forme d'ID uniquement
            if (typeof user.centers[0] === "string") {
                console.log("⚠️ Les centres sont sous forme d'ID uniquement. On récupère les noms.");
                axios.get("https://app.vtalys.fr/api/users/get-centers", { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        console.log("✅ Centres récupérés du backend :", response.data);
                        if (Array.isArray(response.data)) {
                            setUserCenters(response.data);
                            setCurrentCenter(localStorage.getItem("currentCenter") || response.data[0] || null);
                            localStorage.setItem("userCenters", JSON.stringify(response.data)); // Stocker les centres
                        } else {
                            console.error("⚠️ Réponse inattendue pour les centres :", response.data);
                        }
                    })
                    .catch(error => {
                        console.error("❌ Erreur lors de la récupération des centres :", 
                            error.response ? error.response.data : error.message);
                    });
                } else {
                    console.log("✅ Centres correctement formatés :", user.centers);
                    setUserCenters(user.centers);

                    if (user.centers.length === 1) {
    setCurrentCenter(user.centers[0]);
    //localStorage.setItem("currentCenter", JSON.stringify(user.centers[0]));
} else if (!localStorage.getItem("currentCenter")) {
    // Si plusieurs centres et aucun currentCenter → vue globale
    setCurrentCenter(null);
    //localStorage.removeItem("currentCenter");
}

const storedCenter = localStorage.getItem("currentCenter");
try {
  if (storedCenter && storedCenter.trim().startsWith("{")) {
    // Si c’est un JSON valide
    const parsed = JSON.parse(storedCenter);
    setCurrentCenter(parsed);
  } else {
    // Sinon on suppose que c’est un ID string
    setCurrentCenter(null); // mode global
    localStorage.removeItem("currentCenter"); // nettoyage
  }
} catch (error) {
  console.error("❌ Erreur JSON.parse currentCenter:", error);
  localStorage.removeItem("currentCenter");
  setCurrentCenter(null);
}

  

                    
                }
            } else {
                console.warn("⚠️ Aucun utilisateur trouvé dans localStorage ou user.centers est vide.");
            }
        }, []);
    
    

    useEffect(() => {
        const fetchClients = async () => {
            const token = localStorage.getItem('token').trim().replace('JWT ', '');
            try {
                const response = await axios.get('https://app.vtalys.fr/api/clients', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (response.data && Array.isArray(response.data)) {
                    const options = response.data.map(client => ({
                        value: client._id,
    label: `${client.nom} ${client.prenom}`, // Affiche uniquement le nom dans les suggestions
    fullLabel: `${client.nom} - ${client.telephonePortable} - ${client.email}`, // Utilise ce champ pour la recherche
    ...client
                    }));
                    setClientOptions(options);
                } else {
                    console.error("Failed to fetch clients or data is not an array:", response.data);
                }
            } catch (error) {
                console.error('Error fetching clients:', error.response ? error.response.data : error.message);
            }
        };

        fetchClients();
    }, []);
    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        return inputValue.length === 0 ? [] : clientOptions.filter(client =>
        //   client.label.toLowerCase().includes(inputValue)
        client.fullLabel.toLowerCase().includes(inputValue)
        );
      };
      
      const getSuggestionValue = suggestion => suggestion.label;
      
      const renderSuggestion = suggestion => (
        <div>
          {suggestion.label}
        </div>
      );


    const onChange = (event, { newValue }) => {
        setValue(newValue);
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionSelected = (_, { suggestion }) => {
        console.log(`Navigation to client details for ID: ${suggestion.value}`);
        console.log('Client sélectionné dans la barre de recherche:', suggestion);
        history.push({
          pathname: `/admin/nouveauClient`,
          state: { client: suggestion }
        });
        setValue('');
    };
    

    const inputProps = {
        placeholder: 'Rechercher',
        value,
        onChange: onChange,
        style: {
            width: '100%',
            height: 'calc(1.5em + .75rem + 2px)',
            padding: '10px 12px',
            fontFamily: "Helvetica, sans-serif",
            fontWeight: 400,
            fontSize: '1rem',
            border: '1px solid #ced4da', // Customize border color here
            borderRadius: '1rem'
            //background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)'
            
        }
    };

    
    

    // Customize Autosuggest styles
    const theme = {
        container: {
            position: 'relative'
        },
        input: {
            width: '100%',
            height: '38px',
            padding: '10px 20px',
            fontWeight: 300,
            fontSize: '16px',
            border: '1px solid #ced4da', // Customize input border color here
            borderRadius: '4px'
        },
        inputFocused: {
            outline: 'none',
            borderColor: '#80bdff', // Color when the input is focused
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)' // Optional: Bootstrap focus shadow style
        },
        suggestionsContainerOpen: {
            display: 'block',
            position: 'absolute',
            top: '51px',
            width: '100%',
            border: '1px solid #aaa',
            backgroundColor: '#fff',
            fontFamily: 'Helvetica, sans-serif',
            fontWeight: 400,
            fontSize: '16px',
            zIndex: 2,
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,.2)',
            overflowY: 'auto'
        },
        suggestion: {
            cursor: 'pointer',
            padding: '10px 0',
            overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
        },
        suggestionHighlighted: {
            backgroundColor: '#ddd'
        }
    };

    const logOut = async () => {
        try {
            const token = localStorage.getItem("token");
            if (token) {
                const response = await logout(token);
                const { data } = response;
                if (data.success) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    props.history.push("/auth/login");
                } else {
                    console.error("Échec de la déconnexion:", data.message);
                }
            }
        } catch (error) {
            console.error("Erreur lors de la déconnexion:", error.response ? error.response.data : error);
        }
    }
    
    
    const switchCenter = async (newCenterId) => {
        console.log("📥 Tentative de changement de centre :", newCenterId);
        try {
            const token = localStorage.getItem("token");
    
            if (!token) {
                console.error("❌ Aucun token trouvé !");
                return;
            }
    
            const response = await fetch("https://app.vtalys.fr/api/users/switch-center", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ newCenterId }),
            });
    
            const data = await response.json();
            console.log("📤 Réponse reçue :", data);
    
            if (response.ok) {
                if (data.token) {
                    localStorage.setItem("token", data.token); // ✅ Vérifie que `data.token` existe avant de l'enregistrer
                    localStorage.setItem("currentCenter", newCenterId);
                    console.log("✅ Token mis à jour :", data.token);
                }else {
                    console.warn("⚠️ Aucun token reçu après le changement de centre !");
                }
                //localStorage.setItem("token", data.token); // Met à jour le token
                const selectedCenter = userCenters.find(c => c._id === newCenterId);
if (selectedCenter) {
    localStorage.setItem("currentCenter", JSON.stringify(selectedCenter)); // 🔥 Stocke l'objet complet
    setCurrentCenter(selectedCenter);
}

                window.location.reload(); // Recharge la page pour appliquer les nouvelles permissions
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error("Erreur lors du changement de centre", error);
        }
    };
    

    return (
        <>
            <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
                <Container fluid>
                    <Link
                        className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
                        to="/"
                    >
                        {props.brandText}
                    </Link>

                    {userCenters.length === 1 ? (
    <div className="d-flex justify-content-center align-items-center flex-grow-1 text-white font-weight-bold">
    {userCenters[0].name}
</div>

) : (
    <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
        <UncontrolledDropdown>
            <DropdownToggle caret className="btn btn-secondary">
                {currentCenter ? currentCenter.name : "Compte global"}
            </DropdownToggle>
            {/* <DropdownMenu>
                {userCenters.length > 0 ? (
                    userCenters.map(center => (
                        <DropdownItem
                            key={center._id}
                            onClick={() => switchCenter(center._id)}
                            active={center._id === currentCenter}
                        >
                            {center.name}
                        </DropdownItem>
                    ))
                ) : (
                    <DropdownItem disabled>Aucun centre disponible</DropdownItem>
                )}
            </DropdownMenu> */}
            <DropdownMenu>
                <DropdownItem
                    onClick={() => {
                        localStorage.removeItem("currentCenter");
                        setCurrentCenter(null);
                        window.location.reload(); // recharge pour afficher données globales
                    }}
                    active={!currentCenter}
                >
                    Compte global
                </DropdownItem>

                {userCenters.map(center => (
                    <DropdownItem
                        key={center._id}
                        onClick={() => switchCenter(center._id)}
                        active={currentCenter && center._id === currentCenter._id}
                    >
                        {center.name}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    </Form>
)}



<Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            
                           
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps}
                                    onSuggestionSelected={onSuggestionSelected}
                                    theme={theme}
                                />
                    </Form>
                    <Nav className="align-items-center d-none d-md-flex" navbar>
                        <UncontrolledDropdown nav>
                            <DropdownToggle className="pr-0" nav>
                                <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
            <img alt="..." src={userImage} />
        </span>
                                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                          {username}
                      </span>
                                    </Media>
                                </Media>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem className="noti-title" header tag="div">
                                    <h6 className="text-overflow m-0">Bienvenue!</h6>
                                </DropdownItem>
                                <DropdownItem to="/admin/user-profile" tag={Link}>
                                    <i className="ni ni-single-02"/>
                                    <span>Mon profil</span>
                                </DropdownItem>                            
                                <DropdownItem to="/admin/support" tag={Link}>
                                    <i className="ni ni-support-16"/>
                                    <span>Support</span>
                                </DropdownItem>
                                <DropdownItem to="/admin/abonnement" tag={Link}>
                                    <i className="ni ni-badge"/>
                                    <span>Mon Abonnement</span>
                                </DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem href="#!" onClick={logOut}>
                                    <i className="ni ni-user-run"/>
                                    <span>Deconnexion</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
};

export default AdminNavbar;
