// import { useHistory } from "react-router-dom";
// import React, { useState, useEffect } from "react";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// import {
//   Modal, ModalHeader, ModalBody, ModalFooter,
//   Button,
//   CardBody,
//   Card,
//   CardHeader,
//   Container,
//   Label,
//   FormGroup,
//   Input,
//   Row,
// } from "reactstrap";
// import { useDropzone } from 'react-dropzone';
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import Header from "components/Headers/Header.js";
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


// // const formatDate = (isoString) => {
// //   if (!isoString) return '';
// //   const date = new Date(isoString);
// //   return date.toLocaleDateString('fr-FR');  // ou 'en-US' selon le format local que vous préférez
// // }

// const Tables = () => {
//   const history = useHistory();
//   const [clients, setClients] = useState([]);
//   const [totalClients, setTotalClients] = useState(0);
//   const [modal, setModal] = useState(false);
//   const [isUploading, setIsUploading] = useState(false);
//   const [uploadSuccess, setUploadSuccess] = useState(false);
//   const [uploadError, setUploadError] = useState("");
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [selected, setSelected] = useState([]);
//   const [selectedClientId, setSelectedClientId] = useState(null);
//   const [hoveredClientId, setHoveredClientId] = useState(null);
//   const [ setShowDateModal] = useState(false);
//   const [dateType, setDateType] = useState(""); // 'appareillage' ou 'facturation'
//   //const [dateValue, setDateValue] = useState("");
//   const [showFactureModal, setShowFactureModal] = useState(false);
//   const [selectedClient, setSelectedClient] = useState(null);

//   const [showRdvModal, setShowRdvModal] = useState(false);
// const [rdvType, setRdvType] = useState('');


// const getDateFieldName = (statut) => {
//   switch (statut) {
//     case 'Rdv 1er essai': return 'datePremierEssai';
//     case 'Rdv 2eme essai': return 'dateDeuxiemeEssai';
//     case 'Rdv Bilan auditif': return 'dateBilanAuditif';
//     case 'Rdv Téléconsultation': return 'dateRdvTeleconsultation';
//     case 'Rdv facturation': return 'dateRdvFacturation';
//     case 'Rdv devis': return 'dateRdvDevis';
//     case 'Rdv livraison': return 'dateRdvLivraison';
//     case 'Facturé': return 'dateFacturation';
//     default: return '';
//   }
// };

// const getHeureFieldName = (statut) => {
//   switch (statut) {
//     case 'Rdv 1er essai': return 'heurePremierEssai';
//     case 'Rdv 2eme essai': return 'heureDeuxiemeEssai';
//     case 'Rdv Bilan auditif': return 'heureBilanAuditif';
//     case 'Rdv Téléconsultation': return 'heureRdvTeleconsultation';
//     case 'Rdv facturation': return 'heureRdvFacturation';
//     case 'Rdv devis': return 'heureRdvDevis';
//     case 'Rdv livraison': return 'heureRdvLivraison';
//     case 'Facturé': return 'heureFacturation';
//     default: return '';
//   }
// };

// const openModal = (client, type) => {
//   setSelectedClient(client);
//   setRdvType(type);
//   setShowRdvModal(true);
// };


//   const toggleModal = () => {
//     setModal(!modal);
//     if (!modal) {
//       if (!isUploading && !uploadSuccess) {
//         setSelectedFile(null);
//         setUploadError("");
//       }
//     }
//   };


//   const handleOnSelect = (row, isSelect) => {
//     setTimeout(() => {
//       console.log(`Select ${isSelect ? 'on' : 'off'} for row id ${row._id}`);
//       if (isSelect) {
//         setSelected(prevSelected => {
//           const newSelected = [...prevSelected, row._id];
//           console.log('New selected after add:', newSelected);
//           return newSelected;
//         });
//       } else {
//         setSelected(prevSelected => {
//           const newSelected = prevSelected.filter(x => x !== row._id);
//           console.log('New selected after remove:', newSelected);
//           return newSelected;
//         });
//       }
//     }, 100);  // Délai de 100ms
//   };
  
  
//   const handleOnSelectAll = (isSelect, rows) => {
//     console.log(`Select all ${isSelect ? 'on' : 'off'}`);
//     if (isSelect) {
//       const idsToSelect = rows.map(r => r._id);
//       console.log('Selecting all ids:', idsToSelect);
//       setSelected(idsToSelect);
//     } else {
//       setSelected([]);
//     }
//   };
  
  
//   const selectAllRenderer = ({ mode, checked, indeterminate }) => (
//     <input
//       type={mode}
//       checked={checked}
//       ref={input => {
//         if (input) input.indeterminate = indeterminate;
//       }}
//       onChange={e => {
//         handleOnSelectAll(e.target.checked, clients);  // Appelez handleOnSelectAll avec le bon contexte
//       }}
//     />
//   );
  
//   const handleDeleteSelected = async () => {
//     if (window.confirm("Êtes-vous sûr de vouloir supprimer les clients sélectionnés ?")) {
//       for (const clientId of selected) {
//         await fetch(`https://app.vtalys.fr/api/clients/${clientId}`, {
//           method: 'DELETE',
//           headers: {
//             'Authorization': `Bearer ${localStorage.getItem('token').trim().replace('JWT ', '')}`
//           }
//         })
//         .then(response => {
//           if (!response.ok) {
//             throw new Error(`Failed to delete client ${clientId}, status: ${response.status}`);
//           }
//           return response.json();
//         })
//         .then(data => {
//           if (!data.success) {
//             throw new Error(data.message);
//           }
//           setTotalClients(prevTotal => prevTotal - 1);
//           toast.success(`Client supprimé avec succès!`);
//         })
//         .catch(error => {
//           console.error('Error:', error);
//           toast.error(`Error deleting client ${clientId}: ${error.message}`);
//         });
//       }
//       // Mise à jour de l'état après la suppression de tous les clients sélectionnés
//       const newClients = clients.filter(client => !selected.includes(client._id));
//       setClients(newClients);
//       setSelected([]);
//     }
//   };
  
  
//   const deleteButton = selected.length > 0 ? (
//     <Button color="danger" onClick={handleDeleteSelected} style={{ marginLeft: '10px' }}>
//       Supprimer la sélection
//     </Button>
//   ) : null;

//   const selectRow = {
//     mode: 'checkbox',
//     clickToSelect: false, // Désactive la sélection automatique lors du clic sur la ligne
//     selected: selected,
//     onSelect: handleOnSelect,
//     onSelectAll: handleOnSelectAll,
//     selectionHeaderRenderer: selectAllRenderer,
//     style: { backgroundColor: '#c8e6c9' },
//     hideSelectColumn: false, // Garde la colonne de case à cocher visible
//   };
  
//   const handleRowClick = (client) => {
//     console.log(`Navigation to client details for ID: ${client._id}`);
//     setSelectedClientId(client._id);
//     history.push({
//       //pathname: `/admin/nouveauClient`, // Assurez-vous que le chemin est correct
//       pathname: `/admin/ClientDashboard`,
//       state: { client: client }
//     });
//   };
  
//   const fetchClients = (setClients) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       console.error('Token not found in localStorage');
//       return;
//     }

//     const cleanToken = token.trim();
//     const formattedToken = cleanToken.replace('JWT ', '');

//     console.log("Formatted Token from localStorage:", formattedToken);

//     fetch('https://app.vtalys.fr/api/clients', {
//       headers: {
//          'Authorization': `Bearer ${formattedToken}`
//       }
//     })
//     .then(response => {
//       if (!response.ok) {
//         throw new Error(`HTTP status ${response.status}`);
//       }
//       return response.json();
//     })
//     .then(data => {
//       console.log('Clients fetched:', data);
//       setClients(data);
//       setTotalClients(data.length);
//     })
//     .catch(err => {
//       console.error('Error fetching clients:', err.message);
//     });
// };

//   const handleAddClient = () => {
//     console.log('Redirection to /admin/nouveauClient');
//     history.push('/admin/nouveauClient');
//   };

//   const handleStatusChange = (clientId, newStatus) => {
//     // Si le statut est modifié à 'Appareillé' ou 'Facturé', ouvrez la modale pour entrer la date
//     if (newStatus === "Appareillé" || newStatus === "Facturé") {
//       setDateType(newStatus === "Appareillé" ? "appareillage" : "facturation");
//       setShowDateModal(true);
//     } else {
//       updateClientStatus(clientId, newStatus, null); // Mise à jour immédiate si ce n'est ni 'Appareillé' ni 'Facturé'
//     }
//   };

//   // Fonction pour gérer le changement de statut
//   // const handleStatusChangeWithModal = (clientId, value) => {
//   //   // Si le statut est "Facturé", ouvrez le modal
//   //   if (value === 'Facturé') {
//   //     const client = clients.find(client => client._id === clientId); // Trouver le client correspondant
//   //     setSelectedClient(client); // Définir le client sélectionné pour le modal
//   //     setShowFactureModal(true); // Ouvrir le modal
//   //   }
//   //   // Toujours appeler la fonction de changement de statut
//   //   handleStatusChange(clientId, value);
//   // };

//   const handleStatusChangeWithModal = (clientId, value) => {
//     const client = clients.find(client => client._id === clientId); // Trouver le client correspondant
//     setSelectedClient(client); // Définir le client sélectionné pour le modal

//     if (value === 'Appareillé') {
//       setDateType("appareillage"); // Type de date à "appareillage"
//       setShowFactureModal(true);
//     } else if (value === 'Facturé') {
//       setDateType("facturation"); // Type de date à "facturation"
//       setShowFactureModal(true);
//     } else {
//       // Liste des statuts qui nécessitent l'ouverture du modal RDV
//       const rdvStatuses = [
//         "Rdv Bilan auditif", "Rdv 1er essai", "Rdv 2eme essai",
//         "Rdv Téléconsultation", "Rdv facturation", "Rdv devis", "Rdv livraison"
//     ];
    
//     if (rdvStatuses.includes(value)) {
//         setRdvType(value);
//         setShowRdvModal(true); // Ouvre le modal de RDV
//     } else {
//         updateClientStatus(clientId, value, null); // Mise à jour immédiate si ce n'est pas un type nécessitant un modal
//     }
// }
// };

//   // Fonction pour gérer les changements dans le modal
//   const handleModalInputChange = (e) => {
//     const { name, value } = e.target;
//     setSelectedClient((prevClient) => ({
//       ...prevClient,
//       [name]: name.includes("date") ? value : value, // ✅ Stocke bien "YYYY-MM-DD" sans conversion inutile
//     }));
//   };

//   // Fonction pour gérer la soumission du modal
//   const handleSubmitFacture = () => {
//     // Ajouter ici la logique pour sauvegarder les données du modal si nécessaire
//     console.log("Facturation soumise:", selectedClient);
//     const dateField = dateType === "appareillage" ? selectedClient.dateAppareillage : selectedClient.dateFacturation;
//     updateClientStatus(selectedClient._id, dateType === "appareillage" ? "Appareillé" : "Facturé", dateField);
//     setShowFactureModal(false);
//   };

//   const handleSubmitRdv = async () => {
//     if (!selectedClient || !rdvType) return;

//     const dateField = getDateFieldName(rdvType);
//     const heureField = getHeureFieldName(rdvType);

//     const updatedClientData = {
//         [dateField]: selectedClient[dateField],
//         [heureField]: selectedClient[heureField],
//         statut: rdvType,
//     };

//     // 🔥 Utilisation de la même logique que `saveClient`
//     const url = selectedClient._id
//         ? `https://app.vtalys.fr/api/clients/${selectedClient._id}`  // ✅ URL correcte avec "clients"
//         : 'https://app.vtalys.fr/api/clients/add';  // Si c'est un nouveau client

//     const method = selectedClient._id ? 'PUT' : 'POST';  // PUT si mise à jour, sinon POST
//     const token = localStorage.getItem('token');  // Récupération du token JWT

//     // 🛠 Logs pour le debug
//     console.log("🟡 Envoi de la requête à :", url);
//     console.log("📌 Données envoyées :", updatedClientData);
//     console.log("🔐 Token JWT:", token);
//     console.log("📡 Méthode HTTP :", method);

//     try {
//         const response = await fetch(url, {
//             method: method,
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${token}`  // Ajout du token JWT
//             },
//             body: JSON.stringify(updatedClientData),
//         });

//         console.log("📡 Statut de la réponse :", response.status);

//         if (response.ok) {
//           toast.success("✅ Rendez-vous enregistré avec succès !");
//           setShowRdvModal(false);
//       } else {
//           const errorText = await response.text();
//           console.error("❌ Erreur lors de l’enregistrement :", errorText);
//           toast.error(`❌ Erreur : ${errorText}`);
//       }
//   } catch (error) {
//       console.error('❌ Erreur API:', error);
//       toast.error("❌ Une erreur est survenue. Veuillez réessayer.");
//   }
// };

  
//   const updateClientStatus = (clientId, newStatus, date) => {
//     const body = { statut: newStatus };
//     if (date && dateType === "appareillage") {
//       body.dateAppareillage = date;
//     } else if (date && dateType === "facturation") {
//       body.dateFacturation = date;
//     }
  
//     fetch(`https://app.vtalys.fr/api/clients/${clientId}/status`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${localStorage.getItem('token').trim().replace('JWT ', '')}`
//       },
//       body: JSON.stringify(body)
//     })
//     .then(response => {
//       if (!response.ok) {
//         throw new Error(`HTTP status ${response.status}`);
//       }
//       return response.json();
//     })
//     .then(data => {
//       if (!data.success) {
//         throw new Error(data.message);
//       }
//       toast.success('Statut mis à jour avec succès!');
//       fetchClients(setClients); // Rafraîchissement de la liste des clients
//     })
//     .catch(error => {
//       console.error('Error updating status:', error);
//       toast.error(`Erreur: ${error.toString()}`);
//     });
//   };
  
  
  
//   const handleEditClient = (e, client) => {
//     e.stopPropagation(); // Empêche l'événement de se propager à d'autres éléments
//     history.push({
//       pathname: '/admin/nouveauClient',
//       state: { client: client }
//     });
//   };
  
//   const handleDeleteClient = (e, clientId) => {
//     e.stopPropagation(); // Empêche l'événement de se propager à d'autres éléments
//     if (window.confirm("Êtes-vous sûr de vouloir supprimer ce client ?")) {
//       fetch(`https://app.vtalys.fr/api/clients/${clientId}`, {
//         method: 'DELETE',
//         headers: {
//           'Authorization': `Bearer ${localStorage.getItem('token').trim().replace('JWT ', '')}`
//         }
//       })
//       .then(response => {
//         if (!response.ok) {
//           throw new Error(`Failed to delete client, status: ${response.status}`);
//         }
//         return response.json();
//       })
//       .then(data => {
//         if (data.success) {
//           const newClients = clients.filter(client => client._id !== clientId);
//           setSelected(selected.filter(id => id !== clientId)); // Nettoyer aussi les sélections
//           setTotalClients(prevTotal => prevTotal - 1);
//           toast.success("Client supprimé avec succès!");
//           setClients(newClients);
//         } else {
//           throw new Error(data.message);
//         }
//       })
//       .catch(error => {
//         console.error('Error:', error);
//         toast.error(`Error: ${error.message}`);
//       });
//     }
//   };
  
  
//   const columns = [
//     { dataField: "_id", text: "ID", hidden: true },
//     {
//       dataField: "nom",
//       text: "Nom",
//       sort: true,
//       classes: 'col-lg-2',
//       headerClasses: 'col-lg-2'
//     },
//     {
//       dataField: "prenom",
//       text: "Prénom",
//       sort: true,
//       classes: 'col-lg-2',
//       headerClasses: 'col-lg-2'
//     },
//   {
//     dataField: "email",
//     text: "Email",
//     sort: true,
//     classes: 'col-md-3 col-lg-3 text-truncate', // Utilisation de text-truncate pour ajouter ellipsis
//     headerClasses: 'col-md-3 col-lg-3',
//     formatter: (cellContent, row) => {
//       return (
//         <div className="text-truncate" style={{ maxWidth: '200px' }}>
//           {cellContent}
//         </div>
//       );
//     }
//   }, // Colonne Email
//   {
//     dataField: "telephonePortable",
//     text: "Tel",
//     sort: true,
//     classes: 'd-none d-md-table-cell col-md-2 col-lg-2',
//     headerClasses: 'd-none d-md-table-cell col-md-2 col-lg-2'
//   },
//   {

//     dataField: "statut",
//       text: "Statut",
//       classes: 'd-none d-lg-table-cell col-md-4 col-lg-3', // Cache cette colonne sur les écrans plus petits que 'lg'
//     headerClasses: 'd-none d-lg-table-cell col-md-4 col-lg-3',
//       formatter: (cell, row) => (
//         <select
//           defaultValue={row.statut}
//           onClick={(e) => e.stopPropagation()}
//           onChange={(e) => handleStatusChangeWithModal(row._id, e.target.value)}
//           className="form-control"
//           style={{ minWidth: "150px" }}
//         >
//           <option value="none"></option>
//           <option value="Rdv Bilan auditif">Rdv Bilan auditif</option>
//         <option value="Rdv Annulé">Rdv Annulé</option>
//         <option value="En Livraison">En livraison</option>
//         <option value="Rdv livraison">Rdv livraison</option>
//         <option value="Appareillé">Appareillé</option>
//         <option value="Rdv 1er essai">Rdv 1er essai</option>
//         <option value="Rdv 2eme essai">Rdv 2eme essai</option>
//         <option value="Rdv facturation">Rdv facturation</option>
//         <option value="Facturé">Facturé</option>
//         <option value="Rdv devis">Rdv devis</option>
//         <option value="Rdv Téléconsultation">Rdv Téléconsultation</option>
//         {/* <option value="Facture éditée">Facture éditée</option> */}
//         </select>    
//     ),
//     editor: {
//       type: 'select',
//       options: [
//         { value: 'Rdv Bilan auditif', label: 'Rdv Bilan auditif' },
//         { value: 'Rdv Annulé', label: 'Rdv Annulé' },
//         { value: 'En Livraison', label: 'En Livraison' },
//         { value: 'Appareillé', label: 'Appareillé' },
//         { value: "Rdv 1er essai", label: "Rdv 1er essai" },
//         { value: "Rdv 2eme essai", label: "Rdv 2eme essai" },
//         { value: "Rdv devis", label: "Rdv devis" },
//         { value: "Rdv facturation", label: "Rdv facturation" },
//         { value: 'Facturé', label: 'Facturé' },
//         { value: 'Facture éditée', label: 'Facture éditée' }
//       ]
//     }
//   },
  
//   {
//     dataField: 'actions',
//     text: 'Actions',
//     classes: 'col-md-2 col-lg-2 text-center',
//     headerClasses: 'col-md-2 col-lg-2 text-center',
//     formatter: (cell, row) => (
//       <div>
//         <Button color="primary" size="sm" onClick={(e) => handleEditClient(e, row)}>
//           <i className="fas fa-pencil-alt"></i>
//         </Button>
//         <Button color="danger" size="sm" onClick={(e) => handleDeleteClient(e, row._id)}>
//           <i className="fas fa-trash"></i>
//         </Button>
//       </div>
//     )
//   }
//   ];
  
//   useEffect(() => {
//     const uniqueStatuses = [...new Set(clients.map(client => client.statut))];
//     localStorage.setItem('uniqueStatuses', JSON.stringify(uniqueStatuses));
//   }, [clients]);
  
//   useEffect(() => {
//     localStorage.setItem('totalClients', totalClients);
//   }, [totalClients]);
  
//   const handleFileUpload = () => {
//     if (!selectedFile) {
//       toast.warn("No file selected for upload.");
//       return;
//     }

//     setIsUploading(true);
//     const formData = new FormData();
//     formData.append('file', selectedFile);

//     fetch('https://app.vtalys.fr/api/clients/import', {
//       method: 'POST',
//       headers: {
//         'Authorization': `Bearer ${localStorage.getItem('token').trim().replace('JWT ', '')}`
//       },
//       body: formData
//     })
//       .then(response => {
//         if (!response.ok) {
//           throw new Error('Failed to upload file');
//         }
//         return response.json();
//       })
//       .then(data => {
//         setUploadSuccess(true);
//         console.log("Import success:", data);
//         fetchClients(setClients);
//         setIsUploading(false);
        
//         setSelectedFile(null);
//         toast.success("Fichier importé avec succès!");
//         toggleModal(); // Ferme le modal après l'importation réussie
//       })
//       .catch(err => {
//         console.error("Import error:", err);
//         setIsUploading(false);
//         setUploadError("Error during import: " + err.message);
//         toast.error("Error during import: " + err.message);
//       });
//       setUploadSuccess(false);
//   };

//   const pagination = paginationFactory({
//     page: 1,
//     alwaysShowAllBtns: true,
//     withFirstAndLast: false,
//     sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
//       <div className="dataTables_length" id="datatable-basic_length">
//         <label>
//           {" "}
//           {
//             <select
//               name="datatable-basic_length"
//               aria-controls="datatable-basic"
//               className="form-control form-control-sm"
//               onChange={e => onSizePerPageChange(e.target.value)}
//             >
//               <option value="10">10</option>
//               <option value="25">25</option>
//               <option value="50">50</option>
//               <option value="100">100</option>
//             </select>
//           }{" "}
//         </label>
//       </div>
//     )
//   });

//   const { SearchBar } = Search;
//   useEffect(() => {
//     fetchClients(setClients);
//   }, [totalClients]);
  
//   const { getRootProps, getInputProps, isDragActive } = useDropzone({
//     onDrop: acceptedFiles => {
//       console.log(acceptedFiles);
//       setSelectedFile(acceptedFiles[0]);
//       setUploadSuccess(true);
//       setUploadError("");
//       //toast.info("File selected for upload.");
//     }
//   });

//   // const sendEmailsToSelected = () => {
//   //   const selectedClients = clients.filter(client => selected.includes(client.id));
//   //   console.log("Sending emails to:", selectedClients.map(c => c.email));
//   //   // Logique pour envoyer des emails
//   // };

//   // const handleSelectAllClick = () => {
//   //   if (selected.length < clients.length) {
//   //     setSelected(clients.map(x => x.id)); // Sélectionner tous les ID
//   //   } else {
//   //     setSelected([]); // Désélectionner tous
//   //   }
//   // };
//   const rowEvents = {
//     onClick: (e, row, rowIndex) => {
//       if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'INPUT' && e.target.type !== 'checkbox') {
//         handleRowClick(row);
//       }
//     },
//     onMouseEnter: (e, row, rowIndex) => {
//       setHoveredClientId(row._id); // Met à jour l'état pour la ligne survolée
//     },
//     onMouseLeave: (e, row, rowIndex) => {
//       setHoveredClientId(null); // Réinitialise l'état lorsque la souris quitte la ligne
//     }
//   };
  
  
//   const rowStyle = (row, rowIndex) => {
//     if (row._id === selectedClientId) {
//       return { backgroundColor: '#f8f9fe', cursor: 'pointer' }; // Style pour la ligne sélectionnée
//     } else if (row._id === hoveredClientId) {
//       return { 
//         background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)', 
//         cursor: 'pointer',
//         color: 'white' // Pour assurer la lisibilité du texte sur le dégradé
//       }; // Style pour la ligne survolée
//     }
//     return {}; // Style par défaut
// };



//   return (
//     <>
//       <Header totalClients={totalClients} />
//       <ToastContainer position="bottom-left" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
//       <Container className="mt--7" fluid>
//         <Row>
//           <div className="col">
//             <Card className="shadow">
//               <CardHeader className="border-0 d-flex align-items-center justify-content-between">
//                 <div>
//                   <h3 className="mb-0" style={{ paddingBottom: '10px' }}>Clients (Total : {totalClients})</h3>
//                   {/* <Button color="info" onClick={handleSelectAllClick}>
//   {selected.length === clients.length ? "Désélectionner Tout" : "Sélectionner Tout"}
// </Button> */}
//                 </div>
//                 <div>
//                   <Button color="primary" onClick={handleAddClient} style={{ marginRight: '10px' }}>Ajouter Client</Button>
//                   <Button onClick={toggleModal} style={{ background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)',  color: 'white' }}>Importer Clients</Button>
//                   {/* <Button color="info" onClick={sendEmailsToSelected}>Envoyer Mail</Button> */}
//                 </div>
//               </CardHeader>
//               <CardBody>
//               <ToolkitProvider keyField="id" data={clients} columns={columns} search>
//   {props => (
//     <div>
//       <div className="row mb-2">
//         <div className="col-6">
//         {deleteButton}
//           {/* Ici, vous pouvez remettre les boutons ou autres éléments comme avant */}
//         </div>
//         <div className="col-6 text-right">
//           <SearchBar {...props.searchProps} className="form-control-sm" placeholder="Rechercher" style={{ border: '1px solid black', maxWidth: '250px' }} />
//         </div>
//       </div>
//       <div style={{ overflowX: 'auto' }}>
//       <BootstrapTable
//   {...props.baseProps}
//   keyField="_id"
//   bootstrap4
//   pagination={pagination}
//   data={clients}
//   columns={columns}
//   selectRow={selectRow}
//   rowEvents={rowEvents}
//   rowStyle={rowStyle}
//   bordered={false}
// />

//       </div>
//     </div>
//   )}
// </ToolkitProvider>

//               </CardBody>
//             </Card>
//           </div>
//         </Row>
//       </Container>

//       {/* Modal pour "Appareillé" ou "Facturé" */}
//       {showFactureModal && selectedClient && (
//         <Modal isOpen={showFactureModal} toggle={() => setShowFactureModal(!showFactureModal)}>
//           <ModalHeader toggle={() => setShowFactureModal(!showFactureModal)}>
//             {dateType === "appareillage" ? "Détails d'Appareillage" : "Détails de Facturation"}
//           </ModalHeader>
//           <ModalBody>
//             <FormGroup>
//               <Label>Date {dateType === "appareillage" ? "d'Appareillage" : "de Facturation"}</Label>
//               <Input
//                 type="date"
//                 name={dateType === "appareillage" ? "dateAppareillage" : "dateFacturation"}
//                 value={selectedClient[dateType === "appareillage" ? "dateAppareillage" : "dateFacturation"] || ''}
//                 onFocus={(e) => e.target.showPicker()} 
//                 onChange={handleModalInputChange}
//               />
//             </FormGroup>
//             {dateType === "facturation" && (
//               <>
//                 <FormGroup>
//                   <Label>Remise</Label>
//                   <Input
//                     type="number"
//                     name="remise"
//                     value={selectedClient.remise || ''}
//                     onChange={handleModalInputChange}
//                   />
//                 </FormGroup>
//                 <FormGroup>
//                   <Label>Remboursement Sécurité Sociale</Label>
//                   <Input
//                     type="number"
//                     name="rbstSS"
//                     value={selectedClient.rbstSS || ''}
//                     onChange={handleModalInputChange}
//                   />
//                 </FormGroup>
//                 <FormGroup>
//                   <Label>Remboursement Complémentaire</Label>
//                   <Input
//                     type="number"
//                     name="rbstCompl"
//                     value={selectedClient.rbstCompl || ''}
//                     onChange={handleModalInputChange}
//                   />
//                 </FormGroup>
//               </>
//             )}
//           </ModalBody>
//           <ModalFooter>
//             <Button color="primary" onClick={handleSubmitFacture}>Sauvegarder</Button>
//             <Button color="secondary" onClick={() => setShowFactureModal(false)}>Annuler</Button>
//           </ModalFooter>
//         </Modal>
//       )}

// <Modal isOpen={showRdvModal} toggle={() => setShowRdvModal(!showRdvModal)}>
//   <ModalHeader toggle={() => setShowRdvModal(false)}>
//     {rdvType ? `Détails ${rdvType}` : "Détails du rendez-vous"}
//   </ModalHeader>
//   <ModalBody>
//     <FormGroup>
//       <Label>Date du rendez-vous</Label>
//       <Input
//         type="date"
//         name={getDateFieldName(rdvType)}
//         value={
//         selectedClient && selectedClient[getDateFieldName(rdvType)]
//         ? selectedClient[getDateFieldName(rdvType)].includes('T')
//           ? selectedClient[getDateFieldName(rdvType)].split('T')[0]  // ✅ Corrige l'erreur si c'est une date ISO
//           : selectedClient[getDateFieldName(rdvType)]  // ✅ Si c'est déjà bien formaté
//         : ''
//     }
//     onFocus={(e) => e.target.showPicker()} 
//     onChange={handleModalInputChange}
//       />
//     </FormGroup>
//     <FormGroup>
//       <Label>Heure du rendez-vous</Label>
//       <Input
//         type="time"
//         name={getHeureFieldName(rdvType)}
//         value={selectedClient ? selectedClient[getHeureFieldName(rdvType)] || '' : ''}
//         onChange={handleModalInputChange}
//       />
//     </FormGroup>
//   </ModalBody>
//   <ModalFooter>
//     <Button color="primary" onClick={handleSubmitRdv}>Sauvegarder</Button>
//     <Button color="secondary" onClick={() => setShowRdvModal(false)}>Annuler</Button>
//   </ModalFooter>
// </Modal>



//       <Modal isOpen={modal} toggle={toggleModal}>
//         <ModalHeader toggle={toggleModal}>Importer des fichiers</ModalHeader>
//         <ModalBody>
//           <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #007bff', padding: '20px', textAlign: 'center' }}>
//             <input {...getInputProps()} />
//             <i className="fas fa-file-upload fa-2x"></i>
//             <p>Formats supportés : .csv, .xlsx</p>
//             {isDragActive ? (
//               <p>Relâchez le fichier ici...</p>
//             ) : (
//               <p>Glissez-déposez des fichiers ici, ou cliquez pour sélectionner des fichiers</p>
//             )}
//             {isUploading && <div>Chargement...</div>}
//             {uploadSuccess && <div className="alert alert-success">Fichier importé avec succès!</div>}
//             {uploadError && <div className="alert alert-danger">{uploadError}</div>}
//           </div>
//         </ModalBody>
//         <ModalFooter>
//           <Button color="primary" onClick={handleFileUpload} disabled={!selectedFile}>
//             OK
//           </Button>
//           <Button color="secondary" onClick={toggleModal}>Fermer</Button>
//         </ModalFooter>
//       </Modal>
//     </>
//   );
// };

// export default Tables;
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button,
  CardBody,
  Card,
  CardHeader,
  Container,
  Label,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { useDropzone } from 'react-dropzone';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Header from "components/Headers/Header.js";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


// const formatDate = (isoString) => {
//   if (!isoString) return '';
//   const date = new Date(isoString);
//   return date.toLocaleDateString('fr-FR');  // ou 'en-US' selon le format local que vous préférez
// }

const Tables = () => {
  const currentCenter = localStorage.getItem("currentCenter");
  const isGlobalMode = !currentCenter || currentCenter === "null";

  const history = useHistory();
  const [clients, setClients] = useState([]);
  const [totalClients, setTotalClients] = useState(0);
  const [modal, setModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selected, setSelected] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [hoveredClientId, setHoveredClientId] = useState(null);
  const [ setShowDateModal] = useState(false);
  const [dateType, setDateType] = useState(""); // 'appareillage' ou 'facturation'
  //const [dateValue, setDateValue] = useState("");
  const [showFactureModal, setShowFactureModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [rdvDate, setRdvDate] = useState('');
const [rdvHeure, setRdvHeure] = useState('');


  const [showRdvModal, setShowRdvModal] = useState(false);
const [rdvType, setRdvType] = useState('');


const getDateFieldName = (statut) => {
  switch (statut) {
    case 'Rdv 1er essai': return 'datePremierEssai';
    case 'Rdv 2eme essai': return 'dateDeuxiemeEssai';
    case 'Rdv Bilan auditif': return 'dateBilanAuditif';
    case 'Rdv Téléconsultation': return 'dateRdvTeleconsultation';
    case 'Rdv facturation': return 'dateRdvFacturation';
    case 'Rdv devis': return 'dateRdvDevis';
    case 'Rdv livraison': return 'dateRdvLivraison';
    case 'Facturé': return 'dateFacturation';
    default: return '';
  }
};

const getHeureFieldName = (statut) => {
  switch (statut) {
    case 'Rdv 1er essai': return 'heurePremierEssai';
    case 'Rdv 2eme essai': return 'heureDeuxiemeEssai';
    case 'Rdv Bilan auditif': return 'heureBilanAuditif';
    case 'Rdv Téléconsultation': return 'heureRdvTeleconsultation';
    case 'Rdv facturation': return 'heureRdvFacturation';
    case 'Rdv devis': return 'heureRdvDevis';
    case 'Rdv livraison': return 'heureRdvLivraison';
    case 'Facturé': return 'heureFacturation';
    default: return '';
  }
};

const openModal = (client, type) => {
  setSelectedClient(client);
  setRdvType(type);
  setShowRdvModal(true);
};


  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      if (!isUploading && !uploadSuccess) {
        setSelectedFile(null);
        setUploadError("");
      }
    }
  };


  const handleOnSelect = (row, isSelect) => {
    setTimeout(() => {
      console.log(`Select ${isSelect ? 'on' : 'off'} for row id ${row._id}`);
      if (isSelect) {
        setSelected(prevSelected => {
          const newSelected = [...prevSelected, row._id];
          console.log('New selected after add:', newSelected);
          return newSelected;
        });
      } else {
        setSelected(prevSelected => {
          const newSelected = prevSelected.filter(x => x !== row._id);
          console.log('New selected after remove:', newSelected);
          return newSelected;
        });
      }
    }, 100);  // Délai de 100ms
  };
  
  
  const handleOnSelectAll = (isSelect, rows) => {
    console.log(`Select all ${isSelect ? 'on' : 'off'}`);
    if (isSelect) {
      const idsToSelect = rows.map(r => r._id);
      console.log('Selecting all ids:', idsToSelect);
      setSelected(idsToSelect);
    } else {
      setSelected([]);
    }
  };
  
  
  const selectAllRenderer = ({ mode, checked, indeterminate }) => (
    <input
      type={mode}
      checked={checked}
      ref={input => {
        if (input) input.indeterminate = indeterminate;
      }}
      onChange={e => {
        handleOnSelectAll(e.target.checked, clients);  // Appelez handleOnSelectAll avec le bon contexte
      }}
    />
  );
  
  const handleDeleteSelected = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer les clients sélectionnés ?")) {
      for (const clientId of selected) {
        await fetch(`https://app.vtalys.fr/api/clients/${clientId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token').trim().replace('JWT ', '')}`
          }
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`Failed to delete client ${clientId}, status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          if (!data.success) {
            throw new Error(data.message);
          }
          setTotalClients(prevTotal => prevTotal - 1);
          toast.success(`Client supprimé avec succès!`);
        })
        .catch(error => {
          console.error('Error:', error);
          toast.error(`Error deleting client ${clientId}: ${error.message}`);
        });
      }
      // Mise à jour de l'état après la suppression de tous les clients sélectionnés
      const newClients = clients.filter(client => !selected.includes(client._id));
      setClients(newClients);
      setSelected([]);
    }
  };
  
  
  const deleteButton = selected.length > 0 ? (
    <Button color="danger" onClick={handleDeleteSelected} style={{ marginLeft: '10px' }}>
      Supprimer la sélection
    </Button>
  ) : null;

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false, // Désactive la sélection automatique lors du clic sur la ligne
    selected: selected,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    selectionHeaderRenderer: selectAllRenderer,
    style: { backgroundColor: '#c8e6c9' },
    hideSelectColumn: false, // Garde la colonne de case à cocher visible
  };
  
  const handleRowClick = (client) => {
    console.log(`Navigation to client details for ID: ${client._id}`);
    setSelectedClientId(client._id);
    history.push({
      //pathname: `/admin/nouveauClient`, // Assurez-vous que le chemin est correct
      pathname: `/admin/ClientDashboard`,
      state: { client: client }
    });
  };
  
  const fetchClients = (setClients) => { 
    const token = localStorage.getItem('token');
    const currentCenter = localStorage.getItem('currentCenter');
  
    if (!token) {
      console.error('❌ Token not found in localStorage');
      return;
    }
  
    let centerId = null;
  
    // Traite currentCenter proprement
    if (currentCenter && currentCenter !== "null") {
      try {
        const parsed = JSON.parse(currentCenter);
        centerId = parsed._id || parsed;
      } catch (e) {
        console.warn("⚠️ currentCenter invalide:", currentCenter);
      }
    }
  
    const cleanToken = token.trim();
    const formattedToken = cleanToken.replace('JWT ', '');
  
    console.log("🛡️ Formatted Token from localStorage:", formattedToken);
    console.log("🎯 x-current-center envoyé :", centerId);
  
    fetch('https://app.vtalys.fr/api/clients', {
      headers: {
        'Authorization': `Bearer ${formattedToken}`,
        'x-current-center': centerId || '' // ← très important
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      console.log('✅ Clients fetched:', data);
      setClients(data);
      setTotalClients(data.length);
    })
    .catch(err => {
      console.error('❌ Error fetching clients:', err.message);
    });
  };
  

  const handleAddClient = () => {
    console.log('Redirection to /admin/nouveauClient');
    history.push('/admin/nouveauClient');
  };

  const handleStatusChange = (clientId, newStatus) => {
    // Si le statut est modifié à 'Appareillé' ou 'Facturé', ouvrez la modale pour entrer la date
    if (newStatus === "Appareillé" || newStatus === "Facturé") {
      setDateType(newStatus === "Appareillé" ? "appareillage" : "facturation");
      setShowDateModal(true);
    } else {
      updateClientStatus(clientId, newStatus, null); // Mise à jour immédiate si ce n'est ni 'Appareillé' ni 'Facturé'
    }
  };

  // Fonction pour gérer le changement de statut
  // const handleStatusChangeWithModal = (clientId, value) => {
  //   // Si le statut est "Facturé", ouvrez le modal
  //   if (value === 'Facturé') {
  //     const client = clients.find(client => client._id === clientId); // Trouver le client correspondant
  //     setSelectedClient(client); // Définir le client sélectionné pour le modal
  //     setShowFactureModal(true); // Ouvrir le modal
  //   }
  //   // Toujours appeler la fonction de changement de statut
  //   handleStatusChange(clientId, value);
  // };

  const handleStatusChangeWithModal = (clientId, value) => {
    const client = clients.find(client => client._id === clientId); // Trouver le client correspondant
    setSelectedClient(client); // Définir le client sélectionné pour le modal

    if (value === 'Appareillé') {
      setDateType("appareillage"); // Type de date à "appareillage"
      setShowFactureModal(true);
    } else if (value === 'Facturé') {
      setDateType("facturation"); // Type de date à "facturation"
      setShowFactureModal(true);
    } else {
      // Liste des statuts qui nécessitent l'ouverture du modal RDV
      const rdvStatuses = [
        "Rdv Bilan auditif", "Rdv 1er essai", "Rdv 2eme essai",
        "Rdv Téléconsultation", "Rdv facturation", "Rdv devis", "Rdv livraison"
    ];
    
    if (rdvStatuses.includes(value)) {
        setRdvType(value);
        setShowRdvModal(true); // Ouvre le modal de RDV
    } else {
        updateClientStatus(clientId, value, null); // Mise à jour immédiate si ce n'est pas un type nécessitant un modal
    }
}
};

  // Fonction pour gérer les changements dans le modal
  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedClient((prevClient) => ({
      ...prevClient,
      [name]: name.includes("date") ? value : value, // ✅ Stocke bien "YYYY-MM-DD" sans conversion inutile
    }));
  };

  // Fonction pour gérer la soumission du modal
  const handleSubmitFacture = () => {
    // Ajouter ici la logique pour sauvegarder les données du modal si nécessaire
    console.log("Facturation soumise:", selectedClient);
    const dateField = dateType === "appareillage" ? selectedClient.dateAppareillage : selectedClient.dateFacturation;
    updateClientStatus(selectedClient._id, dateType === "appareillage" ? "Appareillé" : "Facturé", dateField);
    setShowFactureModal(false);
  };

  const handleSubmitRdv = async () => {
  if (!selectedClient || !rdvType) return;

  const dateField = getDateFieldName(rdvType);
  const heureField = getHeureFieldName(rdvType);

  const rdvDate = selectedClient[dateField];
  const rdvHeure = selectedClient[heureField];

  if (!rdvDate || !rdvHeure) {
    toast.error("❌ Merci de renseigner la date et l’heure du RDV.");
    return;
  }

  const updatedClientData = {
    [dateField]: rdvDate,
    [heureField]: rdvHeure,
    statut: rdvType,
    centerId: selectedClient.centerId
  };

  console.log("📤 Données envoyées :", updatedClientData);

  const url = selectedClient._id
    ? `https://app.vtalys.fr/api/clients/${selectedClient._id}`
    : 'https://app.vtalys.fr/api/clients/add';

  const method = selectedClient._id ? 'PUT' : 'POST';
  const token = localStorage.getItem('token');

  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(updatedClientData)
    });

    if (response.ok) {
      toast.success("✅ Rendez-vous enregistré avec succès !");
      setShowRdvModal(false);
      fetchClients(setClients); // 🔄 rafraîchir la liste si besoin
    } else {
      const errorText = await response.text();
      toast.error(`❌ Erreur : ${errorText}`);
    }
  } catch (error) {
    console.error("❌ Erreur API :", error);
    toast.error("❌ Une erreur est survenue.");
  }
};
  
  const updateClientStatus = (clientId, newStatus, date) => {
    const body = { statut: newStatus };
    if (date && dateType === "appareillage") {
      body.dateAppareillage = date;
    } else if (date && dateType === "facturation") {
      body.dateFacturation = date;
    }
  
    fetch(`https://app.vtalys.fr/api/clients/${clientId}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token').trim().replace('JWT ', '')}` 
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      if (!data.success) {
        throw new Error(data.message);
      }
      toast.success('Statut mis à jour avec succès!');
      fetchClients(setClients); // Rafraîchissement de la liste des clients
    })
    .catch(error => {
      console.error('Error updating status:', error);
      toast.error(`Erreur: ${error.toString()}`);
    });
  };
  
  
  
  const handleEditClient = (e, client) => {
    e.stopPropagation(); // Empêche l'événement de se propager à d'autres éléments
    history.push({
      pathname: '/admin/nouveauClient',
      state: { client: client }
    });
  };
  
  const handleDeleteClient = (e, clientId) => {
    e.stopPropagation(); // Empêche l'événement de se propager à d'autres éléments
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce client ?")) {
      fetch(`https://app.vtalys.fr/api/clients/${clientId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token').trim().replace('JWT ', '')}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to delete client, status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          const newClients = clients.filter(client => client._id !== clientId);
          setSelected(selected.filter(id => id !== clientId)); // Nettoyer aussi les sélections
          setTotalClients(prevTotal => prevTotal - 1);
          toast.success("Client supprimé avec succès!");
          setClients(newClients);
        } else {
          throw new Error(data.message);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error(`Error: ${error.message}`);
      });
    }
  };
  
  
  const columns = [
    { dataField: "_id", text: "ID", hidden: true },
    {
      dataField: "nom",
      text: "Nom",
      sort: true,
      classes: 'col-lg-1',
      headerClasses: 'col-lg-1'
    },
    {
      dataField: "prenom",
      text: "Prénom",
      sort: true,
      classes: 'col-lg-1',
      headerClasses: 'col-lg-1'
    },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    classes: 'col-md-2 text-truncate', // Utilisation de text-truncate pour ajouter ellipsis
    headerClasses: 'col-md-2',
    formatter: (cellContent, row) => {
      return (
        <div className="text-truncate" style={{ maxWidth: '200px' }}>
          {cellContent}
        </div>
      );
    }
  }, // Colonne Email
  {
    dataField: "telephonePortable",
    text: "Tel",
    sort: true,
    classes: 'd-none d-md-table-cell col-md-1',
    headerClasses: 'd-none d-md-table-cell col-md-1'
  }, 
  {

    dataField: "statut",
      text: "Statut",
      classes: 'd-none d-lg-table-cell col-md-4 col-lg-3', // Cache cette colonne sur les écrans plus petits que 'lg'
    headerClasses: 'd-none d-lg-table-cell col-md-4 col-lg-3',
      formatter: (cell, row) => (
        <select
          defaultValue={row.statut}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => handleStatusChangeWithModal(row._id, e.target.value)}
          className="form-control"
          style={{ minWidth: "150px" }}
        >
          <option value="none"></option>
          <option value="Rdv Bilan auditif">Rdv Bilan auditif</option>
        <option value="Rdv Annulé">Rdv Annulé</option>
        <option value="En Livraison">En livraison</option>
        <option value="Rdv livraison">Rdv livraison</option>
        <option value="Appareillé">Appareillé</option>
        <option value="Rdv 1er essai">Rdv 1er essai</option>
        <option value="Rdv 2eme essai">Rdv 2eme essai</option>
        <option value="Rdv facturation">Rdv facturation</option>
        <option value="Facturé">Facturé</option>
        <option value="Rdv devis">Rdv devis</option>
        <option value="Rdv Téléconsultation">Rdv Téléconsultation</option>
        {/* <option value="Facture éditée">Facture éditée</option> */}
        </select>    
    ),
    editor: {
      type: 'select',
      options: [
        { value: 'Rdv Bilan auditif', label: 'Rdv Bilan auditif' },
        { value: 'Rdv Annulé', label: 'Rdv Annulé' },
        { value: 'En Livraison', label: 'En Livraison' },
        { value: 'Appareillé', label: 'Appareillé' },
        { value: "Rdv 1er essai", label: "Rdv 1er essai" },
        { value: "Rdv 2eme essai", label: "Rdv 2eme essai" },
        { value: "Rdv devis", label: "Rdv devis" },
        { value: "Rdv facturation", label: "Rdv facturation" },
        { value: 'Facturé', label: 'Facturé' },
        { value: 'Facture éditée', label: 'Facture éditée' }
      ]
    }
  },
  
  {
    dataField: 'actions',
    text: 'Actions',
    classes: 'col-md-2 col-lg-2 text-center',
    headerClasses: 'col-md-2 col-lg-2 text-center',
    formatter: (cell, row) => (
      <div>
        <Button color="primary" size="sm" onClick={(e) => handleEditClient(e, row)}>
          <i className="fas fa-pencil-alt"></i>
        </Button>
        <Button color="danger" size="sm" onClick={(e) => handleDeleteClient(e, row._id)}>
          <i className="fas fa-trash"></i>
        </Button>
      </div>
    )
  }
  ];
  if (isGlobalMode) {
    columns.splice(3, 0, {
      dataField: "centerId",
      text: "Centre",
      sort: true,
      classes: 'col-md-2 col-lg-2',
      headerClasses: 'col-md-2 col-lg-2',
      formatter: (cellContent) => (
        <span className="text-truncate" style={{ maxWidth: '120px', display: 'inline-block' }}>
          {cellContent?.name || "—"}
        </span>
      )
    });
  }
  
  useEffect(() => {
    const uniqueStatuses = [...new Set(clients.map(client => client.statut))];
    localStorage.setItem('uniqueStatuses', JSON.stringify(uniqueStatuses));
  }, [clients]);
  
  useEffect(() => {
    localStorage.setItem('totalClients', totalClients);
  }, [totalClients]);
  
  const handleFileUpload = () => {
    if (!selectedFile) {
      toast.warn("No file selected for upload.");
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    fetch('https://app.vtalys.fr/api/clients/import', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token').trim().replace('JWT ', '')}`
      },
      body: formData
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to upload file');
        }
        return response.json();
      })
      .then(data => {
        setUploadSuccess(true);
        console.log("Import success:", data);
        fetchClients(setClients);
        setIsUploading(false);
        
        setSelectedFile(null);
        toast.success("Fichier importé avec succès!");
        toggleModal(); // Ferme le modal après l'importation réussie
      })
      .catch(err => {
        console.error("Import error:", err);
        setIsUploading(false);
        setUploadError("Error during import: " + err.message);
        toast.error("Error during import: " + err.message);
      });
      setUploadSuccess(false);
  };

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          {" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={e => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
        </label>
      </div>
    )
  });

  const { SearchBar } = Search;
  useEffect(() => {
    fetchClients(setClients);
  }, [totalClients, localStorage.getItem('currentCenter')]);
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => {
      console.log(acceptedFiles);
      setSelectedFile(acceptedFiles[0]);
      setUploadSuccess(true);
      setUploadError("");
      //toast.info("File selected for upload.");
    }
  });

  // const sendEmailsToSelected = () => {
  //   const selectedClients = clients.filter(client => selected.includes(client.id));
  //   console.log("Sending emails to:", selectedClients.map(c => c.email));
  //   // Logique pour envoyer des emails
  // };

  // const handleSelectAllClick = () => {
  //   if (selected.length < clients.length) {
  //     setSelected(clients.map(x => x.id)); // Sélectionner tous les ID
  //   } else {
  //     setSelected([]); // Désélectionner tous
  //   }
  // };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'INPUT' && e.target.type !== 'checkbox') {
        handleRowClick(row);
      }
    },
    onMouseEnter: (e, row, rowIndex) => {
      setHoveredClientId(row._id); // Met à jour l'état pour la ligne survolée
    },
    onMouseLeave: (e, row, rowIndex) => {
      setHoveredClientId(null); // Réinitialise l'état lorsque la souris quitte la ligne
    }
  };
  
  
  const rowStyle = (row, rowIndex) => {
    if (row._id === selectedClientId) {
      return { backgroundColor: '#f8f9fe', cursor: 'pointer' }; // Style pour la ligne sélectionnée
    } else if (row._id === hoveredClientId) {
      return { 
        background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)', 
        cursor: 'pointer',
        color: 'white' // Pour assurer la lisibilité du texte sur le dégradé
      }; // Style pour la ligne survolée
    }
    return {}; // Style par défaut
};



  return (
    <>
      <Header totalClients={totalClients} />
      <ToastContainer position="bottom-left" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex align-items-center justify-content-between">
                <div>
                  <h3 className="mb-0" style={{ paddingBottom: '10px' }}>Clients (Total : {totalClients})</h3>
                  {/* <Button color="info" onClick={handleSelectAllClick}>
  {selected.length === clients.length ? "Désélectionner Tout" : "Sélectionner Tout"}
</Button> */}
                </div>
                <div>
  {!isGlobalMode && (
    <>
      <Button color="primary" onClick={handleAddClient} style={{ marginRight: '10px' }}>
        Ajouter Client
      </Button>
      <Button onClick={toggleModal} style={{ background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)', color: 'white' }}>
        Importer Clients
      </Button>
      {/* <Button color="info" onClick={sendEmailsToSelected}>Envoyer Mail</Button> */}
    </>
  )}
</div>

              </CardHeader>
              <CardBody>
              <ToolkitProvider keyField="id" data={clients} columns={columns} search>
  {props => (
    <div>
      <div className="row mb-2">
        <div className="col-6">
        {deleteButton}
          {/* Ici, vous pouvez remettre les boutons ou autres éléments comme avant */}
        </div>
        <div className="col-6 text-right">
          <SearchBar {...props.searchProps} className="form-control-sm" placeholder="Rechercher" style={{ border: '1px solid black', maxWidth: '250px' }} />
        </div>
      </div>
      <div style={{ overflowX: 'auto' }}>
      <BootstrapTable
  {...props.baseProps}
  keyField="_id"
  bootstrap4
  pagination={pagination}
  data={clients}
  columns={columns}
  selectRow={selectRow}
  rowEvents={rowEvents}
  rowStyle={rowStyle}
  bordered={false}
/>

      </div>
    </div>
  )}
</ToolkitProvider>

              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal pour "Appareillé" ou "Facturé" */}
      {showFactureModal && selectedClient && (
        <Modal isOpen={showFactureModal} toggle={() => setShowFactureModal(!showFactureModal)}>
          <ModalHeader toggle={() => setShowFactureModal(!showFactureModal)}>
            {dateType === "appareillage" ? "Détails d'Appareillage" : "Détails de Facturation"}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Date {dateType === "appareillage" ? "d'Appareillage" : "de Facturation"}</Label>
              <Input
                type="date"
                name={dateType === "appareillage" ? "dateAppareillage" : "dateFacturation"}
                value={selectedClient[dateType === "appareillage" ? "dateAppareillage" : "dateFacturation"] || ''}
                onFocus={(e) => e.target.showPicker()} 
                onChange={handleModalInputChange}
              />
            </FormGroup>
            {dateType === "facturation" && (
              <>
                <FormGroup>
                  <Label>Remise</Label>
                  <Input
                    type="number"
                    name="remise"
                    value={selectedClient.remise || ''}
                    onChange={handleModalInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Remboursement Sécurité Sociale</Label>
                  <Input
                    type="number"
                    name="rbstSS"
                    value={selectedClient.rbstSS || ''}
                    onChange={handleModalInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Remboursement Complémentaire</Label>
                  <Input
                    type="number"
                    name="rbstCompl"
                    value={selectedClient.rbstCompl || ''}
                    onChange={handleModalInputChange}
                  />
                </FormGroup>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSubmitFacture}>Sauvegarder</Button>
            <Button color="secondary" onClick={() => setShowFactureModal(false)}>Annuler</Button>
          </ModalFooter>
        </Modal>
      )}

<Modal isOpen={showRdvModal} toggle={() => setShowRdvModal(!showRdvModal)}>
  <ModalHeader toggle={() => setShowRdvModal(false)}>
    {rdvType ? `Détails ${rdvType}` : "Détails du rendez-vous"}
  </ModalHeader>
  <ModalBody>
    <FormGroup>
      <Label>Date du rendez-vous</Label>
      <Input
        type="date"
        name={getDateFieldName(rdvType)}
        value={
        selectedClient && selectedClient[getDateFieldName(rdvType)]
        ? selectedClient[getDateFieldName(rdvType)].includes('T')
          ? selectedClient[getDateFieldName(rdvType)].split('T')[0]  // ✅ Corrige l'erreur si c'est une date ISO
          : selectedClient[getDateFieldName(rdvType)]  // ✅ Si c'est déjà bien formaté
        : ''
    }
    onFocus={(e) => e.target.showPicker()} 
    onChange={handleModalInputChange}
      />
    </FormGroup>
    <FormGroup>
      <Label>Heure du rendez-vous</Label>
      <Input
        type="time"
        name={getHeureFieldName(rdvType)}
        value={selectedClient ? selectedClient[getHeureFieldName(rdvType)] || '' : ''}
        onChange={handleModalInputChange}
      />
    </FormGroup>
  </ModalBody>
  <ModalFooter>
    <Button color="primary" onClick={handleSubmitRdv}>Sauvegarder</Button>
    <Button color="secondary" onClick={() => setShowRdvModal(false)}>Annuler</Button>
  </ModalFooter>
</Modal>



      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Importer des fichiers</ModalHeader>
        <ModalBody>
          <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #007bff', padding: '20px', textAlign: 'center' }}>
            <input {...getInputProps()} />
            <i className="fas fa-file-upload fa-2x"></i>
            <p>Formats supportés : .csv, .xlsx</p>
            {isDragActive ? (
              <p>Relâchez le fichier ici...</p>
            ) : (
              <p>Glissez-déposez des fichiers ici, ou cliquez pour sélectionner des fichiers</p>
            )}
            {isUploading && <div>Chargement...</div>}
            {uploadSuccess && <div className="alert alert-success">Fichier importé avec succès!</div>}
            {uploadError && <div className="alert alert-danger">{uploadError}</div>}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleFileUpload} disabled={!selectedFile}>
            OK
          </Button>
          <Button color="secondary" onClick={toggleModal}>Fermer</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Tables;